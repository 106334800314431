import React from 'react';
import moment from 'moment';

import { BatchTableProps } from '../../../interfaces/billingInterface';
import { EmptyContent } from '../../emptyContent/EmptyContent';
import { Pagination } from '../../pagination/Pagination';
import { TableContent } from '../../table/TableContent';

import { formatCurrency } from '../../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Batch Type',
    dataIndex: 'batchType',
    key: 'batchType',
  },
  {
    title: 'Check Date',
    dataIndex: 'checkDate',
    key: 'checkDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
    render: (text: any, record: any) => {
      if (record.checkNumber) {
        const checkNumbers = record.checkNumber.split(',');
        return (
          <span>
            {checkNumbers.map((number: string, index: number) => (
              <React.Fragment key={index}>
                {number.trim()}
                {index + 1 < checkNumbers.length && ', '}
                {(index + 1) % 2 === 0 && index + 1 !== checkNumbers.length && <br />}
              </React.Fragment>
            ))}
          </span>
        );
      }
      return <></>;
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Payment Amount',
    dataIndex: 'paymentAmount',
    key: 'paymentAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Unapplied Amount',
    dataIndex: 'unAppliedAmount',
    key: 'unAppliedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

export const BatchTable: React.FC<BatchTableProps> = ({
  dataSource = [],
  onEdit,
  onView,
  onApply,
  onLock,
  totalPages,
  currentPage,
  onPageChange,
}: any) => {
  return (
    <>
      {dataSource?.length > 0 ? (
        <>
          <TableContent
            enableActions={true}
            columns={columns}
            dataSource={dataSource}
            enableView={true}
            enableEdit={true}
            enableAdd={true}
            enableLock={true}
            onEdit={onEdit}
            onView={onView}
            onHandleLock={onLock}
            onHandleAdd={onApply}
          />
          {totalPages > 1 && (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          )}
        </>
      ) : (
        <EmptyContent mode="VIEW" enableCreateButton={false} />
      )}
    </>
  );
};
