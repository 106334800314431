import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchRemarkCodeListInitialInterface } from '../../../interfaces/paymentInterfaces';
import { getRemarkCodeListAPIRequest } from '../../../api/system/payment';

const initialState: FetchRemarkCodeListInitialInterface = {
  remarkCodeListLoading: false,
  remarkCodeListData: [],
  remarkCodeListError: null,
  remarkCodeListStatus: 'IDLE',
};

export const getRemarkCodeListRequest: any = createAsyncThunk(
  'denial-reports/remark-codes/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getRemarkCodeListAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getRemarkCodeListSlice = createSlice({
  name: 'fetchRemarkCodes',
  initialState,
  reducers: {
    clearFetchRemarkCodeListResponse: (state) => {
      state.remarkCodeListLoading = false;
      state.remarkCodeListStatus = 'IDLE';
      state.remarkCodeListData = [];
      state.remarkCodeListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRemarkCodeListRequest.pending, (state, action) => {
        state.remarkCodeListLoading = true;
        state.remarkCodeListStatus = 'PENDING';
      })
      .addCase(getRemarkCodeListRequest.fulfilled, (state, action) => {
        state.remarkCodeListLoading = false;
        state.remarkCodeListData = action.payload;
        state.remarkCodeListStatus = 'SUCCESS';
      })
      .addCase(getRemarkCodeListRequest.rejected, (state, action) => {
        state.remarkCodeListLoading = false;
        state.remarkCodeListError = action.payload;
        state.remarkCodeListStatus = 'FAILED';
      });
  },
});

export const { clearFetchRemarkCodeListResponse } = getRemarkCodeListSlice.actions;

export const getRemarkCodeListSelector = (state: RootState) => state.GetRemarkCodeList;

export default getRemarkCodeListSlice.reducer;
