import { useEffect, useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { ClaimsTableActionArea } from '../../components/tableActionArea/TableActionArea';
import { ClaimsTableAdvanceSearch } from '../../components/tableSearch/ClaimsTableAdvanceSearch';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { getClaimsRequest, getClaimsSelector } from '../../redux/slices/billing/getClaimsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import { clearSendClaimResponse, sendClaimRequest, sendClaimSelector } from '../../redux/slices/billing/sendClaimSlice';
import { CommonAlert } from '../../components/atoms/Alert';
import {
  clearPrintPatientClaimResponse,
  printPatientClaimRequest,
  printPatientClaimSelector,
} from '../../redux/slices/patient/printPatientClaimSlice';
import { PrintModal } from '../../components/list/patients/claim/PrintModal';
import { EditStatusModal } from '../../components/billing/primaryClaims/EditStatusModal';
import { NotesModal } from '../../components/billing/primaryClaims/NotesModal';
import { ClaimHistoryModal } from '../../components/billing/primaryClaims/ClaimHistoryModal';
import { ClearingHouseResponseModal } from '../../components/billing/primaryClaims/ClearingHouseResponseModal';
import { ViewERAModal } from '../../components/billing/primaryClaims/ViewERAModal';
import { BulkClaimsStatusModal } from '../../components/billing/primaryClaims/BulkClaimsStatusModal';
import { RightSheetLarge } from '../../components/rightSheet/RightSheetLarge';
import { ClaimForm } from '../../components/billing/primaryClaims/ClaimForm';
import { getPatientInsuranceByTypeRequest } from '../../redux/slices/patient/getPatientInsuranceByTypeSlice';
import {
  clearFetchClaimByIdResponse,
  getClaimByIdRequest,
  getClaimByIdSelector,
} from '../../redux/slices/billing/getClaimByIdSlice';
import { claimEditPostJson, claimOtherPayerJson, claimPayerJson } from '../../constants/billingConstants';
import { OtherPayerData, PayerSpData } from '../../interfaces/patientInterface';
import editClaimSlice, {
  clearEditClaimResponse,
  editClaimRequest,
  editClaimSelector,
} from '../../redux/slices/billing/editClaimSlice';
import {
  sendBulkClaimsRequest,
  sendBulkClaimsSelector,
  clearSendBulkClaimsResponse,
} from '../../redux/slices/billing/sendBulkClaimsSlice';
import {
  getSystemFacilitiesSelector,
  getSystemFacilityRequest,
} from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemInsuranceSelector } from '../../redux/slices/system/getSystemInsuranceSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';
import { clearFetchICDCodesResponse, getICDCodesSelector } from '../../redux/slices/system/getICDCodesSlice';
import { clearFetchCPTCodesResponse, getCPTCodesSelector } from '../../redux/slices/system/getCPTCodesSlice';
import { clearFetchModifiersResponse, getModifiersSelector } from '../../redux/slices/system/getModifiersSlice';
import { getICDCodeListRequest } from '../../redux/slices/patient/getICDCodeListSlice';
import { getCPTCodeListRequest } from '../../redux/slices/patient/getCPTCodeListSlice';
import moment from 'moment';
import { convertDate, formatCurrency, validDateFormat } from '../../utils/commonFunctions';
import { getSystemVisitStatusRequest } from '../../redux/slices/system/getSystemVisitStatusSlice';
import {
  clearFetchClaimHistoryResponse,
  getClaimHistoryRequest,
  getClaimHistorySelector,
} from '../../redux/slices/patient/getClaimHistorySlice';
import { getReasonListRequest } from '../../redux/slices/system/getReasonListSlice';
import { getCarrierListRequest } from '../../redux/slices/system/getCarrierListSlice';
import { getCarrierTypeListRequest } from '../../redux/slices/system/getCarrierTypeListSlice';
import { Spinner } from '../../components/atoms/Spinner';
import { getClaimStatusListRequest } from '../../redux/slices/system/getClaimStatusListSlice';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
    render: (text: any, row: any) => (
      <a
        style={{ color: '#1761fd', textDecoration: 'none' }}
        href={`billing?id=${row?.visitId}&patientId=${row?.patientId}`}
      >
        {text}
      </a>
    ),
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'CPTs',
    dataIndex: 'cpts',
    key: 'cpts',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    render: (text: any, row: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href={`patients?id=${row?.patientId}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    render: (text: any, row: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href={`providers?id=${row?.providerId}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Carrier',
    dataIndex: 'carrier',
    key: 'carrier',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const dataSource = [
  {
    claimId: '1',
    visitID: 'Type A',
    visitDate: '12345',
    cpts: 'Description A',
    amount: '$100.00',
    patient: '$90.00',
    provider: '$10.00',
    carrier: '$10.00',
    status: 'Yes',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'primaryClaims', label: 'Claims', status: 'active', link: 'primary-claims' },
];

const settingsActionArr = [
  {
    id: 'EDIT',
    title: 'Edit',
  },
  {
    id: 'EDIT_STATUS',
    title: 'Edit Status',
  },
  {
    id: 'NOTES',
    title: 'Notes',
  },
];

const dottedActionsArr = [
  {
    id: 'CLAIM_HISTORY',
    title: 'Claim History',
  },
  {
    id: 'CLEARING_HOUSE_RESPONSE',
    title: 'Clearing House Response',
  },
  {
    id: 'VIEW_ERA',
    title: 'View ERA',
  },
];

export const PrimaryClaimsPage = () => {
  const dispatch = useAppDispatch();
  const { claimsLoading, claimsData, claimsStatus } = useAppSelector(getClaimsSelector);
  const { claimSendStatus, claimSendData } = useAppSelector(sendClaimSelector);
  const { sendBulkClaimsStatus, sendBulkClaimsData, sendBulkClaimsError } = useAppSelector(sendBulkClaimsSelector);
  const { printPatientClaimData, printPatientClaimStatus } = useAppSelector(printPatientClaimSelector);
  const { claimByIdData, claimByIdStatus } = useAppSelector(getClaimByIdSelector);
  const { claimEditData, claimEditStatus, claimEditError } = useAppSelector(editClaimSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const { icdCodesData, icdCodesStatus, icdCodesError } = useAppSelector(getICDCodesSelector);
  const { cptCodesData, cptCodesStatus, cptCodesError } = useAppSelector(getCPTCodesSelector);
  const { modifiersData, modifiersStatus, modifiersError } = useAppSelector(getModifiersSelector);

  const { getClaimHistoryStatus, getClaimHistoryData, getClaimHistoryError } = useAppSelector(getClaimHistorySelector);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterObject, setFilterObject] = useState<any>({});
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  const [visiblePrintModal, setVisiblePrintModal] = useState(false);
  const [printFormData, setPrintFormData] = useState<any>({});
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});

  const [visibleEditStatus, setVisibleEditStatus] = useState<boolean>(false);
  const [visitEditStatusDataSource, setVisitEditStatusDataSource] = useState<any>([]);

  const [editStatusFormData, setEditStatusFormdata] = useState<any>({});
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);

  const [visibleNotesModal, setVisibleNotesModal] = useState<boolean>(false);
  const [noteHistoryDataSource, setNoteHistoryDataSource] = useState<any>([]);
  const [editNotesFormData, setEditNotesFormdata] = useState<any>({});

  const [visibleClaimHistory, setVisibleClaimHistoty] = useState<boolean>(false);
  const [claimHistoryDataSource, setClaimHistoryDataSource] = useState<any>([]);

  const [visibleHouseResponse, setVisibleHouseResponse] = useState<boolean>(false);
  const [houseResponseDataSource, setHouseResponseDataSource] = useState<any>([]);

  const [visibleClaimERA, setVisibleClaimERA] = useState<boolean>(false);
  const [claimERADataSource, setClaimERADataSource] = useState<any>([]);

  const [dottedActionClicked, setDottedActionClicked] = useState<boolean>(false);
  const [settingActionClicked, setSettingActionClicled] = useState<boolean>(false);

  const [claimFormEditData, setClaimFormEditData] = useState<any>({});
  const [otherPayerData, setOtherPayerData] = useState<OtherPayerData>(claimOtherPayerJson);
  const [payerData, setPayerData] = useState<PayerSpData>(claimPayerJson);
  const [visitId, setVisitId] = useState<number>();

  const [icdCodesDataSource, setICDCodesDataSource] = useState<any>([]);
  const [cptCodesDataSource, setCPTCodesDataSource] = useState<any>([]);
  const [modifiersDataSource, setModifiersDataSource] = useState<any>([]);

  const [cptDataSource, setCPTDataSource] = useState<any>([]);
  const [icdDataSource, setICDDataSource] = useState<any>([]);

  const [isBulkClaimsModalVisible, setIsBulkClaimsModalVisible] = useState<boolean>(false);
  const [bulkClaimsData, setBulkClaimsData] = useState<any[]>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchClaims(page - 1);
  };

  useEffect(() => {
    if (systemFacilityData?.length === 0 || systemProvidersProviderData?.length === 0) {
      fetchFacilities();
      fetchSystemProviders();
    }
    getVisitStatus();
    getReasonList();
    getCarrierList();
    getCarrierTypeList();
    getClaimStatusList();
  }, []);

  useEffect(() => {
    if (claimsStatus === 'SUCCESS') {
      setDataSource(transformInput(claimsData?.items));
      setTotalPages(claimsData?.totalPages);
      setCurrentPage(claimsData?.currentPage);
      setTotalItems(claimsData?.totalItems);
    }
  }, [claimsStatus]);

  useEffect(() => {
    if (claimSendStatus === 'SUCCESS') {
      if (claimSendData?.success) {
        fetchClaims();
        setAlertObj({
          color: 'success',
          message: 'Successfully sent !',
          error: false,
        });
        setVisibleAlert(true);
      } else {
        setAlertObj({
          color: 'failure',
          message: claimSendData?.errorMessage,
          error: false,
        });
        setVisibleAlert(true);
      }

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (claimSendStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearSendClaimResponse());
  }, [claimSendStatus]);

  useEffect(() => {
    if (sendBulkClaimsStatus === 'SUCCESS') {
      if (sendBulkClaimsData?.claims?.length === 0) {
        setAlertObj({
          color: 'success',
          message: 'No Claims Available to Submit !',
          error: false,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          setVisibleAlert(false);
          setAlertObj({
            color: '',
            message: '',
            error: false,
          });
        }, 3000);
      } else if (sendBulkClaimsData?.claims?.length > 0) {
        setBulkClaimsData(sendBulkClaimsData.claims);
        setIsBulkClaimsModalVisible(true);
      }
      fetchClaims();
    } else if (sendBulkClaimsStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: sendBulkClaimsError?.message ?? 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearSendBulkClaimsResponse());
  }, [sendBulkClaimsStatus]);

  useEffect(() => {
    if (filterObject) {
      fetchClaims();
    }
  }, [filterObject]);

  useEffect(() => {
    if (printPatientClaimStatus === 'SUCCESS') {
      setVisiblePrintModal(false);
      if (printPatientClaimData?.url !== null) {
        window.open(printPatientClaimData?.url, '_blank', 'noopener');
      } else {
        setAlertObj({
          color: 'failure',
          message: 'Something is wrong with the print URL!',
          error: false,
        });
        setVisibleAlert(true);
      }
      setTimeout(() => {
        dispatch(clearPrintPatientClaimResponse());
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (printPatientClaimStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearPrintPatientClaimResponse());
    }
  }, [printPatientClaimStatus]);

  useEffect(() => {
    if (claimByIdStatus === 'SUCCESS') {
      // console.log('=== claimByIdData ===', claimByIdData);
      const claimData: any = claimByIdData;
      setPayerData({
        payerId: claimData?.payer?.payerId,
        payerName: claimData?.payer?.name,
        insuredIdNumber: claimData?.insuredIdNumber,
        payerAddress1: claimData?.payerAddress1,
        payerAddress2: claimData?.payerAddress2,
        payerCity: claimData?.payerCity,
        payerState: claimData?.payerState,
        payerZip: claimData?.payerZip,
        payerOfficeNumber: claimData?.payerOfficeNumber,
      });
      setOtherPayerData({
        otherPayerId: claimData?.otherPayer?.payerId,
        otherPayerClaimId: claimData?.otherPayerClaimId,
        otherPayerAddress1: claimData?.otherPayerAddress1,
        otherPayerAddress2: claimData?.otherPayerAddress2,
        otherPayerCity: claimData?.otherPayerCity,
        otherPayerState: claimData?.otherPayerState,
        otherPayerZip: claimData?.otherPayerZip,
      });
      setClaimFormEditData(claimByIdData);
      setVisibleEdit(true);
    } else if (claimByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchClaimByIdResponse());
  }, [claimByIdStatus]);

  useEffect(() => {
    if (claimEditStatus === 'SUCCESS') {
      fetchClaims();
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleEdit(false);
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (claimEditStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: claimEditError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearEditClaimResponse());
  }, [claimEditStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (icdCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setICDCodesDataSource(icdCodesData?.items);
    } else if (icdCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in ICD Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchICDCodesResponse());
  }, [icdCodesStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (cptCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setCPTCodesDataSource(cptCodesData?.items);
    } else if (cptCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in CPT Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchCPTCodesResponse());
  }, [cptCodesStatus]);

  useEffect(() => {
    if (modifiersStatus === 'SUCCESS') {
      setModifiersDataSource(modifiersData?.items);
    } else if (modifiersStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in Modifier Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchModifiersResponse());
  }, [modifiersStatus]);

  useEffect(() => {
    if (getClaimHistoryStatus === 'SUCCESS') {
      setClaimHistoryDataSource(getClaimHistoryData);
      handleVisibleClaimHistory();
    } else if (getClaimHistoryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: getClaimHistoryError?.message ?? 'Something went wrong in Claim History Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchClaimHistoryResponse());
  }, [getClaimHistoryStatus]);

  const getClaimStatusList = () => {
    dispatch(getClaimStatusListRequest());
  };

  const getCarrierTypeList = () => {
    dispatch(getCarrierTypeListRequest());
  };

  const getCarrierList = () => {
    dispatch(getCarrierListRequest());
  };

  const getReasonList = () => {
    dispatch(getReasonListRequest());
  };

  const getVisitStatus = () => {
    dispatch(getSystemVisitStatusRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const getICDCodeList = (visitId: number) => {
    dispatch(getICDCodeListRequest({ visitId }));
  };

  const getCPTCodeList = (visitId: number) => {
    dispatch(getCPTCodeListRequest({ visitId }));
  };

  const fetchFacilities = async () => {
    dispatch(getSystemFacilityRequest());
  };

  const fetchClaims = async (page = currentPage) => {
    const newFilter = { ...filterObject };

    if (newFilter?.claimStatusId === 0) {
      delete newFilter?.claimStatusId;
    }
    dispatch(getClaimsRequest({ size: PAGE_SIZE, page: page ?? currentPage, filters: newFilter }));
  };

  const fetchClaimById = async (claimId: number) => {
    dispatch(getClaimByIdRequest({ claimId }));
  };

  const fetchClaimHistory = async (claimId: number) => {
    dispatch(getClaimHistoryRequest({ claimId }));
  };

  const transformInput = (input: any) => {
    return [
      ...input.map((item: any) => ({
        claimId: item.claimId,
        visitId: item.visitId,
        visitDate: item.visitDate,
        cpts: item.cpts,
        patient: item.patient.name,
        provider: item.provider.name,
        carrier: item.carrier.name,
        status: item.status,
        patientId: item.patient.id,
        providerId: item?.provider?.id,
        // amount: item?.amount ? '$' + item?.amount.toFixed(2) : '$ 0.00',
        amount: formatCurrency(item?.amount ?? 0),
      })),
    ];
  };

  const handleSearch = (data: any) => {
    // console.log('=== filters ===', data);

    if (data?.name === '') {
      delete data.name;
    }

    if (data?.claimId) {
      if (data?.claimId === '' || Number.isNaN(data.claimId)) {
        delete data.claimId;
      } else {
        data.claimId = parseInt(data?.claimId);
      }
    }

    if (data?.visitId) {
      if (data?.visitId === '' || Number.isNaN(data.visitId)) {
        delete data.visitId;
      } else {
        data.visitId = parseInt(data?.visitId);
      }
    }

    if (data?.claimStatusId) {
      if (data?.claimStatusId === '') {
        delete data.claimStatusId;
      } else {
        data.claimStatusId = parseInt(data?.claimStatusId);
      }
    }

    if (data?.providerId) {
      if (data?.providerId === '') {
        delete data.providerId;
      } else {
        data.providerId = parseInt(data?.providerId);
      }
    }

    if (data?.reasonId) {
      if (data?.reasonId === '') {
        delete data.reasonId;
      } else {
        data.reasonId = parseInt(data?.reasonId);
      }
    }
    if (data?.carrierId) {
      if (data?.carrierId === '') {
        delete data.carrierId;
      } else {
        data.carrierId = parseInt(data?.carrierId);
      }
    }

    if (data?.carrierType) {
      if (data?.carrierType === '') {
        delete data.carrierType;
      } else {
        data.carrierType = data?.carrierType;
      }
    }

    setCurrentPage(0);
    setFilterObject(data);
  };

  const handleClaimSend = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    dispatch(sendClaimRequest({ claimId: dataSet?.claimId }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const onClosePrintModal = (data: boolean) => {
    setVisiblePrintModal(data);
  };

  const handlePrint = (event: any, rowIndex: any) => {
    event?.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedToEdit(dataSet);
    setVisiblePrintModal(true);
  };

  const handlePrintForm = (e: any) => {
    const { name, value, checked } = e.target;
    if (name === 'isNeedPrintDate') setPrintFormData((prev: any) => ({ ...prev, [name]: checked }));
    else setPrintFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const printPdf = () => {
    let obj = {
      claimType: printFormData.claimType ? printFormData.claimType : '',
      claimPrintType: printFormData.claimPrintType ? printFormData.claimPrintType : '',
      isNeedPrintDate: printFormData.isNeedPrintDate ? printFormData.isNeedPrintDate : false,
    };

    let finalObject = {
      patientId: selectedToEdit?.patientId,
      claimId: selectedToEdit?.claimId,
      printOptions: obj,
    };

    dispatch(printPatientClaimRequest(finalObject));
  };

  const handleDottedActions = (id: any, rowIndex: any) => {
    setSettingActionClicled(false);
    setDottedActionClicked(true);
    setErrorObj({});
    const dataSet = dataSource[rowIndex];
    if (id === 'CLAIM_HISTORY') {
      fetchClaimHistory(dataSet?.claimId);
    } else if (id === 'CLEARING_HOUSE_RESPONSE') {
      handleVisibleClearingHouseResponse();
    } else if (id === 'VIEW_ERA') {
      handleVisibleClaimERA();
    }
  };

  const handleSettingsAction = (id: any, rowIndex: any) => {
    setSettingActionClicled(true);
    setDottedActionClicked(false);
    setErrorObj({});
    const dataSet = dataSource[rowIndex];
    // console.log('=== dataSet ===', dataSet);

    getPatientInsurance(dataSet?.patientId);
    setSelectedToEdit(dataSet);
    setVisitId(dataSet?.visitId);

    if (id === 'EDIT') {
      fetchClaimById(dataSet?.claimId);
    } else if (id === 'EDIT_STATUS') {
      handleVisibleEditStatus();
    } else if (id === 'NOTES') {
      handleVisibleNotes();
    }
  };

  const getPatientInsurance = (patientId: number) => {
    dispatch(getPatientInsuranceByTypeRequest({ patientId: patientId, type: 'ALL' }));
  };

  const handleVisibleEditStatus = () => {
    setVisibleEditStatus(true);
  };

  const handleCloseEditStatus = (close: boolean) => {
    setVisibleEditStatus(close);
  };

  const onChangeEditStatusFields = (event: any) => {
    setEditStatusFormdata((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onSubmitEditStatus = () => {};

  const handleVisibleNotes = () => {
    setVisibleNotesModal(true);
  };

  const handleCloseNotes = (close: boolean) => {
    setVisibleNotesModal(close);
  };

  const onChangeNotesFields = (event: any) => {
    setEditNotesFormdata((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onSubmitNotes = (submissionData: any) => {};

  const handleVisibleClaimHistory = () => {
    setVisibleClaimHistoty(true);
  };

  const handleCloseClaimHistory = (close: boolean) => {
    setVisibleClaimHistoty(close);
  };

  const handleVisibleClearingHouseResponse = () => {
    setVisibleHouseResponse(true);
  };

  const handleCloseClearingHouseResponse = (close: boolean) => {
    setVisibleHouseResponse(close);
  };

  const handleVisibleClaimERA = () => {
    setVisibleClaimERA(true);
  };

  const handleCloseClaimERA = (close: boolean) => {
    setVisibleClaimERA(close);
  };

  const handleSendAllClaims = () => {
    dispatch(sendBulkClaimsRequest());
  };

  const onCloseBulkClaimsStatusModal = () => {
    setBulkClaimsData([]);
    setIsBulkClaimsModalVisible(false);
  };

  const editFormOnClose = () => {
    setVisibleEdit(false);
  };

  const onChangeClaimEditFormFields = (event: any) => {
    const { name, value, checked } = event.target;
    if (name === 'showPayerAddress') {
      setClaimFormEditData((prev: any) => ({ ...prev, [name]: checked }));
    } else {
      setClaimFormEditData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const onChangeClaimEditFormDates = (name: string, date: Date) => {
    setClaimFormEditData((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  // Function to reset the payer data fields
  const resetPayerData = () => {
    const { payerName, showPayerAddress, payerId, ...resetData } = claimPayerJson;
    return resetData;
  };

  const getPayerDataFromClaim = (claimFormEditData: any) => {
    const { payerName, showPayerAddress, payerId, ...resetData } = claimPayerJson;

    return Object.keys(resetData).reduce((acc: any, key: any) => {
      acc[key] = claimFormEditData[key];
      return acc;
    }, {});
  };

  const resetOtherPayerData = () => {
    const { otherPayerId, ...resetData } = claimOtherPayerJson;
    return resetData;
  };

  const getOtherPayerDataFromClaim = (otherPayerData: any) => {
    const { otherPayerId, ...resetData } = claimOtherPayerJson;
    return Object.keys(resetData).reduce((acc: any, key: any) => {
      acc[key] = otherPayerData[key];
      return acc;
    }, {});
  };

  const handlePayerFields = (event: any) => {
    const { name, value } = event.target;
    if (name === 'payerName') {
      if (value === claimFormEditData?.payer?.id) {
        setPayerData((prev: any) => ({
          ...prev,
          [name]: value,
          payerId: value,
          ...getPayerDataFromClaim(claimFormEditData),
        }));
      } else {
        setPayerData((prev: any) => ({
          ...prev,
          [name]: value,
          payerId: value,
          ...resetPayerData(),
        }));
      }
    } else {
      setPayerData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const handleOtherPayerFields = (event: any) => {
    const { name, value } = event.target;
    if (name === 'otherPayerName') {
      if (value === claimFormEditData?.otherPayer?.id) {
        setOtherPayerData((prev: any) => ({
          ...prev,
          otherPayerId: value,
          ...getOtherPayerDataFromClaim(claimFormEditData),
        }));
      } else {
        setOtherPayerData((prev: any) => ({
          ...prev,
          otherPayerId: value,
          ...resetOtherPayerData(),
        }));
      }
    } else {
      setOtherPayerData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const beforeSubmit = (data: any) => {
    return Object.keys(data).reduce((acc: any, key: any) => {
      acc[key] = claimEditPostJson[key];
      return acc;
    }, {});
  };

  const onSubmitEditForm = () => {
    const allFields = { ...payerData, ...otherPayerData, ...claimFormEditData };

    // console.log('=== allFields ===', allFields);

    if (allFields?.patientDob) {
      allFields.patientDob = convertDate(allFields?.patientDob);
    }

    if (allFields?.insuredDob) {
      allFields.insuredDob = convertDate(allFields?.insuredDob);
    }

    if (allFields?.otherInsuredDob) {
      allFields.otherInsuredDob = convertDate(allFields?.otherInsuredDob);
    }

    if (allFields?.primaryPaymentDate) {
      allFields.primaryPaymentDate = convertDate(allFields?.primaryPaymentDate);
    }

    if (allFields?.dateOfCondition) {
      allFields.dateOfCondition = convertDate(allFields?.dateOfCondition);
    }

    if (allFields?.hospDateFrom) {
      allFields.hospDateFrom = convertDate(allFields?.hospDateFrom);
    }

    if (allFields?.hospDateTo) {
      allFields.hospDateTo = convertDate(allFields?.hospDateTo);
    }

    if (allFields?.patientEmployment) {
      allFields.patientEmployment = 'UNKNOWN';
    }

    // const beforeSubmitResult = beforeSubmit(allFields);
    // console.log('=== beforeSubmitResult ===', beforeSubmitResult);

    const obj = {
      claimId: selectedToEdit?.claimId,
      claimEdit: allFields,
    };
    // console.log('=== submit edit ===', obj);
    dispatch(editClaimRequest(obj));
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <>
      <div className="main-content">
        <MainHeader />
        <PageTopic mainTitle="Claims" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
        <ClaimsTableAdvanceSearch onSubmit={handleSearch} />
        {visibleAlert && !alertObj?.error && (
          <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
        )}
        <ClaimsTableActionArea sendAllClaims={handleSendAllClaims} claimCount={totalItems} />
        {claimsLoading ? (
          <Spinner />
        ) : (
          <>
            {!claimsLoading && dataSource?.length > 0 ? (
              <div className="overflow-x-visible">
                {/* overflow-x-auto */}
                <TableContent
                  enableActions={true}
                  columns={columns}
                  dataSource={dataSource}
                  enableOrdering={false}
                  enablePrint={true}
                  enableSend={true}
                  enableDots={true}
                  enableSettings={true}
                  dottedActions={dottedActionsArr}
                  settingActions={settingsActionArr}
                  onHandltSend={handleClaimSend}
                  onHandlePrint={handlePrint}
                  onHandleDottedAction={handleDottedActions}
                  onHandleSettingsAction={handleSettingsAction}
                />
              </div>
            ) : (
              <EmptyContent enableCreateButton={false} />
            )}
            {totalPages > 1 ? (
              <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      {visiblePrintModal ? (
        <PrintModal
          openModal={visiblePrintModal}
          setOpenModal={onClosePrintModal}
          onChange={handlePrintForm}
          printView={printPdf}
        />
      ) : (
        <></>
      )}

      {visibleEditStatus && (
        <EditStatusModal
          editStatusModalOpen={visibleEditStatus}
          setOpenEditStatusModal={handleCloseEditStatus}
          editStatusFormData={editStatusFormData}
          onChangeFields={onChangeEditStatusFields}
          onSubmit={onSubmitEditStatus}
        />
      )}

      {visibleNotesModal && (
        <NotesModal
          visibleNotesModal={visibleNotesModal}
          setVisibleNotesModal={handleCloseNotes}
          editNotesFormData={editNotesFormData}
          onChangeNotesFields={onChangeNotesFields}
          onSubmit={onSubmitNotes}
        />
      )}

      {visibleClaimHistory && (
        <ClaimHistoryModal
          claimHistoryModal={visibleClaimHistory}
          setClaimHistoryModal={handleCloseClaimHistory}
          dataSource={claimHistoryDataSource}
        />
      )}

      {visibleHouseResponse && (
        <ClearingHouseResponseModal
          clearingHouseResponseModal={visibleHouseResponse}
          setClearingHouseResponseModal={handleCloseClearingHouseResponse}
          dataSource={houseResponseDataSource}
        />
      )}

      {isBulkClaimsModalVisible && (
        <BulkClaimsStatusModal
          claimsData={bulkClaimsData}
          isBulkClaimsStatusModalOpen={isBulkClaimsModalVisible}
          onCloseBulkClaimsStatusModal={onCloseBulkClaimsStatusModal}
        />
      )}

      {visibleClaimERA && <ViewERAModal visibleClaimERA={visibleClaimERA} setVisibleClaimERA={handleCloseClaimERA} />}

      {visibleEdit && (
        <RightSheetLarge
          title="Claim Preview"
          children={
            <ClaimForm
              claimFormEditData={claimFormEditData}
              onChange={onChangeClaimEditFormFields}
              onChangeDate={onChangeClaimEditFormDates}
              payerData={payerData}
              handlePayerFields={handlePayerFields}
              handleOtherPayerFields={handleOtherPayerFields}
              otherPayerData={otherPayerData}
              visitId={visitId}
              getICDCodeList={getICDCodeList}
              getCPTCodeList={getCPTCodeList}
              cptDataSource={cptDataSource}
              icdDataSource={icdDataSource}
              icdCodesDataSource={icdCodesDataSource}
              cptCodesDataSource={cptCodesDataSource}
              modifiersDataSource={modifiersDataSource}
              fetchClaimById={fetchClaimById}
            />
          }
          enableStepper={false}
          onClose={editFormOnClose}
          submitButtonTitle="Update Claim"
          cancelButtonTitle="Cancel"
          onSubmit={onSubmitEditForm}
          enableAlert={false}
          enableTitleAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      )}
    </>
  );
};
