export const Title = ({ title }: { title: String }) => {
  return (
    <>
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <div style={{ marginLeft: '5px', padding: 10 }}>{title}</div>
      </div>
    </>
  );
};
