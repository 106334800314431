import { validZipCodeLength, validatePhoneNumber } from './commonFunctions';

export const createInsuranceValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.name) {
    newErrors.name = 'Name is required';
    isValid = false;
  }

  // if (!formData?.planName) {
  //   newErrors.planName = 'Plan Name is required';
  //   isValid = false;
  // }

  // if (!formData?.financialClass) {
  //   newErrors.financialClass = 'Financial Class is required';
  //   isValid = false;
  // }

  // if (!formData?.address1) {
  //   newErrors.address1 = 'Address 1 is required';
  //   isValid = false;
  // }

  // if (!formData?.city) {
  //   newErrors.city = 'City is required';
  //   isValid = false;
  // }

  // if (!formData?.state) {
  //   newErrors.state = 'State is required';
  //   isValid = false;
  // }

  // if (!formData?.businessState) {
  //   newErrors.businessState = 'Business State is required';
  //   isValid = false;
  // }

  // if (!formData?.webSite) {
  //   newErrors.webSite = 'Website is required';
  //   isValid = false;
  // }

  if (!formData?.claimPayerId) {
    newErrors.claimPayerId = 'Claim Payer ID is required';
    isValid = false;
  }

  if (!formData?.eligibilityPayerId) {
    newErrors.eligibilityPayerId = 'Eligibility Payer ID is required';
    isValid = false;
  }

  if (formData?.zipCode) {
    if (formData?.zipCode.toString().length !== validZipCodeLength) {
      newErrors.zipCode = 'Valid zip code required';
      isValid = false;
    }
  }

  if (formData?.phone) {
    if (!validatePhoneNumber(formData?.phone)) {
      newErrors.phone = 'Valid phone number required';
      isValid = false;
    }
  }

  if (formData?.contact) {
    if (!validatePhoneNumber(formData?.contact)) {
      newErrors.contact = 'Valid contact number required';
      isValid = false;
    }
  }

  if (formData?.fax) {
    if (!validatePhoneNumber(formData?.fax)) {
      newErrors.fax = 'Valid fax number required';
      isValid = false;
    }
  }

  // if (!formData?.providerSupportNo) {
  //   newErrors.providerSupportNo = 'Provider Support Number is required';
  //   isValid = false;
  // }

  // if (!formData?.daysToProcess) {
  //   newErrors.daysToProcess = 'Days to Process is required';
  //   isValid = false;
  // }

  return { newErrors, isValid };
};
