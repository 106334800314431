import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ExpectedVsCollectionsReportAdvanceSearch } from '../../components/tableSearch/ExpectedVsCollectionsReportAdvanceSearch';

import {
  getExpectedVsCollectionsReportRequest,
  getExpectedVsCollectionsReportSelector,
  clearFetchExpectedVsCollectionsReportResponse,
} from '../../redux/slices/reports/getExpectedVsCollectionsReportSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Payer',
    dataIndex: 'payer',
    key: 'payer',
  },
  {
    title: 'Amount Billed',
    dataIndex: 'amountBilled',
    key: 'amountBilled',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Date Billed',
    dataIndex: 'dateBilled',
    key: 'dateBilled',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Amount Expected',
    dataIndex: 'amountExpected',
    key: 'amountExpected',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Amount Received',
    dataIndex: 'amountReceived',
    key: 'amountReceived',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Date Received',
    dataIndex: 'dateReceived',
    key: 'dateReceived',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

const dummyDataSource = [
  {
    state: 'MI',
    payer: 'MI AETNA',
    amountBilled: 280,
    dateBilled: '2024-10-14',
    amountExpected: 280,
    amountReceived: 197.21,
    dateReceived: '2024-10-23',
  },
  {
    state: 'MI',
    payer: 'MI AETNA',
    amountBilled: 112.32,
    dateBilled: '2024-10-16',
    amountExpected: 112.32,
    amountReceived: 89.93,
    dateReceived: '2024-10-21',
  },
  {
    state: 'MI',
    payer: 'MI AETNA',
    amountBilled: 87.21,
    dateBilled: '2024-09-12',
    amountExpected: 0,
    amountReceived: 0,
    dateReceived: '2024-10-03',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'expectedVsCollectionsReport',
    label: 'Expected vs Collections - Detailed Report',
    status: 'active',
    link: 'expected-vs-collection-report',
  },
];

export const ExpectedVsCollectionsReportPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchExpectedVsCollectionsReportData,
    fetchExpectedVsCollectionsReportStatus,
    fetchExpectedVsCollectionsReportLoading,
  } = useAppSelector(getExpectedVsCollectionsReportSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemProvidersProviderData?.length === 0) {
      fetchSystemProviders();
    }
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchExpectedVsCollectionsReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchExpectedVsCollectionsReportStatus === 'SUCCESS') {
      setDataSource(fetchExpectedVsCollectionsReportData?.items);
      setCurrentPage(fetchExpectedVsCollectionsReportData?.currentPage);
      setTotalPages(fetchExpectedVsCollectionsReportData?.totalPages);
      dispatch(clearFetchExpectedVsCollectionsReportResponse());
    } else if (fetchExpectedVsCollectionsReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchExpectedVsCollectionsReportResponse());
    }
  }, [fetchExpectedVsCollectionsReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchExpectedVsCollectionsReport(page - 1);
  };

  const fetchExpectedVsCollectionsReport = async (pageNumber = currentPage) => {
    dispatch(getExpectedVsCollectionsReportRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Expected vs Collections - Detailed Report"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />
      <ExpectedVsCollectionsReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchExpectedVsCollectionsReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="flex justify-end mb-4">
                <div className="flex items-center">
                  <label htmlFor="search" className="mr-2 text-gray-600">
                    Search:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded px-2 py-1"
                    name="search"
                    title="Search"
                    disabled
                  />
                </div>
              </div>

              <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
