import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { SendBulkClaimsPropsInterface } from '../../../interfaces/billingInterface';
import { sendBulkClaimsAPIRequest } from '../../../api/billing/claims';

const initialState: SendBulkClaimsPropsInterface = {
  sendBulkClaimsLoading: false,
  sendBulkClaimsData: {},
  sendBulkClaimsError: null,
  sendBulkClaimsStatus: 'IDLE',
};

export const sendBulkClaimsRequest: any = createAsyncThunk('primaryClaims/sendBulkClaims', async (thunkAPI: any) => {
  try {
    const response: any = await sendBulkClaimsAPIRequest();
    return response;
  } catch (error: any) {
    console.log(error);
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const sendBulkClaimsSlice = createSlice({
  name: 'sendBulkClaims',
  initialState,
  reducers: {
    clearSendBulkClaimsResponse: (state) => {
      state.sendBulkClaimsLoading = false;
      state.sendBulkClaimsStatus = 'IDLE';
      state.sendBulkClaimsData = {};
      state.sendBulkClaimsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendBulkClaimsRequest.pending, (state, action) => {
        state.sendBulkClaimsLoading = true;
        state.sendBulkClaimsStatus = 'PENDING';
      })
      .addCase(sendBulkClaimsRequest.fulfilled, (state, action) => {
        state.sendBulkClaimsLoading = false;
        state.sendBulkClaimsData = action.payload;
        state.sendBulkClaimsStatus = 'SUCCESS';
      })
      .addCase(sendBulkClaimsRequest.rejected, (state, action) => {
        state.sendBulkClaimsLoading = false;
        state.sendBulkClaimsError = action.payload;
        state.sendBulkClaimsStatus = 'FAILED';
      });
  },
});

export const { clearSendBulkClaimsResponse } = sendBulkClaimsSlice.actions;

export const sendBulkClaimsSelector = (state: RootState) => state.SendBulkClaims;

export default sendBulkClaimsSlice.reducer;
