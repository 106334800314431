import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { Pagination } from '../../../pagination/Pagination';
import { Spinner } from '../../../atoms/Spinner';
import { CommonAlert } from '../../../atoms/Alert';

import {
  getPatientsRequest,
  getPatientsSelector,
  clearFetchPatientResponse,
} from '../../../../redux/slices/patient/getPatientSlice';

const PAGE_SIZE = 5;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text: any, record: any) => {
      return (
        <span>
          {record?.firstName ?? ''}
          {record?.lastName ? ` ${record?.lastName}` : ''}
        </span>
      );
    },
  },
  {
    title: 'DOB',
    dataIndex: 'dob',
    key: 'dob',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

export const PatientSearchModal = ({ patientSearchModalOpen, setOpenPatientSearchModal, selectPatient }: any) => {
  const dispatch = useAppDispatch();
  const { patientData, status } = useAppSelector(getPatientsSelector);

  const [dataSource, setDataSource] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

  useEffect(() => {
    setFetchDataLoading(true);
    getPatientData(0);
  }, []);

  useEffect(() => {
    if (status === 'SUCCESS') {
      setDataSource(patientData?.items);
      setTotalPages(patientData?.totalPages);
      setCurrentPage(patientData?.currentPage);
      setFetchDataLoading(false);
      dispatch(clearFetchPatientResponse());
    } else if (status === 'FAILED') {
      setFetchDataLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearFetchPatientResponse());
    }
  }, [status]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    getPatientData(page - 1);
  };

  const getPatientData = (page: number) => {
    const obj = {
      page: page ?? currentPage,
      size: PAGE_SIZE,
      filters: formData,
    };
    dispatch(getPatientsRequest(obj));
  };

  const handleSearch = () => {
    getPatientData(0);
  };

  const handleReset = () => {
    setFormData({});
    setIsSearchEnabled(false);
    const obj = { page: 0, size: PAGE_SIZE, filters: {} };
    dispatch(getPatientsRequest(obj));
  };

  const handleSelectPatient = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    const obj = {
      id: dataSet?.id,
      name: `${dataSet.firstName} ${dataSet.lastName}`,
    };
    selectPatient(obj);
    setAlertObj({
      color: 'success',
      message: 'Patient Selected !',
      error: false,
    });
    setVisibleAlert(true);
    setTimeout(() => {
      setAlertObj({
        color: '',
        message: '',
        error: false,
      });
      setVisibleAlert(false);
      setOpenPatientSearchModal(false);
    }, 1000);
  };

  const handleFieldValueChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (
      (!updatedFormData?.lastName || updatedFormData?.lastName?.trim() === '') &&
      (!updatedFormData?.firstName || updatedFormData?.firstName?.trim() === '')
    ) {
      setIsSearchEnabled(false);
    } else {
      setIsSearchEnabled(true);
    }
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <Modal show={patientSearchModalOpen} onClose={() => setOpenPatientSearchModal(false)}>
      <Modal.Header>Search Patient</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField
            label="Last Name"
            name="lastName"
            onChange={handleFieldValueChange}
            value={formData?.lastName ?? ''}
          />
          <InputField
            label="First Name"
            name="firstName"
            onChange={handleFieldValueChange}
            value={formData?.firstName ?? ''}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <div className="col-span-1"></div>
          <div className="col-span-1 flex justify-end space-x-2">
            <CommonButton type="button" label={'Reset'} buttonType="secondary" onClick={handleReset} />
            <CommonButton
              type="button"
              label={'Search'}
              buttonType="primary"
              onClick={handleSearch}
              disabled={!isSearchEnabled}
            />
          </div>
        </div>

        {fetchDataLoading ? (
          <Spinner />
        ) : (
          <>
            {dataSource && dataSource?.length > 0 && (
              <>
                <TableContent
                  columns={columns}
                  dataSource={dataSource}
                  enableActions
                  enableAdd
                  onHandleAdd={handleSelectPatient}
                />
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
