import { Modal, Accordion } from 'flowbite-react';
import { CommonButton } from '../../atoms/CommonButton';

export const BulkClaimsStatusModal = ({
  claimsData,
  isBulkClaimsStatusModalOpen,
  onCloseBulkClaimsStatusModal,
}: any) => {
  const successfulClaims = claimsData?.filter((claim: any) => claim?.success === true) || [];
  const unsuccessfulClaims = claimsData?.filter((claim: any) => claim?.success === false) || [];

  return (
    <Modal show={isBulkClaimsStatusModalOpen} onClose={onCloseBulkClaimsStatusModal} size="5xl">
      <Modal.Header>Sent Claims Statuses</Modal.Header>
      <Modal.Body>
        <Accordion alwaysOpen>
          {/* Successful Claims */}
          <Accordion.Panel>
            <Accordion.Title>
              <span className="font-bold text-green-600">Successful ({successfulClaims.length})</span>
            </Accordion.Title>
            <Accordion.Content>
              {successfulClaims.length > 0 ? (
                successfulClaims.map((claim: any) => (
                  <div key={claim.claimId} className="flex justify-between space-x-5 mb-4">
                    <span className="font-semibold min-w-[100px]">{`Claim #${claim.claimId}`}</span>
                    <span className="flex-grow text-justify">{claim.message}</span>
                  </div>
                ))
              ) : (
                <p>No successful claims available.</p>
              )}
            </Accordion.Content>
          </Accordion.Panel>

          {/* Unsuccessful Claims */}
          <Accordion.Panel>
            <Accordion.Title>
              <span className="font-bold text-red-600">Unsuccessful ({unsuccessfulClaims.length})</span>
            </Accordion.Title>
            <Accordion.Content>
              {unsuccessfulClaims.length > 0 ? (
                unsuccessfulClaims.map((claim: any) => (
                  <div key={claim.claimId} className="flex justify-between space-x-5 mb-4">
                    <span className="font-semibold min-w-[100px]">{`Claim #${claim.claimId}`}</span>
                    <span className="flex-grow text-justify">{claim.message}</span>
                  </div>
                ))
              ) : (
                <p>No unsuccessful claims available.</p>
              )}
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </Modal.Body>
      <Modal.Footer className="flex justify-end">
        <CommonButton
          type="button"
          label="OK"
          buttonType="primary"
          onClick={onCloseBulkClaimsStatusModal}
          style={{ width: '70px' }}
        />
      </Modal.Footer>
    </Modal>
  );
};
