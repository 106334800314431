import { useEffect, useState } from 'react';
import { CommonButton } from '../../atoms/CommonButton';
import { TableContent } from '../../table/TableContent';
import { RightSheet } from '../../rightSheet/RightSheet';
import { AddressForm } from './AddressForm';
import { Spinner } from '../../atoms/Spinner';
import { AddressListPropsInterface } from '../../../interfaces/userInterface';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import {
  addUserAddressSelector,
  clearEditAddressResponse,
  editAddressRequest,
} from '../../../redux/slices/user/editAddressSlice';
import { getAddressRequest, getUserAddressSelector } from '../../../redux/slices/user/getAddressSlice';
import { CommonAlert } from '../../atoms/Alert';
import { userAddressValidation } from '../../../utils/userValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const columns = [
  {
    title: 'Primary',
    dataIndex: 'primary',
    key: 'primary',
  },
  {
    title: 'Address',
    dataIndex: 'address1',
    key: 'address1',
    render: (text: any, record: any) => {
      return (
        <span>
          {record?.address1}
          {record?.address2 ? `, ${record?.address2}` : ''}
        </span>
      );
    },
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Zip',
    dataIndex: 'zipCode',
    key: 'zipCode',
  },
];

// const dataSource = [
//   {
//     primary: 'A / A / 1 / 11',
//     address: 'Ranpokunagama',
//     city: 'Nittambuwa',
//     state: 'Gampaha',
//     zip: '11880',
//   },
//   {
//     primary: 'A / A / 1 / 11',
//     address: 'Ranpokunagama',
//     city: 'Nittambuwa',
//     state: 'Gampaha',
//     zip: '11880',
//   },
//   {
//     primary: 'A / A / 1 / 11',
//     address: 'Ranpokunagama',
//     city: 'Nittambuwa',
//     state: 'Gampaha',
//     zip: '11880',
//   },
//   {
//     primary: 'A / A / 1 / 11',
//     address: 'Ranpokunagama',
//     city: 'Nittambuwa',
//     state: 'Gampaha',
//     zip: '11880',
//   },
// ];

export const AddressList: React.FC<AddressListPropsInterface> = ({ selectedId, mode }) => {
  const dispatch = useAppDispatch();
  const { editAddressStatus, editAddressLoading } = useAppSelector(addUserAddressSelector);
  const { status, addressData, loading } = useAppSelector(getUserAddressSelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [addressFormData, setAddressFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const [childMode, setChildMode] = useState('CREATE');
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    if (selectedId) {
      setAddressFormData({});
      setVisibleModal(false);
      fetchAddressDetails();
    }
  }, []);

  useEffect(() => {
    if (status === 'SUCCESS' && !loading) {
      setDataSource(addressData);
    }
  }, [status]);

  useEffect(() => {
    if (editAddressStatus === 'SUCCESS' && !editAddressLoading) {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      fetchAddressDetails();
      setVisibleModal(false);
      setAddressFormData({});
      setSelectedToEdit({});
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (editAddressStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearEditAddressResponse());
  }, [editAddressStatus]);

  const fetchAddressDetails = async () => {
    dispatch(getAddressRequest({ userId: selectedId }));
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setChildMode('EDIT');
    setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setChildMode('VIEW');
    const dataSet = dataSource[rowIndex];
    setSelectedToEdit(dataSet);
    setVisibleModal(true);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const handleAddNewModal = () => {
    setChildMode('CREATE');
    setAddressFormData({});
    setSelectedToEdit({});
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    setChildMode('CREATE');
    setAddressFormData({});
    setSelectedToEdit({});
    setVisibleModal(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    fetchAddressDetails();
  };

  const handleAddressFormChange = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setAddressFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = () => {
    // addressFormData['state'] = 'FL';
    addressFormData['country'] = 'US';
    let obj: any = {
      userId: selectedId,
      addressData: addressFormData,
      // method: childMode === 'CREATE' ? 'post' : 'put',
    };

    const validationDetails = userAddressValidation(obj?.addressData);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setErrorObj(validationDetails?.newErrors);
      const errorMessages = Object.values(validationDetails.newErrors);
      setVisibleAlert(true);
      setAlertObj({
        color: 'failure',
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span style={{ marginLeft: '8px' }}>{msg}</span>
              </div>
            ))}
          </div>
        ),
        error: true,
      });
    } else {
      dispatch(editAddressRequest(obj));
    }

    // if (childMode === 'CREATE') {
    // dispatch(editAddressRequest(obj));
    // } else if (childMode === 'EDIT') {
    //   obj['addressId'] = selectedToEdit?.id
    //   dispatch(editAddressRequest(obj));
    // }
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  // console.log('=== addressFormData ===', addressFormData);

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      {mode !== 'VIEW' && (
        <div className="w-64 ml-auto">
          <CommonButton
            label="Add New"
            buttonType="primary"
            // icon={
            //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            //     <path
            //       fill-rule="evenodd"
            //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
            //       clip-rule="evenodd"
            //     />
            //   </svg>
            // }
            onClick={handleAddNewModal}
          />
        </div>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <TableContent
          enableActions={true}
          columns={columns}
          dataSource={dataSource}
          enableDelete={false}
          enableEdit={false}
          enableView={true}
          onDelete={onDelete}
          onEdit={onEdit}
          onView={onView}
        />
      )}

      {visibleModal && (
        <RightSheet
          title={childMode === 'VIEW' ? 'View Address' : 'Add / Edit Address'}
          submitButtonTitle="Submit"
          cancelButtonTitle="Cancel"
          children={
            <AddressForm
              onChange={handleAddressFormChange}
              addressFormEditData={selectedToEdit}
              errorObject={errorObj}
              mode={childMode}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          alertOnClose={errorAlertOnClose}
          enableCancelButton={childMode !== 'VIEW'}
          enableSubmitButton={childMode !== 'VIEW'}
        />
      )}
    </>
  );
};
