export const createBatchPaymentValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.batchType) {
    newErrors.batchType = 'Batch Type is required';
    isValid = false;
  }

  if (!formData?.checkDate) {
    newErrors.checkDate = 'Check Date is required';
    isValid = false;
  }

  if (!formData?.checkNumber?.trim()) {
    newErrors.checkNumber = 'Check Number is required';
    isValid = false;
  }

  if (!formData?.description?.trim()) {
    newErrors.description = 'Description is required';
    isValid = false;
  }

  if (!formData?.paymentAmount) {
    newErrors.paymentAmount = 'Payment Amount is required';
    isValid = false;
  }

  return { newErrors, isValid };
};
