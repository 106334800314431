import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { SelectInput } from '../../../atoms/SelectInput';

import {
  addInsuranceSubscriberRequest,
  addInsuranceSubscriberSelector,
  clearAddPatientInsuranceSubscriberResponse,
} from '../../../../redux/slices/patient/addInsuranceSubscriberSlice';

import { sexStatus } from '../../../../constants/userConstants';
import { formatDatePickerDate, validateEmail } from '../../../../utils/commonFunctions';

export const AddNewSubscriberModal = ({
  visibleNewSubscriberModal,
  setVisibleNewSubscriberModal,
  getSubscriberData,
  setFetchDataLoading,
}: any) => {
  const dispatch = useAppDispatch();

  const { addInsuranceSubscriberStatus } = useAppSelector(addInsuranceSubscriberSelector);

  const [formData, setFormData] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (addInsuranceSubscriberStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully added !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleNewSubscriberModal(false);
        setFetchDataLoading(true);
        getSubscriberData(0);
      }, 2000);
      dispatch(clearAddPatientInsuranceSubscriberResponse());
    } else if (addInsuranceSubscriberStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddPatientInsuranceSubscriberResponse());
    }
  }, [addInsuranceSubscriberStatus]);

  const handleFieldValueChange = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleOnChangeDate = (name: string, date: any) => {
    setFormData((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  const onSubmit = () => {
    if (formData?.email && !validateEmail(formData.email)) {
      setAlertObj({
        color: 'failure',
        message: 'Enter a valid Email',
        error: true,
      });
      setVisibleAlert(true);

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 2000);

      return;
    }

    dispatch(addInsuranceSubscriberRequest(formData));
  };

  const onCloseModal = () => {
    setFormData({});
    setVisibleNewSubscriberModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <Modal show={visibleNewSubscriberModal} onClose={onCloseModal}>
      <Modal.Header>Add Subscriber</Modal.Header>
      <Modal.Body>
        {visibleAlert && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={() => setVisibleAlert(false)}
            alertClassName="w-5/12"
          />
        )}
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField
            label="First Name"
            name="firstName"
            onChange={handleFieldValueChange}
            value={formData?.firstName ?? ''}
          />
          <InputField
            onChange={handleFieldValueChange}
            name="lastName"
            label="Last Name"
            value={formData?.lastName ?? ''}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          {/* <Calendar onSelectedDateChanged={(date) => handleOnChangeDate('dob', date)} name="dob" label="DOB" /> */}
          <DatePickerField
            label="DOB"
            name="dob"
            selectedDate={formatDatePickerDate(formData?.dob)}
            onChange={(date: Date | null) => {
              if (date) {
                handleOnChangeDate('dob', date);
              }
            }}
          />
          <SelectInput label={'Gender'} name="gender" onChange={handleFieldValueChange} options={sexStatus} />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField label="Email" name="email" onChange={handleFieldValueChange} value={formData?.email ?? ''} />
          <InputField onChange={handleFieldValueChange} name="phone" label="Phone" value={formData?.phone ?? ''} />
        </div>
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton type="button" label={'Cancel'} buttonType="secondary" onClick={onCloseModal} />
          <CommonButton type="button" label={'Add'} buttonType="primary" onClick={onSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
