import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchBatchPaymentDetailsByIdInterface,
  FetchBatchPaymentByIdInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getBatchPaymentByIdAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: FetchBatchPaymentByIdInitialInterface = {
  fetchBatchPaymentByIdLoading: false,
  fetchBatchPaymentByIdData: {},
  fetchBatchPaymentByIdError: null,
  fetchBatchPaymentByIdStatus: 'IDLE',
};

export const getBatchPaymentByIdRequest: any = createAsyncThunk(
  'batch-payment/get/id',
  async (searchParams: FetchBatchPaymentDetailsByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getBatchPaymentByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBatchPaymentByIdSlice = createSlice({
  name: 'fetchBatchPaymentById',
  initialState,
  reducers: {
    clearFetchBatchPaymentByIdResponse: (state) => {
      state.fetchBatchPaymentByIdLoading = false;
      state.fetchBatchPaymentByIdData = {};
      state.fetchBatchPaymentByIdStatus = 'IDLE';
      state.fetchBatchPaymentByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBatchPaymentByIdRequest.pending, (state, action) => {
        state.fetchBatchPaymentByIdLoading = true;
        state.fetchBatchPaymentByIdStatus = 'PENDING';
      })
      .addCase(getBatchPaymentByIdRequest.fulfilled, (state, action) => {
        state.fetchBatchPaymentByIdLoading = false;
        state.fetchBatchPaymentByIdStatus = 'SUCCESS';
        state.fetchBatchPaymentByIdData = action.payload;
      })
      .addCase(getBatchPaymentByIdRequest.rejected, (state, action) => {
        state.fetchBatchPaymentByIdLoading = false;
        state.fetchBatchPaymentByIdStatus = 'FAILED';
        state.fetchBatchPaymentByIdError = action.payload;
      });
  },
});

export const { clearFetchBatchPaymentByIdResponse } = getBatchPaymentByIdSlice.actions;

export const getBatchPaymentByIdSelector = (state: RootState) => state.GetBatchPaymentById;

export default getBatchPaymentByIdSlice.reducer;
