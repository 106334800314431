import { Modal } from 'flowbite-react';
import { TableContent } from '../../../table/TableContent';
import moment from 'moment';
import { validDateFormat } from '../../../../utils/commonFunctions';

const columns = [
  {
    title: 'Visit Status',
    dataIndex: 'visitStatus',
    key: 'visitStatus',
  },
  {
    title: 'Notes',
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'User',
    dataIndex: 'responsible',
    key: 'responsible',
  },
  {
    title: 'Created On',
    dataIndex: 'created',
    key: 'created',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY HH:mm:ss') : ''),
  },
];

export const StatusHistoryModal = ({ visibleHistoryModal, setVisibleHistoryModal, dataSource, visitId }: any) => {
  return (
    <Modal show={visibleHistoryModal} onClose={() => setVisibleHistoryModal(false)}>
      <Modal.Header>Visit History</Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-1 gap-5 mt-2">Visit History #{visitId}</div>
        <TableContent dataSource={dataSource} columns={columns} />
      </Modal.Body>
    </Modal>
  );
};
