import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchCategoryListInitialInterface } from '../../../interfaces/systemInterfaces';
import { getCategoryListAPIRequest } from '../../../api/system/patient';

const initialState: FetchCategoryListInitialInterface = {
  categoryListLoading: false,
  categoryListData: [],
  categoryListError: null,
  categoryListStatus: 'IDLE',
};

export const getCategoryListRequest: any = createAsyncThunk('patient/category/get', async (thunkAPI: any) => {
  try {
    const response: any = await getCategoryListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getCategoryListSlice = createSlice({
  name: 'fetchCategory',
  initialState,
  reducers: {
    clearFetchCategoryListResponse: (state) => {
      state.categoryListLoading = false;
      state.categoryListStatus = 'IDLE';
      state.categoryListData = [];
      state.categoryListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryListRequest.pending, (state, action) => {
        state.categoryListLoading = true;
        state.categoryListStatus = 'PENDING';
      })
      .addCase(getCategoryListRequest.fulfilled, (state, action) => {
        state.categoryListLoading = false;
        state.categoryListData = action.payload;
        state.categoryListStatus = 'SUCCESS';
      })
      .addCase(getCategoryListRequest.rejected, (state, action) => {
        state.categoryListLoading = false;
        state.categoryListError = action.payload;
        state.categoryListStatus = 'FAILED';
      });
  },
});

export const { clearFetchCategoryListResponse } = getCategoryListSlice.actions;

export const getCategoryListSelector = (state: RootState) => state.GetCategoryList;

export default getCategoryListSlice.reducer;
