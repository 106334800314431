import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { TableContent } from '../../../components/table/TableContent';
import { InputField } from '../../../components/atoms/InputField';
import { PrimaryButton } from '../../../components/atoms/PrimaryButton';
import { CustomSearchButton } from '../../../components/list/patients/visit/AddCPTModal';
import { SelectInput } from '../../../components/atoms/SelectInput';
import { PatientSearchModal } from '../../../components/billing/batchPayment/bulkPayments/PatientSearchModal';
import { AddDescriptionModal } from '../../../components/billing/batchPayment/bulkPayments/AddDescriptionModal';

import {
  getBulkPaymentsListRequest,
  getBulkPaymentsListSelector,
  clearFetchBulkPaymentsListResponse,
} from '../../../redux/slices/payments/batchPayments/getBulkPaymentsListSlice';

import { billingCodes } from '../../../constants/patientConstants';
import { BulkPaymentsPropsInterface } from '../../../interfaces/paymentInterfaces';

import { formatCurrency } from '../../../utils/commonFunctions';

const columns = [
  {
    title: 'DOS',
    dataIndex: 'dos',
    key: 'dos',
    render: (text: string, record: any) => {
      return record.newRow ? <></> : moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'CPT',
    dataIndex: 'cpt',
    key: 'cpt',
  },
  {
    title: 'Units',
    dataIndex: 'units',
    key: 'units',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number, record: any) => {
      return record.newRow ? <></> : formatCurrency(text ?? 0);
    },
  },
  {
    title: 'Modifier',
    dataIndex: 'modifier',
    key: 'modifier',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number, record: any) => {
      return record.newRow ? <></> : formatCurrency(text ?? 0);
    },
  },
  {
    title: 'Billing Code',
    dataIndex: 'billingCode',
    key: 'billingCode',
    render: (text: any, record: any) => (
      <SelectInput
        options={billingCodes}
        enableDefaultPlaceholder={true}
        name="billingCode"
        defaultValue={record?.applicationStatus ?? undefined}
        enableLabel={false}
        // onChange={(e: any) => handleApplicationStatusChange(record?.id, e.target.value)}
      />
    ),
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
    render: (text: any, record: any) => {
      if (record.newRow) return <></>;
      const claimIds = text.split(',').map((id: any) => ({ id, name: id }));
      return <SelectInput options={claimIds} enableDefaultPlaceholder={true} enableLabel={false} />;
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amountFillable',
    key: 'amountFillable',
    render: (text: any, record: any) => <InputField placeholder="Amount" enableLabel={false} />,
  },
  {
    title: 'Allowed',
    dataIndex: 'allowedAmount',
    key: 'allowedAmount',
    render: (text: any, record: any) => {
      return record.newRow ? <></> : <InputField placeholder="Allowed Amount" enableLabel={false} />;
    },
  },
];

const dummyDataSource = {
  patient: {
    patientId: 218,
    patientName: 'Vishal Hank',
  },
  whoPaid: [
    {
      value: 'Patient',
      label: 'Vishal Hank',
    },
    {
      value: 'Primary',
      label: 'Medicare',
    },
    {
      value: 'Secondary',
      label: 'Health Care',
    },
    {
      value: 'Tertiary',
      label: 'Divine Life',
    },
  ],
  items: [
    {
      dos: '2024-01-01',
      cpt: '99213',
      units: 1,
      amount: 150,
      modifier: '25',
      balance: 50,
      claimId: '13425,12123,43434',
    },
    {
      dos: '2024-02-01',
      cpt: '99354',
      units: 2,
      amount: 300,
      modifier: '',
      balance: 100,
      claimId: '89321',
    },
  ],
};

export const BulkPayments: React.FC<BulkPaymentsPropsInterface> = ({ batchData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fetchBulkPaymentListStatus, fetchBulkPaymentListData } = useAppSelector(getBulkPaymentsListSelector);

  const [patientSearchData, setPatientSearchData] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>(dummyDataSource?.items);
  const [visiblePatientSearchModal, setVisiblePatientSearchModal] = useState<boolean>(false);
  const [visibleAddDescriptionModal, setVisibleAddDescriptionModal] = useState<boolean>(false);
  const [previousPatientId, setPreviousPatientId] = useState<string | null>(null);

  useEffect(() => {
    if (patientSearchData?.patientId && patientSearchData.patientId !== previousPatientId) {
      setPatientSearchData((prev: any) => ({
        ...prev,
        patientName: '',
        patientId: patientSearchData.patientId,
      }));
    }
  }, [patientSearchData.patientId, previousPatientId]);

  const onSubmit = () => {
    dispatch(getBulkPaymentsListRequest({ patientId: patientSearchData?.patientId, batchId: batchData?.id }));
  };

  const onChangeId = (e: any) => {
    setPatientSearchData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectPatient = (data: any) => {
    setPatientSearchData((prev: any) => ({ ...prev, patientName: data?.name, patientId: data?.id }));
    setPreviousPatientId(data?.id);
  };

  const handleAddRow = (event: any, rowIndex: any) => {
    // console.log('ROW INDEX', rowIndex);
    event.preventDefault();
    if (rowIndex !== null) {
      const newRow = {
        newRow: true,
      };
      setDataSource((prev: any) => {
        const newData = [...prev];
        newData.splice(rowIndex + 1, 0, newRow);
        return newData;
      });
    }
  };

  const handleClickNote = (event: any, rowIndex: any) => {
    setVisibleAddDescriptionModal(true);
  };

  const handleAddDescription = (data: any) => {
    // console.log('data', data);
  };

  const handlePatientClick = () => {
    navigate(`/patients?id=${dummyDataSource?.patient?.patientId}`, {
      state: { fromBulkPayments: true },
    });
  };

  const formattedWhoPaidOptions = dummyDataSource?.whoPaid?.map((item: any) => {
    if (item.value === 'Patient') {
      return { id: item.value, name: item.label };
    }
    return { id: item.value, name: `${item.label} (${item.value})` };
  });

  const renderCustomRowContent = () => {
    const insurances: any = [];

    dummyDataSource?.whoPaid?.forEach((item: any) => {
      if (item.value === 'Primary' || item.value === 'Secondary' || item.value === 'Tertiary') {
        insurances.push(`${item.value}: ${item.label}`);
      }
    });

    return (
      <div className="font-bold">
        <span className="text-red-500">Case ID:</span>{' '}
        <span className="text-black mr-5">{dummyDataSource.patient.patientId}</span>
        {insurances.map((info: any, index: any) => (
          <span key={index} className="text-red-500 mr-5">
            {info}
          </span>
        ))}
      </div>
    );
  };
  // console.log('Batch Data', batchData);
  // console.log('Selected patient', patientSearchData);
  // console.log('dataSource', dataSource);
  return (
    <>
      <div className="grid md:grid-cols-5 gap-4 mt-6">
        <InputField label="Batch ID" name="id" disabled defaultValue={batchData?.id ?? undefined} />
        <InputField
          label="Payment Date"
          name="checkDate"
          disabled
          defaultValue={
            batchData?.checkDate && moment(batchData.checkDate).isValid()
              ? moment(batchData.checkDate).format('MM/DD/YYYY')
              : undefined
          }
        />
        <InputField
          label="Check Number"
          name="checkNumber"
          disabled
          defaultValue={batchData?.checkNumber ?? undefined}
        />
        <InputField
          label="Description"
          name="description"
          disabled
          defaultValue={batchData?.description ?? undefined}
        />
        <InputField
          label="UnApplied"
          name="unAppliedAmount"
          disabled
          defaultValue={formatCurrency(batchData?.unAppliedAmount ?? 0)}
        />
      </div>
      <div className="grid md:grid-cols-5 gap-4 mt-6 items-end">
        <div className="flex items-end">
          {' '}
          <InputField
            label=""
            name="patientName"
            placeholder="Patient Name"
            disabled
            value={patientSearchData?.patientName ?? undefined}
          />
          <CustomSearchButton onClick={() => setVisiblePatientSearchModal(true)} />
        </div>

        <InputField
          label=""
          name="patientId"
          placeholder="Patient ID"
          value={patientSearchData?.patientId ?? ''}
          onChange={onChangeId}
        />
        <PrimaryButton type="button" label="Submit" style={{ height: '40px', maxWidth: '100px' }} onClick={onSubmit} />
      </div>

      <div className="mt-14">
        <div className="text-black">
          Patient Name:{' '}
          <span className="text-red-500 no-underline cursor-pointer" onClick={handlePatientClick}>
            {dummyDataSource?.patient?.patientName ?? ''}
          </span>
        </div>
        <div className="grid md:grid-cols-4 gap-4 mt-2 mb-8 items-end">
          <SelectInput defaultPlaceholder="Select Who Paid" options={formattedWhoPaidOptions} />
          <div className="col-span-1 flex justify-start space-x-2">
            <PrimaryButton type="button" label="Reload" style={{ height: '40px', maxWidth: '100px' }} />
            <PrimaryButton type="button" label="Submit" style={{ height: '40px', maxWidth: '100px' }} />
          </div>
          <div className="col-span-2"></div>
        </div>

        <TableContent
          columns={columns}
          dataSource={dataSource}
          enableActions
          enableAdd
          enableNote
          onHandleAdd={handleAddRow}
          onHandleNote={handleClickNote}
          enableCustomRow={true}
          customRowContent={renderCustomRowContent()}
        />
      </div>

      {visiblePatientSearchModal && (
        <PatientSearchModal
          patientSearchModalOpen={visiblePatientSearchModal}
          setOpenPatientSearchModal={setVisiblePatientSearchModal}
          selectPatient={handleSelectPatient}
        />
      )}

      {visibleAddDescriptionModal && (
        <AddDescriptionModal
          addDescriptionModalOpen={visibleAddDescriptionModal}
          setOpenAddDescriptionModal={setVisibleAddDescriptionModal}
          onSubmit={handleAddDescription}
        />
      )}
    </>
  );
};
