import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { Checkbox } from '../../../atoms/Checkbox';
import { CommonButton } from '../../../atoms/CommonButton';
import { applicationStatus, carriers } from '../../../../constants/systemConstants';
import { TableContent } from '../../../table/TableContent';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { getProviderByIdSelector } from '../../../../redux/slices/provider/getProviderByIdSlice';
import { ProviderCarriersInterface } from '../../../../interfaces/providersInterface';
import { useAppDispatch } from '../../../../redux/store';
import { createProviderCarriersValidation } from '../../../../utils/providerValidation';
import {
  addProviderCarriersRequest,
  addProviderCarriersSelector,
  clearAddProviderCarriersResponse,
} from '../../../../redux/slices/provider/addProviderCarrierSlice';
import {
  deleteProviderCarriersByIdRequest,
  deleteProviderCarriersByIdSelector,
  clearDeleteProviderCarriersByIdResponse,
} from '../../../../redux/slices/provider/deleteCarrierSlice';
import {
  clearFetchProviderCarriersByIdResponse,
  getProviderCarriersByIdRequest,
  getProviderCarriersByIdSelector,
} from '../../../../redux/slices/provider/getProviderCarrierByIdSlice';
import {
  clearEditProviderCarriersResponse,
  editProviderCarriersRequest,
  editProviderCarriersSelector,
} from '../../../../redux/slices/provider/editProviderCarrierSlice';
import {
  editProviderCarrierApplicationStatusRequest,
  editProviderCarrierApplicationStatusSelector,
  clearEditProviderCarrierApplicationStatus,
} from '../../../../redux/slices/provider/editProviderCarrierApplicationStatusSlice';
import {
  editProviderCarrierPendingInfoRequest,
  editProviderCarrierPendingInfoSelector,
  clearEditProviderCarrierPendingInfo,
} from '../../../../redux/slices/provider/editProviderCarrierPendingInfoSlice';
import {
  getSystemInsuranceRequest,
  getSystemInsuranceSelector,
} from '../../../../redux/slices/system/getSystemInsuranceSlice';
import { ProviderCarriersAddDetailsInterface } from '../../../../interfaces/providersInterface';
import { ProviderCarriersForm } from './ProviderCarriersForm';
import { formatDate } from '../../../../utils/commonFunctions';
import { CheckboxPropsInterface } from '../../../../interfaces/atomInterfaces';
import { SelectInput } from '../../../atoms/SelectInput';
import { Spinner } from '../../../atoms/Spinner';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import moment from 'moment';

const columns = (mode: any, handleApplicationStatusChange: any, handlePendingInformationChange: any) => [
  {
    title: 'Carrier Name',
    key: 'carrierName',
    render: (text: string, record: any) => record?.carrierName?.insuranceName,
  },
  {
    title: 'Credentialled',
    dataIndex: 'credentialled',
    key: 'credentialled',
    render: (credentialled: boolean) => (credentialled ? 'Yes' : 'No'),
  },
  {
    title: 'Effective Date',
    dataIndex: 'effectiveDate',
    key: 'effectiveDate',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'Expiration Date',
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'Group Linked',
    dataIndex: 'groupLinked',
    key: 'groupLinked',
    render: (groupLinked: boolean) => (groupLinked ? 'Yes' : 'No'),
  },
  {
    title: 'Application Status',
    dataIndex: 'applicationStatus',
    key: 'applicationStatus',
    render: (text: any, record: any) => (
      <SelectInput
        options={applicationStatus}
        enableDefaultPlaceholder={true}
        name="applicationStatus"
        defaultValue={record?.applicationStatus ?? undefined}
        disabled={mode === 'VIEW' ? true : false}
        onChange={(e: any) => handleApplicationStatusChange(record?.id, e.target.value)}
      />
    ),
  },
  {
    title: 'Pending Information',
    dataIndex: 'pendingInformation',
    key: 'pendingInformation',
    render: (text: any, record: any) => {
      if (!record?.pendingInformation) {
        return null;
      }
      return (
        <div>
          {Object.keys(record?.pendingInformation).map((key, index) => (
            <div key={key}>
              <Checkbox
                defaultChecked={record?.pendingInformation[key]}
                disabled={mode === 'VIEW'}
                onChange={(e: any) => handlePendingInformationChange(record?.id, index + 1, e.target.checked)}
                label={key}
              />
            </div>
          ))}
        </div>
      );
    },
  },
];

const initialPendingInformation = {
  cds: false,
  dea: false,
  certificate: false,
  hospitalAffiliation: false,
  mfa: false,
  pendingSignature: false,
  pli: false,
  token: false,
};

const initialFormData = {
  credentialled: false,
  groupLinked: false,
  pendingInformation: initialPendingInformation,
};

export const ProviderCarriers: React.FC<ProviderCarriersInterface> = ({
  selectedId,
  mode,
  addDataLoading,
  fetchDataLoading,
  fetchProviderById,
}) => {
  const dispatch = useAppDispatch();

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [providerCarriersFormData, setProviderCarriersFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const [carrierOptions, setCarrierOptions] = useState<any[]>([]);

  const [errorObj, setErrorObj] = useState<any>({});
  const [carriersMode, setCarriersMode] = useState<any>('CREATE');
  const [selectedCarriersId, setSelectedCarriersId] = useState<number>();
  const { fetchProviderByIdStatus, fetchProviderByIdData } = useAppSelector(getProviderByIdSelector);
  const { addProviderCarriersStatus, addProviderCarriersLoading } = useAppSelector(addProviderCarriersSelector);

  const { deleteProviderCarriersByIdLoading, deleteProviderCarriersByIdStatus } = useAppSelector(
    deleteProviderCarriersByIdSelector
  );

  const { fetchProviderCarriersByIdData, fetchProviderCarriersByIdStatus } = useAppSelector(
    getProviderCarriersByIdSelector
  );

  const { editProviderCarriersLoading, editProviderCarriersStatus } = useAppSelector(editProviderCarriersSelector);
  const { editProviderApplicationStatusStatus } = useAppSelector(editProviderCarrierApplicationStatusSelector);
  const { editProviderCarrierPendingInfoStatus } = useAppSelector(editProviderCarrierPendingInfoSelector);
  const { systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);

  useEffect(() => {
    if (fetchProviderByIdStatus === 'SUCCESS') {
      setDataSource(fetchProviderByIdData);
      setVisibleModal(false);
    }
  }, [fetchProviderByIdStatus]);

  useEffect(() => {
    fetchSystemInsurance();
    setVisibleModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setErrorObj({});
  }, []);

  useEffect(() => {
    if (addProviderCarriersStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'carrier');
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setProviderCarriersFormData({});
        setErrorObj({});
        setCarriersMode('CREATE');
      }, 3000);
      dispatch(clearAddProviderCarriersResponse());
    } else if (addProviderCarriersStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddProviderCarriersResponse());
    }
  }, [addProviderCarriersStatus]);

  useEffect(() => {
    if (fetchProviderCarriersByIdStatus === 'SUCCESS') {
      let obj = {
        carrierName: fetchProviderCarriersByIdData?.carrierName?.insuranceId,
        credentialled: fetchProviderCarriersByIdData?.credentialled,
        groupLinked: fetchProviderCarriersByIdData?.groupLinked,
        credentialingStatus: fetchProviderCarriersByIdData?.credentialingStatus,
        pin: fetchProviderCarriersByIdData?.pin,
        grp: fetchProviderCarriersByIdData?.grp,
        initiatedDate: fetchProviderCarriersByIdData?.initDate,
        effectiveDate: fetchProviderCarriersByIdData?.effectiveDate,
        expirationDate: fetchProviderCarriersByIdData?.expirationDate,
        lastRevalidationDate: fetchProviderCarriersByIdData?.lastRevalidationDate,
        nextRevalidationDate: fetchProviderCarriersByIdData?.nextRevalidationDate,
        applicationStatus: fetchProviderCarriersByIdData?.applicationStatus,
        pendingInformation: fetchProviderCarriersByIdData?.pendingInformation,
        note: fetchProviderCarriersByIdData?.note,
        status: fetchProviderCarriersByIdData?.status,
      };

      const options = [
        {
          id: fetchProviderCarriersByIdData?.carrierName?.insuranceId,
          name: fetchProviderCarriersByIdData?.carrierName?.insuranceName,
        },
      ];

      setProviderCarriersFormData(obj);
      setCarrierOptions(options);
      dispatch(clearFetchProviderCarriersByIdResponse());
      setVisibleModal(true);
    } else if (fetchProviderCarriersByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchProviderCarriersByIdResponse());
    }
  }, [fetchProviderCarriersByIdStatus]);

  useEffect(() => {
    if (deleteProviderCarriersByIdStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'carrier');
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDeleteProviderCarriersByIdResponse());
    } else if (deleteProviderCarriersByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDeleteProviderCarriersByIdResponse());
    }
  }, [deleteProviderCarriersByIdStatus]);

  useEffect(() => {
    if (editProviderCarriersStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'carrier');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setErrorObj({});
        setProviderCarriersFormData({});
        setCarriersMode('CREATE');
      }, 3000);
      dispatch(clearEditProviderCarriersResponse());
    } else if (editProviderCarriersStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditProviderCarriersResponse());
    }
  }, [editProviderCarriersStatus]);

  useEffect(() => {
    if (editProviderApplicationStatusStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'carrier');
      setAlertObj({
        color: 'success',
        message: 'Application status successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditProviderCarrierApplicationStatus());
    } else if (editProviderApplicationStatusStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditProviderCarrierApplicationStatus());
    }

    if (editProviderCarrierPendingInfoStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'carrier');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditProviderCarrierPendingInfo());
    } else if (editProviderCarrierPendingInfoStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditProviderCarrierPendingInfo());
    }
  }, [editProviderApplicationStatusStatus, editProviderCarrierPendingInfoStatus]);

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setCarriersMode('EDIT');
    setSelectedCarriersId(dataSet?.id);
    getCarriersDetailsGetById(dataSet?.id);
    // setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const getCarriersDetailsGetById = (id: number) => {
    // console.log('request sent');
    dispatch(getProviderCarriersByIdRequest({ providerId: selectedId, carriersId: id }));
  };

  const handleCarriersFormChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }

    const convertedValue = type === 'checkbox' ? checked : value;

    setProviderCarriersFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setProviderCarriersFormData((prevData: any) => ({
      ...prevData,
      pendingInformation: {
        ...prevData.pendingInformation,
        [name]: checked,
      },
    }));
  };

  const handleApplicationStatusChange = (carrierId: any, appStatusId: string) => {
    if (appStatusId === '') {
      setAlertObj({
        color: 'failure',
        message: 'Please select an application status',
        error: true,
      });
      setVisibleAlert(true);

      return;
    }

    dispatch(
      editProviderCarrierApplicationStatusRequest({
        providerId: selectedId,
        carriersId: carrierId,
        appStatusId: parseInt(appStatusId),
      })
    );
  };

  const handlePendingInformationChange = (carrierId: any, infoId: number, checked: boolean) => {
    dispatch(
      editProviderCarrierPendingInfoRequest({
        providerId: selectedId,
        carriersId: carrierId,
        infoId: infoId,
        checked: checked,
      })
    );
  };

  const fetchSystemInsurance = () => {
    dispatch(getSystemInsuranceRequest());
  };

  const onProviderCarriersDateChange = (name: string, value: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setProviderCarriersFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onDelete = (event: any, rowIndex: any) => {
    const dataSet = dataSource[rowIndex];
    setSelectedCarriersId(dataSet?.id);
    dispatch(
      deleteProviderCarriersByIdRequest({
        providerId: selectedId,
        carriersId: dataSet?.id,
      })
    );
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleAddNewModal = () => {
    setProviderCarriersFormData(initialFormData);
    setCarriersMode('CREATE');
    setVisibleModal(true);
    setErrorObj({});
  };

  const onCloseModal = () => {
    setProviderCarriersFormData({});
    setCarriersMode('CREATE');
    setVisibleModal(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const convertBeforeSubmit = (formData: any) => {
    let obj = {
      ...formData,
      carrierName: parseInt(formData?.carrierName, 10),
      applicationStatus: formData?.applicationStatus ? parseInt(formData.applicationStatus, 10) : undefined,
      credentialingStatus: formData?.credentialingStatus ? parseInt(formData.credentialingStatus, 10) : undefined,
      status: parseInt(formData?.status, 10),
      effectiveDate: formatDate(formData?.effectiveDate),
      initiatedDate: formatDate(formData?.initiatedDate),
      expirationDate: formatDate(formData?.expirationDate),
      lastRevalidationDate: formatDate(formData?.lastRevalidationDate),
      nextRevalidationDate: formatDate(formData?.nextRevalidationDate),
    };
    return obj;
  };

  const onSubmit = () => {
    if (carriersMode === 'CREATE') {
      let obj = {
        providerId: selectedId,
        carriersData: convertBeforeSubmit(providerCarriersFormData),
      };

      const validationDetails = createProviderCarriersValidation(providerCarriersFormData);
      // console.log('object', obj);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addProviderCarriersRequest(obj));
      }
    } else if (carriersMode === 'EDIT') {
      let obj = {
        providerId: selectedId,
        carriersData: convertBeforeSubmit(providerCarriersFormData),
        carriersId: selectedCarriersId,
      };

      const validationDetails = createProviderCarriersValidation(providerCarriersFormData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(editProviderCarriersRequest(obj));
      }
    }
  };

  // console.log('selectedId', selectedId);
  // console.log('fetchProviderCarriersByIdStatus', fetchProviderCarriersByIdStatus);
  // console.log('fetchProviderCarriersByIdData', fetchProviderCarriersByIdData);
  // console.log('providerCarriersFormData', providerCarriersFormData);

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            // icon={
            //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            //     <path
            //       fill-rule="evenodd"
            //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
            //       clip-rule="evenodd"
            //     />
            //   </svg>
            // }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      {fetchDataLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns(mode, handleApplicationStatusChange, handlePendingInformationChange)}
              dataSource={dataSource}
              enableDelete={true}
              enableEdit={true}
              enableView={false}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={mode === 'VIEW' ? false : true} mode={mode} />
          )}
        </>
      )}

      {visibleModal && (
        <RightSheet
          title={carriersMode === 'CREATE' ? 'Add Carriers' : 'Edit Carriers'}
          submitButtonTitle="Submit"
          cancelButtonTitle="Cancel"
          children={
            <ProviderCarriersForm
              carriersFormEditData={providerCarriersFormData}
              onChange={handleCarriersFormChange}
              carriersMode={carriersMode}
              carriersNames={carriersMode === 'CREATE' ? systemInsuranceInsuranceData : carrierOptions}
              errorObject={errorObj}
              onDateChange={onProviderCarriersDateChange}
              onCheckBoxGroupChange={handleCheckboxChange}
              selectedCarriersId={selectedCarriersId}
              providerId={selectedId}
              fetchProviderById={fetchProviderById}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      )}
    </>
  );
};
