import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchClaimEraFilesInterface,
  FetchClaimEraFilesInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getClaimEraFilesAPIRequest } from '../../../../api/payments/claimEraFiles';

const initialState: FetchClaimEraFilesInitialInterface = {
  fetchClaimEraFilesLoading: false,
  fetchClaimEraFilesData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchClaimEraFilesError: null,
  fetchClaimEraFilesStatus: 'IDLE',
};

export const getClaimEraFilesRequest: any = createAsyncThunk(
  'claim-era-files/get',
  async (searchParams: FetchClaimEraFilesInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimEraFilesAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimEraFilesSlice = createSlice({
  name: 'fetchClaimEraFiles',
  initialState,
  reducers: {
    clearFetchClaimEraFilesResponse: (state) => {
      state.fetchClaimEraFilesLoading = false;
      state.fetchClaimEraFilesStatus = 'IDLE';
      state.fetchClaimEraFilesData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchClaimEraFilesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimEraFilesRequest.pending, (state, action) => {
        state.fetchClaimEraFilesLoading = true;
        state.fetchClaimEraFilesStatus = 'PENDING';
      })
      .addCase(getClaimEraFilesRequest.fulfilled, (state, action) => {
        state.fetchClaimEraFilesLoading = false;
        state.fetchClaimEraFilesStatus = 'SUCCESS';
        state.fetchClaimEraFilesData = action.payload;
      })
      .addCase(getClaimEraFilesRequest.rejected, (state, action) => {
        state.fetchClaimEraFilesLoading = false;
        state.fetchClaimEraFilesError = action.payload;
        state.fetchClaimEraFilesStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimEraFilesResponse } = getClaimEraFilesSlice.actions;

export const getClaimEraFilesSelector = (state: RootState) => state.GetClaimEraFiles;

export default getClaimEraFilesSlice.reducer;
