import { Modal } from 'flowbite-react';
import { TableContent } from '../../table/TableContent';
import moment from 'moment';

const columns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text: string) => moment(text).format('MM/DD/YYYY HH:mm:ss'),
  },
];

export const ClaimHistoryModal = ({ claimHistoryModal, setClaimHistoryModal, dataSource }: any) => {
  return (
    <Modal show={claimHistoryModal} onClose={() => setClaimHistoryModal(false)}>
      <Modal.Header>Claim History</Modal.Header>
      <Modal.Body>
        <TableContent dataSource={dataSource} columns={columns} />
      </Modal.Body>
    </Modal>
  );
};
