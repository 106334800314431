import { useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { validDateFormat } from '../../../utils/commonFunctions';
import moment from 'moment';

const columns = [
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="dashboard">
        {text}
      </a>
    ),
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
    render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Billed Date',
    dataIndex: 'billedDate',
    key: 'billedDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
  },
];

const dataSource = [
  {
    patientId: '1786',
    patientName: 'Ravanda Terry',
    visitId: '66570',
    claimId: '47791',
    visitDate: '01/03/2024',
    billedDate: '',
    cptCode: '99305',
    balance: '$210.00',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'primaryInsuranceReport',
    label: 'Primary Insurance Aging',
    status: 'active',
    link: 'primary-insurance-report',
  },
];

export const PrimaryInsuranceAgingSubReportPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="BCBS MEDICARE ADVANTAGE [Current]"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />
      {/* <TableSearch /> */}
      {dataSource?.length > 0 ? (
        <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
