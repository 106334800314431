import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { ClaimERAFilesHeader } from '../../../components/billing/claimERAFiles/ClaimERAFilesHeader';

import {
  getClaimEraFilesByReceivedDateRequest,
  getClaimEraFilesByReceivedDateSelector,
  clearFetchClaimEraFilesByReceivedDateResponse,
} from '../../../redux/slices/payments/claimEraFiles/getClaimEraFilesByReceivedDateSlice';

import { formatCurrency } from '../../../utils/commonFunctions';
import { validDateFormat } from '../../../utils/commonFunctions';
import { Spinner } from '../../../components/atoms/Spinner';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Era ID',
    dataIndex: 'eraIds',
    key: 'eraIds',
    render: (eraIds: string[] | null | undefined) => (
      <div>
        {eraIds && eraIds.length > 0 ? eraIds.map((id: string, index: number) => <div key={index}>{id}</div>) : <></>}
      </div>
    ),
  },
  {
    title: 'Files',
    dataIndex: 'files',
    key: 'files',
    render: (files: string[] | null | undefined) => (
      <div>
        {files && files.length > 0 ? (
          files.map((file: string, index: number) => (
            <div key={index}>
              <a href={`claim-era-files/download/${file}`} target="_blank" className="text-linkBlue">
                {file}
              </a>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    ),
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumbers',
    key: 'checkNumbers',
    render: (checkNumbers: string[] | null | undefined) => (
      <div>
        {checkNumbers && checkNumbers.length > 0 ? (
          checkNumbers.map((checkNumber: string, index: number) => <div key={index}>{checkNumber}</div>)
        ) : (
          <></>
        )}
      </div>
    ),
  },
  {
    title: 'Paid Amount',
    dataIndex: 'paidAmounts',
    key: 'paidAmounts',
    render: (paidAmounts: number[] | null | undefined) => (
      <div>
        {paidAmounts && paidAmounts.length > 0 ? (
          paidAmounts.map((amount: number, index: number) => <div key={index}>{formatCurrency(amount ?? 0)}</div>)
        ) : (
          <></>
        )}
      </div>
    ),
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    paidDate: '2024-05-10',
    eraIds: ['43253271', '43288391', '43317669'],
    files: [
      'wF7hqdYCZyuA0hqPGRKaRxMAU_43253271.pdf',
      'Yexs2yWJAS13yxHQAExWVTMX1_43288391.pdf',
      'WoVyZM3LeRCqcGTA5eoVQDE3g_43317669.pdf',
    ],
    checkNumbers: ['R7908809', '818822854', '898612830'],
    paidAmounts: [219.42, 487.57, 138.64],
    totalAmount: 845.63,
  },
];

export const months = [
  { id: '05-2023', name: 'May - 2023' },
  { id: '06-2023', name: 'June - 2023' },
  { id: '07-2023', name: 'July - 2023' },
  { id: '08-2023', name: 'August - 2023' },
  { id: '09-2023', name: 'September - 2023' },
  { id: '10-2023', name: 'October - 2023' },
  { id: '11-2023', name: 'November - 2023' },
  { id: '12-2023', name: 'December - 2023' },
  { id: '01-2024', name: 'January - 2024' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'claimEraFilesByRecievedDate',
    label: 'Claim ERA files by Received Date',
    status: 'active',
    link: 'claim-era-files-by-recieved-date',
  },
];

export const ERAFilesByReceivedDatePage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchClaimEraFilesByReceivedDateStatus,
    fetchClaimEraFilesByReceivedDateData,
    fetchClaimEraFilesByReceivedDateLoading,
  } = useAppSelector(getClaimEraFilesByReceivedDateSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  // useEffect(() => {
  //   if (filterObject) {
  //     fetchClaimEraFilesByReceivedDate();
  //   }
  // }, [filterObject]);

  useEffect(() => {
    if (fetchClaimEraFilesByReceivedDateStatus === 'SUCCESS') {
      setDataSource(fetchClaimEraFilesByReceivedDateData?.items);
      setCurrentPage(fetchClaimEraFilesByReceivedDateData?.currentPage);
      setTotalPages(fetchClaimEraFilesByReceivedDateData?.totalPages);
      dispatch(clearFetchClaimEraFilesByReceivedDateResponse());
    } else if (fetchClaimEraFilesByReceivedDateStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchClaimEraFilesByReceivedDateResponse());
    }
  }, [fetchClaimEraFilesByReceivedDateStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchClaimEraFilesByReceivedDate(page - 1);
  };

  const fetchClaimEraFilesByReceivedDate = async (pageNumber = currentPage) => {
    dispatch(getClaimEraFilesByReceivedDateRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.month || updatedData?.month === '') {
      delete updatedData.month;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Claim ERA files by Received Date"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />

      <ClaimERAFilesHeader totalERA={'3550'} months={months} onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {fetchClaimEraFilesByReceivedDateLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <TableContent enableActions={false} columns={columns} dataSource={dummyDataSource} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
