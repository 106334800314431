import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBillingSnapshotReportInterface,
  FetchBillingSnapshotReportSummaryInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getBillingSnapshotSummaryAPIRequest } from '../../../api/reports/billingSnapshotReport';

const initialState: FetchBillingSnapshotReportSummaryInitialInterface = {
  fetchBillingSnapshotSummaryLoading: false,
  fetchBillingSnapshotSummaryData: {},
  fetchBillingSnapshotSummaryStatus: 'IDLE',
  fetchBillingSnapshotSummaryError: null,
};

export const getBillingSnapshotSummaryRequest: any = createAsyncThunk(
  'patient/billing-snapshot-summary/get',
  async (reportData: FetchBillingSnapshotReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getBillingSnapshotSummaryAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBillingSnapshotSummarySlice = createSlice({
  name: 'fetchBillingSnapshotSummary',
  initialState,
  reducers: {
    clearFetchBillingSnapshotSummaryResponse: (state) => {
      state.fetchBillingSnapshotSummaryLoading = false;
      state.fetchBillingSnapshotSummaryData = {};
      state.fetchBillingSnapshotSummaryStatus = 'IDLE';
      state.fetchBillingSnapshotSummaryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillingSnapshotSummaryRequest.pending, (state, action) => {
        state.fetchBillingSnapshotSummaryLoading = true;
        state.fetchBillingSnapshotSummaryStatus = 'PENDING';
      })
      .addCase(getBillingSnapshotSummaryRequest.fulfilled, (state, action) => {
        state.fetchBillingSnapshotSummaryLoading = false;
        state.fetchBillingSnapshotSummaryStatus = 'SUCCESS';
        state.fetchBillingSnapshotSummaryData = action.payload;
      })
      .addCase(getBillingSnapshotSummaryRequest.rejected, (state, action) => {
        state.fetchBillingSnapshotSummaryLoading = false;
        state.fetchBillingSnapshotSummaryError = action.payload;
        state.fetchBillingSnapshotSummaryStatus = 'FAILED';
      });
  },
});

export const { clearFetchBillingSnapshotSummaryResponse } = getBillingSnapshotSummarySlice.actions;

export const getBillingSnapshotSummarySelector = (state: RootState) => state.GetBillingSnapshotSumamry;

export default getBillingSnapshotSummarySlice.reducer;
