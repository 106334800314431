import { useState } from 'react';

import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';

export const AddDescriptionModal = ({ addDescriptionModalOpen, setOpenAddDescriptionModal, onSubmit }: any) => {
  const [description, setDescription] = useState<string>('');
  const [isAddEnabled, setIsAddEnabled] = useState<boolean>(false);

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setDescription(value);

    if (value.trim() === '') {
      setIsAddEnabled(false);
    } else {
      setIsAddEnabled(true);
    }
  };

  return (
    <Modal show={addDescriptionModalOpen} onClose={() => setOpenAddDescriptionModal(false)}>
      <Modal.Header>Add Description</Modal.Header>
      <Modal.Body>
        <InputField
          enableLabel={false}
          placeholder="Enter Description"
          name="description"
          onChange={handleSearchInputChange}
          value={description ?? ''}
        />
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton
            type="button"
            label={'Close'}
            buttonType="secondary"
            onClick={() => {
              setDescription('');
              setOpenAddDescriptionModal(false);
            }}
          />
          <CommonButton
            type="button"
            label={'Add'}
            buttonType="primary"
            onClick={() => onSubmit(description)}
            disabled={!isAddEnabled}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
