import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { BilledAndUnbilledAdvanceSearch } from '../../components/tableSearch/BilledAndUnbilledAdvanceSearch';

import {
  getBilledAndUnbilledReportRequest,
  getBilledAndUnbilledReportSelector,
  clearBilledAndUnbilledReportResponse,
} from '../../redux/slices/reports/getBilledAndUnbilledReportSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '0 - 30 Days',
    dataIndex: 'days0To30',
    key: 'days0To30',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '31 - 60 Days',
    dataIndex: 'days31To60',
    key: 'days31To60',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '61 - 90 Days',
    dataIndex: 'days61To90',
    key: 'days61To90',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '91 - 120 Days',
    dataIndex: 'days91To120',
    key: 'days91To120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '>120 Days',
    dataIndex: 'daysAbove120',
    key: 'daysAbove120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    status: 'Primary',
    days0To30: 110262.0,
    days31To60: 68170.86,
    days61To90: 8588.73,
    days91To120: 5643.45,
    daysAbove120: 6113.1,
    balance: 198778.14,
  },
  {
    status: 'Secondary',
    days0To30: 1522.23,
    days31To60: 0.0,
    days61To90: 0.0,
    days91To120: 0.0,
    daysAbove120: 0.0,
    balance: 1522.23,
  },
  {
    status: 'Crossover Carrier',
    days0To30: 4254.48,
    days31To60: 405.69,
    days61To90: 528.97,
    days91To120: 473.49,
    daysAbove120: 177.79,
    balance: 5840.42,
  },
  {
    status: 'Patient',
    days0To30: 23898.9,
    days31To60: 23597.59,
    days61To90: 18599.24,
    days91To120: 18510.44,
    daysAbove120: 26090.13,
    balance: 110696.3,
  },
  {
    status: 'Visit - Pending Touched',
    days0To30: 884.0,
    days31To60: 510.0,
    days61To90: 102.0,
    days91To120: 0.0,
    daysAbove120: 0.0,
    balance: 1496.0,
  },
  {
    status: 'Visit - Cancelled',
    days0To30: 272.0,
    days31To60: 442.0,
    days61To90: 1020.0,
    days91To120: 170.0,
    daysAbove120: 408.0,
    balance: 2312.0,
  },
  {
    status: 'Visit - On Hold',
    days0To30: 12410.0,
    days31To60: 8194.0,
    days61To90: 11560.0,
    days91To120: 13124.0,
    daysAbove120: 8466.0,
    balance: 53754.0,
  },
  {
    status: 'Visit - Reviewed',
    days0To30: 52734.0,
    days31To60: 0.0,
    days61To90: 0.0,
    days91To120: 0.0,
    daysAbove120: 0.0,
    balance: 52734.0,
  },
  {
    status: 'Visit - Issues Pending',
    days0To30: 0.0,
    days31To60: 0.0,
    days61To90: 170.0,
    days91To120: 238.0,
    daysAbove120: 340.0,
    balance: 748.0,
  },
  {
    status: 'Visit - Alert',
    days0To30: 748.0,
    days31To60: 0.0,
    days61To90: 0.0,
    days91To120: 0.0,
    daysAbove120: 0.0,
    balance: 748.0,
  },
  {
    status: 'Total',
    days0To30: 206985.61,
    days31To60: 101320.14,
    days61To90: 40568.94,
    days91To120: 38159.38,
    daysAbove120: 41595.02,
    balance: 428629.09,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'billedAndUnbilledReport',
    label: 'Billed and Unbilled',
    status: 'active',
    link: 'billed-unbilled-report',
  },
];

export const BilledAndUnbilledReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchBilledAndUnbilledReportData, fetchBilledAndUnbilledReportStatus, fetchBilledAndUnbilledReportLoading } =
    useAppSelector(getBilledAndUnbilledReportSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchBilledAndUnbilledReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchBilledAndUnbilledReportStatus === 'SUCCESS') {
      setDataSource(fetchBilledAndUnbilledReportData);
      dispatch(clearBilledAndUnbilledReportResponse());
    } else if (fetchBilledAndUnbilledReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearBilledAndUnbilledReportResponse());
    }
  }, [fetchBilledAndUnbilledReportStatus]);

  const fetchBilledAndUnbilledReport = async () => {
    dispatch(getBilledAndUnbilledReportRequest({ filters: filterObject }));
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.facilityId = parseInt(updatedData?.facilityId, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Billed and Unbilled" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <BilledAndUnbilledAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchBilledAndUnbilledReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <div className="pb-7">
              <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
            </div>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
