import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientTransactionResponsibilityUpdateInterface,
  PatientTransactionResponsibilityUpdatePropsInterface,
} from '../../../interfaces/patientInterface';
import { editTransactionResponsibilityAPIRequest } from '../../../api/patient/patientTransaction';

const initialState: PatientTransactionResponsibilityUpdatePropsInterface = {
  editTransactionResponsibilityLoading: false,
  editTransactionResponsibilityData: {},
  editTransactionResponsibilityError: null,
  editTransactionResponsibilityStatus: 'IDLE',
};

export const editTransactionResponsibilityRequest: any = createAsyncThunk(
  'patient/transaction/updateResponsibility',
  async (transaction: PatientTransactionResponsibilityUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await editTransactionResponsibilityAPIRequest(transaction);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editTransactionResponsibilitySlice = createSlice({
  name: 'editTransactionResponsibility',
  initialState,
  reducers: {
    clearEditTransactionResponsibility: (state) => {
      state.editTransactionResponsibilityLoading = false;
      state.editTransactionResponsibilityStatus = 'IDLE';
      state.editTransactionResponsibilityData = {};
      state.editTransactionResponsibilityError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editTransactionResponsibilityRequest.pending, (state, action) => {
        state.editTransactionResponsibilityLoading = true;
        state.editTransactionResponsibilityStatus = 'PENDING';
      })
      .addCase(editTransactionResponsibilityRequest.fulfilled, (state, action) => {
        state.editTransactionResponsibilityData = action.payload;
        state.editTransactionResponsibilityLoading = false;
        state.editTransactionResponsibilityStatus = 'SUCCESS';
      })
      .addCase(editTransactionResponsibilityRequest.rejected, (state, action) => {
        state.editTransactionResponsibilityLoading = false;
        state.editTransactionResponsibilityStatus = 'FAILED';
        state.editTransactionResponsibilityError = action.payload;
      });
  },
});

export const { clearEditTransactionResponsibility } = editTransactionResponsibilitySlice.actions;

export const editTransactionResponsibilitySelector = (state: RootState) => state.EditTransactionResponsibility;

export default editTransactionResponsibilitySlice.reducer;
