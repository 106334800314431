import { financialClass, usStates } from '../../../constants/systemConstants';
import { FacilityFormInterface } from '../../../interfaces/facilitiesInterface';
import { InsuranceFormInterface } from '../../../interfaces/insuranceInterface';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';

export const InsuranceForm: React.FC<InsuranceFormInterface> = ({ onChange, formData, errorObject, mode }) => {
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Name"
          name="name"
          onChange={onChange}
          defaultValue={formData?.name ?? undefined}
          error={errorObject && errorObject?.name}
          errorMessage={errorObject && errorObject?.name}
          disabled={mode === 'VIEW'}
          required={true}
        />
        <InputField
          label="Plan Name"
          name="planName"
          onChange={onChange}
          defaultValue={formData?.planName ?? undefined}
          error={errorObject && errorObject?.planName}
          errorMessage={errorObject && errorObject?.planName}
          disabled={mode === 'VIEW'}
        />
        <SelectInput
          label="Financial Class"
          options={financialClass}
          enableDefaultPlaceholder={true}
          name="financialClass"
          onChange={onChange}
          defaultValue={formData?.financialClass ?? undefined}
          error={errorObject && errorObject?.financialClass}
          errorMessage={errorObject && errorObject?.financialClass}
          disabled={mode === 'VIEW'}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Address 1"
          name="address1"
          onChange={onChange}
          defaultValue={formData?.address1 ?? undefined}
          error={errorObject && errorObject?.address1}
          errorMessage={errorObject && errorObject?.address1}
          disabled={mode === 'VIEW'}
        />
        <InputField
          label="Address 2"
          name="address2"
          onChange={onChange}
          defaultValue={formData?.address2 ?? undefined}
          error={errorObject && errorObject?.address2}
          errorMessage={errorObject && errorObject?.address2}
          disabled={mode === 'VIEW'}
        />
        <InputField
          label="City"
          name="city"
          onChange={onChange}
          defaultValue={formData?.city ?? undefined}
          error={errorObject && errorObject?.city}
          errorMessage={errorObject && errorObject?.city}
          disabled={mode === 'VIEW'}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          defaultValue={formData?.state ?? undefined}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          disabled={mode === 'VIEW'}
        />
        <InputField
          label="Zip Code"
          name="zipCode"
          onChange={onChange}
          defaultValue={formData?.zipCode ?? undefined}
          error={errorObject && errorObject?.zipCode}
          errorMessage={errorObject && errorObject?.zipCode}
          disabled={mode === 'VIEW'}
          tooltip="Character length must be 5"
        />
        <SelectInput
          label="Doing Business In"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="businessState"
          onChange={onChange}
          defaultValue={formData?.businessState ?? undefined}
          error={errorObject && errorObject?.businessState}
          errorMessage={errorObject && errorObject?.businessState}
          disabled={mode === 'VIEW'}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Phone"
          name="phone"
          onChange={onChange}
          defaultValue={formData?.phone ?? undefined}
          error={errorObject && errorObject?.phone}
          errorMessage={errorObject && errorObject?.phone}
          disabled={mode === 'VIEW'}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Fax"
          name="fax"
          onChange={onChange}
          defaultValue={formData?.fax ?? undefined}
          error={errorObject && errorObject?.fax}
          errorMessage={errorObject && errorObject?.fax}
          disabled={mode === 'VIEW'}
          tooltip="10-digit fax number required"
        />
        <InputField
          label="Website"
          name="webSite"
          onChange={onChange}
          defaultValue={formData?.webSite ?? undefined}
          error={errorObject && errorObject?.webSite}
          errorMessage={errorObject && errorObject?.webSite}
          disabled={mode === 'VIEW'}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Claim Payer ID"
          name="claimPayerId"
          onChange={onChange}
          defaultValue={formData?.claimPayerId ?? undefined}
          error={errorObject && errorObject?.claimPayerId}
          errorMessage={errorObject && errorObject?.claimPayerId}
          disabled={mode === 'VIEW'}
          required={true}
        />
        <InputField
          label="Eligibility Payer ID"
          name="eligibilityPayerId"
          onChange={onChange}
          defaultValue={formData?.eligibilityPayerId ?? undefined}
          error={errorObject && errorObject?.eligibilityPayerId}
          errorMessage={errorObject && errorObject?.eligibilityPayerId}
          disabled={mode === 'VIEW'}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Contact"
          name="contact"
          onChange={onChange}
          defaultValue={formData?.contact ?? undefined}
          error={errorObject && errorObject?.contact}
          errorMessage={errorObject && errorObject?.contact}
          disabled={mode === 'VIEW'}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Provider Support No"
          name="providerSupportNo"
          onChange={onChange}
          defaultValue={formData?.providerSupportNo ?? undefined}
          error={errorObject && errorObject?.providerSupportNo}
          errorMessage={errorObject && errorObject?.providerSupportNo}
          disabled={mode === 'VIEW'}
        />
        <InputField
          label="Days to Process"
          name="daysToProcess"
          onChange={onChange}
          defaultValue={formData?.daysToProcess ?? undefined}
          error={errorObject && errorObject?.daysToProcess}
          errorMessage={errorObject && errorObject?.daysToProcess}
          disabled={mode === 'VIEW'}
        />
      </div>
    </>
  );
};
