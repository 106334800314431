import { useState } from 'react';

import { useAppSelector } from '../../hooks/storeHooks/hooks';

import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { SelectInput } from '../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilitiesSelector } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { usStates } from '../../constants/systemConstants';

export const BilledAndUnbilledAdvanceSearch = ({ onSubmit }: any) => {
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);

  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-4 gap-4 items-end">
            <SelectInput
              defaultPlaceholder="Select Provider / PA"
              name="providerId"
              options={systemProvidersProviderData}
              onChange={onChangeFilter}
              value={filterObject?.providerId ?? ''}
            />
            <SelectInput
              defaultPlaceholder="All Facility"
              name="facilityId"
              options={systemFacilityData}
              onChange={onChangeFilter}
              value={filterObject?.facilityId ?? ''}
            />
            <SelectInput
              defaultPlaceholder="All States"
              name="state"
              options={usStates}
              onChange={onChangeFilter}
              value={filterObject?.state ?? ''}
            />
            <div className="flex gap-2 justify-end">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({});
                  onSubmit({});
                }}
              />
              <PrimaryButton
                type="button"
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
