import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchBatchPaymentDetailsByIdInterface,
  FetchBatchPaymentByIdForApplyInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getBatchPaymentByIdForApplyAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: FetchBatchPaymentByIdForApplyInitialInterface = {
  fetchBatchPaymentByIdForApplyLoading: false,
  fetchBatchPaymentByIdForApplyData: {},
  fetchBatchPaymentByIdForApplyError: null,
  fetchBatchPaymentByIdForApplyStatus: 'IDLE',
};

export const getBatchPaymentByIdForApplyRequest: any = createAsyncThunk(
  'batch-payment/get-data-for-apply/id',
  async (searchParams: FetchBatchPaymentDetailsByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getBatchPaymentByIdForApplyAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBatchPaymentByIdForApplySlice = createSlice({
  name: 'fetchBatchPaymentDetailsByIdForApply',
  initialState,
  reducers: {
    clearFetchBatchPaymentDetailsByIdForApplyResponse: (state) => {
      state.fetchBatchPaymentByIdForApplyLoading = false;
      state.fetchBatchPaymentByIdForApplyData = {};
      state.fetchBatchPaymentByIdForApplyStatus = 'IDLE';
      state.fetchBatchPaymentByIdForApplyError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBatchPaymentByIdForApplyRequest.pending, (state, action) => {
        state.fetchBatchPaymentByIdForApplyLoading = true;
        state.fetchBatchPaymentByIdForApplyStatus = 'PENDING';
      })
      .addCase(getBatchPaymentByIdForApplyRequest.fulfilled, (state, action) => {
        state.fetchBatchPaymentByIdForApplyLoading = false;
        state.fetchBatchPaymentByIdForApplyStatus = 'SUCCESS';
        state.fetchBatchPaymentByIdForApplyData = action.payload;
      })
      .addCase(getBatchPaymentByIdForApplyRequest.rejected, (state, action) => {
        state.fetchBatchPaymentByIdForApplyLoading = false;
        state.fetchBatchPaymentByIdForApplyStatus = 'FAILED';
        state.fetchBatchPaymentByIdForApplyError = action.payload;
      });
  },
});

export const { clearFetchBatchPaymentDetailsByIdForApplyResponse } = getBatchPaymentByIdForApplySlice.actions;

export const getBatchPaymentByIdForApplySelector = (state: RootState) => state.GetBatchPaymentsDataForApply;

export default getBatchPaymentByIdForApplySlice.reducer;
