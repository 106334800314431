import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchBulkPaymentListInterface,
  FetchBulkPaymentListPropsInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getBulkPaymentsListAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: FetchBulkPaymentListPropsInterface = {
  fetchBulkPaymentListLoading: false,
  fetchBulkPaymentListData: {},
  fetchBulkPaymentListError: null,
  fetchBulkPaymentListStatus: 'IDLE',
};

export const getBulkPaymentsListRequest: any = createAsyncThunk(
  'bulk-payments/get',
  async (searchParams: FetchBulkPaymentListInterface, thunkAPI: any) => {
    try {
      console.log('Search Params', searchParams);
      //   const response: any = await getBulkPaymentsListAPIRequest(searchParams);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBulkPaymentsListSlice = createSlice({
  name: 'fetchBulkPaymentsList',
  initialState,
  reducers: {
    clearFetchBulkPaymentsListResponse: (state) => {
      state.fetchBulkPaymentListLoading = false;
      state.fetchBulkPaymentListData = {};
      state.fetchBulkPaymentListStatus = 'IDLE';
      state.fetchBulkPaymentListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBulkPaymentsListRequest.pending, (state, action) => {
        state.fetchBulkPaymentListLoading = true;
        state.fetchBulkPaymentListStatus = 'PENDING';
      })
      .addCase(getBulkPaymentsListRequest.fulfilled, (state, action) => {
        state.fetchBulkPaymentListLoading = false;
        state.fetchBulkPaymentListStatus = 'SUCCESS';
        state.fetchBulkPaymentListData = action.payload;
      })
      .addCase(getBulkPaymentsListRequest.rejected, (state, action) => {
        state.fetchBulkPaymentListLoading = false;
        state.fetchBulkPaymentListStatus = 'FAILED';
        state.fetchBulkPaymentListError = action.payload;
      });
  },
});

export const { clearFetchBulkPaymentsListResponse } = getBulkPaymentsListSlice.actions;

export const getBulkPaymentsListSelector = (state: RootState) => state.GetBulkPaymentsList;

export default getBulkPaymentsListSlice.reducer;
