import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { VisitForm } from './VisitForm';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { PatientVisitInterface } from '../../../../interfaces/patientInterface';
import { Spinner } from '../../../atoms/Spinner';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import {
  clearFetchPatientByIdResponse,
  getPatientsByIdSelector,
} from '../../../../redux/slices/patient/getPatientByIdSlice';
import {
  addPatientVisitRequest,
  addPatientVisitSelector,
  clearAddPatientVisitResponse,
} from '../../../../redux/slices/patient/addPatientVisitSlice';
import { deletePatientVisitByIdSelector } from '../../../../redux/slices/patient/deletePatientVisitSlice';
import {
  clearFetchPatientVisitByIdResponse,
  getPatientVisitByIdRequest,
  getPatientVisitByIdSelector,
} from '../../../../redux/slices/patient/getPatientVisitByIdSlice';
import {
  clearEditPatientVisitResponse,
  editPatientVisitRequest,
  editPatientVisitSelector,
} from '../../../../redux/slices/patient/editPatientVisitSlice';
import moment from 'moment';
import {
  createPatientCaseValidation,
  createPatientVisitEIValidation,
  createPatientVisitValidation,
} from '../../../../utils/patientValidation';
import {
  clearUpdatePatientVisitEIByIdResponse,
  updatePatientVisitEIByIdRequest,
  updatePatientVisitEIByIdSelector,
} from '../../../../redux/slices/patient/updateEligibilityInquirySlice';
import { EligibilityForm } from './EligibilityForm';
import { ViewEligibilityInquiry } from './ViewEligibilityInquiry';
import { convertDate, createViewEligibilityObject, validDateFormat } from '../../../../utils/commonFunctions';
import { getPatientByIdDemoRequest } from '../../../../redux/slices/patient/getPatientByIdDemoSlice';
import { RightSheetLarge } from '../../../rightSheet/RightSheetLarge';
import { VisitEditForm } from './VisitEditForm';
import { getCPTCodeListRequest, getCPTListSelector } from '../../../../redux/slices/patient/getCPTCodeListSlice';
import {
  clearFetchICDResponse,
  getICDCodeListRequest,
  getICDListSelector,
} from '../../../../redux/slices/patient/getICDCodeListSlice';
import { EditVisitStatus } from './EditVisitStatusModal';
import { NotesModal } from './NotesModal';
import {
  clearFetchVisitNoteHistoryResponse,
  getVisitNoteHistoryRequest,
  getVisitNoteHistorySelector,
} from '../../../../redux/slices/patient/getVisitNoteHistorySlice';
import {
  getVisitApplyNoteHistoryRequest,
  getVisitApplyNoteHistorySelector,
  clearFetchVisitApplyNoteHistoryResponse,
} from '../../../../redux/slices/patient/getVisitApplyNoteHistorySlice';
import { addVisitNoteSelector, clearAddVisitNoteResponse } from '../../../../redux/slices/patient/addVisitNoteSlice';
import {
  clearFetchVisitStatusHistoryResponse,
  getVisitStatusHistoryRequest,
  getVisitStatusHistorySelector,
} from '../../../../redux/slices/patient/getVisitStatusHistorySlice';
import { StatusHistoryModal } from './StatusHistoryModal';
import { clearFetchICDCodesResponse, getICDCodesSelector } from '../../../../redux/slices/system/getICDCodesSlice';
import { clearFetchCPTCodesResponse, getCPTCodesSelector } from '../../../../redux/slices/system/getCPTCodesSlice';
import { getModifiersSelector } from '../../../../redux/slices/system/getModifiersSlice';
import {
  clearFetchPatientInsuranceByTypeResponse,
  getPatientInsuranceByTypeRequest,
  getPatientInsuranceByTypeSelector,
} from '../../../../redux/slices/patient/getPatientInsuranceByTypeSlice';
import {
  clearFetchPatientElByVisitIdResponse,
  getPatientElByVisitIdRequest,
  getPatientElByVisitIdSelector,
} from '../../../../redux/slices/patient/getPatientEligibilityDataByVisitIdSlice';
import { deleteVisitCPTCodeSelector } from '../../../../redux/slices/patient/deleteVisitCPTCodeSlice';
import {
  getPatientCaseOptionsRequest,
  getPatientCaseOptionsSelector,
} from '../../../../redux/slices/patient/getPatientCaseOptionSlice';
import { CopyAlertModal } from './CopyAlertModal';
import { clearCopyVisitResponse, copyVisitSelector } from '../../../../redux/slices/patient/CopyVisitSlice';
import { claimStatus } from '../../../../constants/patientConstants';
import {
  clearFetchViewEIByIdResponse,
  getViewEIByIdRequest,
  getViewEIByIdSelector,
} from '../../../../redux/slices/patient/getPatientViewEIByIdSlice';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {
  clearCreateClaimsResponse,
  createClaimsRequest,
  createClaimsSelector,
} from '../../../../redux/slices/patient/createClaimsSlice';
import { ApplyNotesModal } from './ApplyNotesModal';
import { getSystemVisitStatusRequest } from '../../../../redux/slices/system/getSystemVisitStatusSlice';
import { getIPDataRequest } from '../../../../redux/slices/system/getIPDataSlice';
import {
  addVisitApplyNoteSelector,
  clearAddVisitApplyNoteResponse,
} from '../../../../redux/slices/patient/addVisitApplyNoteSlice';

const columns = [
  {
    title: 'Visit ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'DOS',
    dataIndex: 'dos',
    key: 'dos',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Case ID',
    dataIndex: 'caseId',
    key: 'caseId',
  },
  {
    title: 'Facility',
    dataIndex: 'facilityName',
    key: 'facilityName',
  },
  {
    title: 'Referring Provider',
    dataIndex: 'providerName',
    key: 'providerName',
  },
  {
    title: 'Insurance',
    dataIndex: 'insuranceName',
    key: 'insuranceName',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const dataSource = [
  {
    visitId: '62554',
    dos: '12/19/2023',
    caseId: '9888',
    facilityId: 'The Rivers of Grosse Pointe',
    attendingProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
  },
  {
    visitId: '62554',
    dos: '12/19/2023',
    caseId: '9888',
    facilityId: 'The Rivers of Grosse Pointe',
    attendingProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
  },
  {
    visitId: '62554',
    dos: '12/19/2023',
    caseId: '9888',
    facilityId: 'The Rivers of Grosse Pointe',
    attendingProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
  },
  {
    visitId: '62554',
    dos: '12/19/2023',
    caseId: '9888',
    facilityId: 'The Rivers of Grosse Pointe',
    attendingProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
  },
];

const dottedActionsArr = [
  {
    id: 'ELIGIBILITY_FORM',
    title: 'Eligibility Inquiry',
  },
  {
    id: 'VIEW_ELIGIBILITY',
    title: 'View Eligibility',
  },
  {
    id: 'EDIT_ELIGIBILITY',
    title: 'Edit Eligibility Status',
  },
];

const settingsActionArr = [
  {
    id: 'EDIT',
    title: 'Edit',
  },
  {
    id: 'VIEW',
    title: 'View',
  },
  {
    id: 'COPY',
    title: 'Copy',
  },
  {
    id: 'EDIT_STATUS',
    title: 'Edit Status',
  },
  {
    id: 'NOTES',
    title: 'Notes',
  },
];

export const Visit: React.FC<PatientVisitInterface> = ({
  selectedId,
  fetchPatientById,
  fetchDataLoading,
  addDataLoading,
  mode,
}) => {
  const dispatch = useAppDispatch();
  const { fetchPatientByIdData, fetchPatientByIdStatus } = useAppSelector(getPatientsByIdSelector);
  const { addPatientVisitStatus, addPatientVisitLoading, addPatientVisitError } =
    useAppSelector(addPatientVisitSelector);
  const { deletePatientVisitByIdLoading, deletePatientVisitByIdStatus, deletePatientVisitByIdError } =
    useAppSelector(deletePatientVisitByIdSelector);
  const { fetchPatientVisitByIdData, fetchPatientVisitByIdStatus, fetchPatientVisitByIdError } =
    useAppSelector(getPatientVisitByIdSelector);
  const { editPatientVisitLoading, editPatientVisitStatus, editPatientVisitError } =
    useAppSelector(editPatientVisitSelector);

  const {
    updatePatientVisitEIByIdData,
    updatePatientVisitEIByIdLoading,
    updatePatientVisitEIByIdError,
    updatePatientVisitEIByIdStatus,
  } = useAppSelector(updatePatientVisitEIByIdSelector);
  const { fetchViewEIByIdStatus, fetchViewEIByIdData, fetchViewEIByIdError } = useAppSelector(getViewEIByIdSelector);

  const { cptData, statusCPT, errorCPT } = useAppSelector(getCPTListSelector);
  const { icdData, statusICD, errorICD } = useAppSelector(getICDListSelector);
  const { noteHistoryData, statusNoteHistory, errorNoteHistory } = useAppSelector(getVisitNoteHistorySelector);
  const { applyNoteHistoryData, errorApplyNoteHistory, statusApplyNoteHistory } = useAppSelector(
    getVisitApplyNoteHistorySelector
  );
  const { addVisitNoteData, addVisitNoteStatus, addVisitNoteError } = useAppSelector(addVisitNoteSelector);
  const { historyStatus, statusHistoryData, errorStatusHistory } = useAppSelector(getVisitStatusHistorySelector);
  const { icdCodesData, icdCodesStatus, icdCodesError } = useAppSelector(getICDCodesSelector);
  const { cptCodesData, cptCodesStatus, cptCodesError } = useAppSelector(getCPTCodesSelector);
  const { modifiersData, modifiersStatus, modifiersError } = useAppSelector(getModifiersSelector);
  const { fetchPatientInsuranceByTypeStatus, fetchPatientInsuranceByTypeData, fetchPatientInsuranceByTypeError } =
    useAppSelector(getPatientInsuranceByTypeSelector);
  const { fetchPatientElByVisitIdData, fetchPatientElByVisitIdStatus, fetchPatientElByVisitIdError } =
    useAppSelector(getPatientElByVisitIdSelector);
  const { caseOptionPatientData } = useAppSelector(getPatientCaseOptionsSelector);

  const { copyVisitStatus, copyVisitError } = useAppSelector(copyVisitSelector);

  const { createClaimsStatus, createClaimsError } = useAppSelector(createClaimsSelector);

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [patientVisitFormData, setPatientVisitFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const [childMode, setChildMode] = useState('CREATE');
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [selectedVisitId, setSelectedVisitId] = useState<number>();
  const [errorObj, setErrorObj] = useState<any>({});

  const [visibleEligibilityForm, setVisibleEligibilityForm] = useState<boolean>(false);
  const [eligibilityFormEditData, setVisibleEligibilityFormData] = useState<any>({});
  const [visibleEInquiryView, setVisibleEInquiryView] = useState<boolean>(false);
  const [viewEIDetails, setViewEIDetails] = useState<any>({});

  const [cptDataSource, setCPTDataSource] = useState<any>([]);
  const [icdDataSource, setICDDataSource] = useState<any>([]);

  const [visibleEditStatus, setVisibleEditStatus] = useState<boolean>(false);

  const [visibleNotesModal, setVisibleNotesModal] = useState<boolean>(false);
  const [noteHistoryDataSource, setNoteHistoryDataSource] = useState<any>([]);
  const [applyNoteHistoryDataSource, setApplyNoteHistoryDataSource] = useState<any>([]);

  const [visibleStatusHistory, setVisibleStatusHistoty] = useState<boolean>(false);
  const [statusHistoryDataSource, setStatusHistoryDataSource] = useState<any>([]);

  const [icdCodesDataSource, setICDCodesDataSource] = useState<any>([]);
  const [cptCodesDataSource, setCPTCodesDataSource] = useState<any>([]);
  const [modifiersDataSource, setModifiersDataSource] = useState<any>([]);
  const [patientInsuranceList, setPatientInsuranceList] = useState<any>([]);

  const [selectedToCopy, setSelectedToCopy] = useState<any>({});
  const [openCopyAlert, setOpenCopyAlert] = useState<boolean>(false);

  const [dottedActionClicked, setDottedActionClicked] = useState<boolean>(false);
  const [settingActionClicked, setSettingActionClicled] = useState<boolean>(false);
  const [visibleApplyNotes, setVisibleApplyNotes] = useState<boolean>(false);

  const [selectedMenu, setSelectedMenu] = useState<string>('');

  // console.log('=== updatePatientVisitEIByIdError ===', updatePatientVisitEIByIdError);

  useEffect(() => {
    if (!caseOptionPatientData) {
      getCaseDropDown();
    }
    getVisitStatus();
    setVisibleModal(false);
    setVisibleEditModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setDottedActionClicked(false);
    setSettingActionClicled(false);
    setVisibleEligibilityForm(false);
    setPatientVisitFormData({});
    dispatch(getIPDataRequest());
  }, []);

  useEffect(() => {
    if (selectedId) {
      getPatientInsurance();
    }
  }, [selectedId]);

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      const convertedData = convertDataSet(fetchPatientByIdData);
      setDataSource(convertedData);
      dispatch(clearFetchPatientByIdResponse());
    }
  }, [fetchPatientByIdStatus]);

  useEffect(() => {
    if (fetchPatientVisitByIdStatus === 'SUCCESS') {
      setPatientVisitFormData(fetchPatientVisitByIdData);
      setVisibleEditModal(true);
    } else if (fetchPatientVisitByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchPatientVisitByIdError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchPatientVisitByIdResponse());
  }, [fetchPatientVisitByIdStatus]);

  useEffect(() => {
    if (addPatientVisitStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'visit');
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setPatientVisitFormData({});
        setErrorObj({});
        setChildMode('CREATE');
      }, 3000);
      dispatch(clearAddPatientVisitResponse());
    } else if (addPatientVisitStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addPatientVisitError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddPatientVisitResponse());
    }
  }, [addPatientVisitStatus]);

  useEffect(() => {
    if (deletePatientVisitByIdStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'visit');
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    } else if (deletePatientVisitByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: deletePatientVisitByIdError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [deletePatientVisitByIdStatus]);

  useEffect(() => {
    if (editPatientVisitStatus === 'SUCCESS') {
      // fetchPatientById(selectedId, 'visit');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      if (selectedToEdit) {
        getVisitById(selectedToEdit?.id);
        getICDCodeList(selectedToEdit?.id);
        getCPTCodeList(selectedToEdit?.id);
      }
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setErrorObj({});
        // setPatientVisitFormData({});
        // setChildMode('CREATE');
      }, 3000);
      dispatch(clearEditPatientVisitResponse());
    } else if (editPatientVisitStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: editPatientVisitError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientVisitResponse());
    }
  }, [editPatientVisitStatus]);

  useEffect(() => {
    if (updatePatientVisitEIByIdStatus === 'SUCCESS') {
      // console.log('=== updatePatientVisitEIByIdData ===', updatePatientVisitEIByIdData);

      if (updatePatientVisitEIByIdData?.eligible === true) {
        setVisibleEligibilityFormData({});
        let handledObject = createViewEligibilityObject(updatePatientVisitEIByIdData?.edi271DecodeData);
        setViewEIDetails(handledObject);
      } else if (updatePatientVisitEIByIdData?.eligible === false) {
        setAlertObj({
          color: 'failure',
          message: updatePatientVisitEIByIdData?.errorMessage ?? 'Something went wrong !',
          error: true,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          setAlertObj({
            color: '',
            message: '',
            error: false,
          });
          setVisibleAlert(false);
        }, 3000);
      }
    } else if (updatePatientVisitEIByIdStatus === 'FAILED') {
      // console.log('=== updatePatientVisitEIByIdError ===', updatePatientVisitEIByIdError);
      setAlertObj({
        color: 'failure',
        message: updatePatientVisitEIByIdError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    fetchPatientById(selectedId, 'visit');
  }, [updatePatientVisitEIByIdStatus]);

  useEffect(() => {
    // console.log('=== viewEIDetails ===', viewEIDetails, Object.keys(viewEIDetails)?.length > 0);

    if (Object.keys(viewEIDetails)?.length > 0) {
      setVisibleEligibilityForm(false);
      setVisibleEInquiryView(true);
    }
  }, [viewEIDetails]);

  useEffect(() => {
    if (statusCPT === 'SUCCESS') {
      setCPTDataSource(cptData);
    } else if (statusCPT === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorCPT?.message ?? 'Something went wrong in CPT Data Source!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [statusCPT]);

  useEffect(() => {
    if (statusICD === 'SUCCESS') {
      setICDDataSource(icdData);
    } else if (statusICD === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorICD?.message ?? 'Something went wrong in ICD Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchICDResponse());
  }, [statusICD]);

  useEffect(() => {
    if (statusNoteHistory === 'SUCCESS') {
      // console.log('=== noteHistoryData ===', noteHistoryData);
      setNoteHistoryDataSource(noteHistoryData);
      setVisibleNotesModal(true);
    } else if (statusNoteHistory === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorNoteHistory?.message ?? 'Something went wrong in Notes History Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitNoteHistoryResponse());
  }, [statusNoteHistory]);

  useEffect(() => {
    if (statusApplyNoteHistory === 'SUCCESS') {
      setApplyNoteHistoryDataSource(applyNoteHistoryData);
      setVisibleApplyNotes(true);
    } else if (statusApplyNoteHistory === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorApplyNoteHistory?.message ?? 'Something went wrong in Apply Notes History Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitApplyNoteHistoryResponse());
  }, [statusApplyNoteHistory]);

  useEffect(() => {
    if (addVisitNoteStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'visit');
      setVisibleNotesModal(false);
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addVisitNoteStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addVisitNoteError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearAddVisitNoteResponse());
  }, [addVisitNoteStatus]);

  useEffect(() => {
    if (historyStatus === 'SUCCESS') {
      setStatusHistoryDataSource(statusHistoryData);
      if (selectedMenu === 'STATUS_HISTORY') {
        setVisibleStatusHistoty(true);
      } else if (selectedMenu === 'EDIT_STATUS') {
        setVisibleEditStatus(true);
      }
    } else if (historyStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorStatusHistory?.message ?? 'Something went wrong in Status History Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitStatusHistoryResponse());
  }, [historyStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (icdCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setICDCodesDataSource(icdCodesData?.items);
    } else if (icdCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: icdCodesError?.message ?? 'Something went wrong in ICD Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchICDCodesResponse());
  }, [icdCodesStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (cptCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setCPTCodesDataSource(cptCodesData?.items);
    } else if (cptCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: cptCodesError?.message ?? 'Something went wrong in CPT Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchCPTCodesResponse());
  }, [cptCodesStatus]);

  useEffect(() => {
    if (modifiersStatus === 'SUCCESS') {
      setModifiersDataSource(modifiersData?.items);
    } else if (modifiersStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: modifiersError?.message ?? 'Something went wrong in Modifier Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [modifiersStatus]);

  useEffect(() => {
    if (fetchPatientInsuranceByTypeStatus === 'SUCCESS') {
      setPatientInsuranceList(fetchPatientInsuranceByTypeData);
    } else if (fetchPatientInsuranceByTypeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchPatientInsuranceByTypeError?.message ?? 'Something went wrong in Patient Insurance data!',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchPatientInsuranceByTypeResponse());
  }, [fetchPatientInsuranceByTypeStatus]);

  useEffect(() => {
    if (fetchPatientElByVisitIdStatus === 'SUCCESS') {
      // console.log('====fetchPatientElByVisitIdData ===', fetchPatientElByVisitIdData);
      setVisibleEligibilityFormData(fetchPatientElByVisitIdData);
      if (dottedActionClicked) {
        setVisibleEligibilityForm(true);
      }
    } else if (fetchPatientElByVisitIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchPatientElByVisitIdError?.message ?? 'Something went wrong in Patient Eligibility data!',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleEligibilityForm(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchPatientElByVisitIdResponse());
  }, [fetchPatientElByVisitIdStatus]);

  useEffect(() => {
    if (copyVisitStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'visit');
      setOpenCopyAlert(false);
      setAlertObj({
        color: 'success',
        message: 'Successfully Copied !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setSelectedToCopy({});
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (copyVisitStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: copyVisitError?.message ?? 'Something went wrong in Copying Visit Data!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setSelectedToCopy({});
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearCopyVisitResponse());
  }, [copyVisitStatus]);

  useEffect(() => {
    if (fetchViewEIByIdStatus === 'SUCCESS') {
      // console.log('=== view EI ===', fetchViewEIByIdData);
      let handledObject = createViewEligibilityObject(fetchViewEIByIdData);
      setViewEIDetails(handledObject);
    } else if (fetchViewEIByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchViewEIByIdError?.message ?? 'Something went wrong in Patient Eligibility data!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchViewEIByIdResponse());
  }, [fetchViewEIByIdStatus]);

  useEffect(() => {
    if (createClaimsStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'visit');
      setAlertObj({
        color: 'success',
        message: 'Successfully created the claim list !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (createClaimsStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: createClaimsError?.message ?? 'Something went wrong in Creating Claims!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearCreateClaimsResponse());
  }, [createClaimsStatus]);

  const getVisitStatus = () => {
    dispatch(getSystemVisitStatusRequest());
  };

  const getCaseDropDown = () => {
    dispatch(getPatientCaseOptionsRequest({ patientId: selectedId }));
  };

  const getPatientInsurance = () => {
    dispatch(getPatientInsuranceByTypeRequest({ patientId: selectedId, type: 'ALL' }));
  };

  const convertDataSet = (dataSet: []) => {
    let newArr = [];

    for (let index = 0; index < dataSet.length; index++) {
      const element: any = dataSet[index];
      // console.log('===element ===', element);

      let obj = {
        facilityName: element?.facilityName,
        insuranceName: element?.insuranceName,
        id: element?.id,
        providerName: element?.providerName,
        dos: convertDate(element?.dos),
        status: element?.status,
        caseId: element?.caseId,
        eligibilityId: element?.eligibilityId,
      };
      newArr[newArr.length] = obj;
    }
    return newArr;
  };

  const getVisitById = (visitId: number) => {
    dispatch(getPatientVisitByIdRequest({ patientId: selectedId, visitId: visitId }));
  };

  const getICDCodeList = (visitId: number) => {
    dispatch(getICDCodeListRequest({ visitId }));
  };

  const getCPTCodeList = (visitId: number) => {
    dispatch(getCPTCodeListRequest({ visitId }));
  };

  const getNoteHistory = (visitId: number) => {
    dispatch(getVisitNoteHistoryRequest({ visitId }));
  };

  const getApplyNoteHistory = () => {
    dispatch(getVisitApplyNoteHistoryRequest({ patientId: selectedId }));
  };

  const getStatusHistory = (visitId: number) => {
    dispatch(getVisitStatusHistoryRequest({ visitId }));
  };

  const getEligibilityDataByVisit = (visitId: number) => {
    dispatch(getPatientElByVisitIdRequest({ patientId: selectedId, visitId: visitId }));
  };

  const getViewEligibilityData = (visitId: number, eligibilityId: number) => {
    dispatch(getViewEIByIdRequest({ visitId: visitId, patientId: selectedId, eligibilityId: eligibilityId }));
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    setErrorObj({});
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setChildMode('EDIT');
    setSelectedToEdit(dataSet);
    getVisitById(dataSet?.id);
    getICDCodeList(dataSet?.id);
    getCPTCodeList(dataSet?.id);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedVisitId(dataSource[rowIndex]?.id);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedVisitId(dataSet?.id);
  };

  const resetAlert = () => {
    setVisibleAlert(false);
    setAlertObj({ color: '', message: '', error: false });
  };

  const populateAlert = (color: string = '', error: boolean = false, message: any = '', errorObject: any = {}) => {
    setErrorObj(errorObject);
    setAlertObj({
      color: color,
      message: error ? (
        <div>
          {Object.keys(errorObject)?.map((msg: any, index: any) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span style={{ marginLeft: '8px' }}>{errorObject[msg]}</span>
            </div>
          ))}
        </div>
      ) : (
        message
      ),
      error: error,
    });
    setVisibleAlert(true);
  };

  const onSubmit = () => {
    let obj = {
      patientId: selectedId,
      visitData: patientVisitFormData,
    };

    const validationDetails = createPatientVisitValidation(obj?.visitData);

    if (childMode === 'CREATE') {
      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        populateAlert('failure', true, '', validationDetails?.newErrors);
      } else {
        dispatch(addPatientVisitRequest(obj));
      }
    } else if (childMode === 'EDIT') {
      let obj = {
        patientId: selectedId,
        visitData: patientVisitFormData,
        visitId: selectedVisitId,
      };

      const validationDetails = createPatientVisitValidation(obj?.visitData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        populateAlert('failure', true, '', validationDetails?.newErrors);
      } else {
        dispatch(editPatientVisitRequest(obj));
      }
    }
  };

  const handleAlertClose = () => {
    resetAlert();
  };

  const handleAddNewModal = () => {
    resetAlert();
    setPatientVisitFormData({});
    setErrorObj({});
    setChildMode('CREATE');
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    fetchPatientById(selectedId, 'visit');
    resetAlert();
    setPatientVisitFormData({});
    setErrorObj({});
    setChildMode('CREATE');
    setVisibleModal(false);
    setVisibleEditModal(false);
  };

  const onChangeVisitDate = (date: Date) => {
    setPatientVisitFormData((prev: any) => ({ ...prev, visitDate: moment(date).format('YYYY-MM-DD') }));
  };

  const handleVisitFormChange = (e: any) => {
    setPatientVisitFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDottedActions = (id: any, rowIndex: any) => {
    setDottedActionClicked(true);
    setSettingActionClicled(false);
    const dataSet = dataSource[rowIndex];
    // console.log('=== dataSet ===', dataSet);

    if (id === 'ELIGIBILITY_FORM') {
      setSelectedVisitId(dataSet?.id);
      getEligibilityDataByVisit(dataSet?.id);
    } else if (id === 'VIEW_ELIGIBILITY') {
      setSelectedVisitId(dataSet?.id);
      getViewEligibilityData(dataSet?.id, dataSet?.eligibilityId);
    }
  };

  const handleSettingsAction = (id: any, rowIndex: any) => {
    // console.log('=== id - settings ===', id);
    setSettingActionClicled(true);
    setDottedActionClicked(false);
    setErrorObj({});
    const dataSet = dataSource[rowIndex];
    if (id === 'EDIT') {
      getEditViewData('EDIT', dataSet);
    } else if (id === 'EDIT_STATUS') {
      setSelectedMenu('EDIT_STATUS');
      setSelectedVisitId(dataSet?.id);
      setSelectedToEdit(dataSet);
      // getVisitById(dataSet?.id);
      getStatusHistory(dataSet?.id);
    } else if (id === 'NOTES') {
      setSelectedVisitId(dataSet?.id);
      getNoteHistory(dataSet?.id);
      setSelectedToEdit(dataSet);
    } else if (id === 'COPY') {
      getEligibilityDataByVisit(dataSet?.id);
      setSelectedVisitId(dataSet?.id);
      setSelectedToCopy(dataSet);
      setOpenCopyAlert(true);
    } else if (id === 'VIEW') {
      getEditViewData('VIEW', dataSet);
    }
  };

  const getEditViewData = (type: string, dataSet: any) => {
    setChildMode(type);
    setSelectedToEdit(dataSet);
    setSelectedVisitId(dataSet?.id);
    getVisitById(dataSet?.id);
    getICDCodeList(dataSet?.id);
    getCPTCodeList(dataSet?.id);
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onCloseEIModal = () => {
    setVisibleEligibilityFormData({});
    setVisibleEligibilityForm(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onSubmitEI = () => {
    const validationDetails = createPatientVisitEIValidation(eligibilityFormEditData);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setErrorObj(validationDetails?.newErrors);
      const errorMessages = Object.values(validationDetails.newErrors);
      setVisibleAlert(true);
      setAlertObj({
        color: 'failure',
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span style={{ marginLeft: '8px' }}>{msg}</span>
              </div>
            ))}
          </div>
        ),
        error: true,
      });
    } else {
      let enquiryData = {
        lastName: eligibilityFormEditData.lastName ?? '',
        firstName: eligibilityFormEditData.firstName ?? '',
        policy: eligibilityFormEditData.policy ?? '',
        group: eligibilityFormEditData.group ?? '',
        insuranceId: eligibilityFormEditData.insuranceId ? parseInt(eligibilityFormEditData.insuranceId) : '',
        payerId: eligibilityFormEditData.payerId ?? '',
        receiverFirstName: eligibilityFormEditData.receiverFirstName ?? '',
        receiverLastNameOrOrganizationName: eligibilityFormEditData.receiverLastNameOrOrganizationName ?? '',
        npiType: eligibilityFormEditData.npiType ?? '',
        npi: eligibilityFormEditData.npi ?? '',
        patientDoB: eligibilityFormEditData.patientDoB ?? '',
        dateOfService: eligibilityFormEditData.dateOfService ?? '',
      };
      let obj = {
        patientId: selectedId,
        visitId: selectedVisitId,
        enquiryData: enquiryData,
      };
      dispatch(updatePatientVisitEIByIdRequest(obj));
      // console.log('=== eligibilityFormEditData ===', eligibilityFormEditData);
    }
  };

  const handlePatientEIFormChange = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setVisibleEligibilityFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onEligibilityFormDates = (date: any, key: any) => {
    if (errorObj?.hasOwnProperty(key)) {
      delete errorObj[key];
    }
    setVisibleEligibilityFormData((prev: any) => ({ ...prev, [key]: moment(date).format('YYYY-MM-DD') }));
  };

  const onCloseEInquiry = (data: boolean) => {
    setVisibleEInquiryView(data);
    setViewEIDetails({});
    dispatch(clearUpdatePatientVisitEIByIdResponse());
  };

  const onCloseEditStatusModal = (data: boolean) => {
    setVisibleEditStatus(data);
  };

  const onCloseNotesModal = (data: boolean) => {
    setVisibleNotesModal(data);
  };

  const onViewHistory = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedMenu('STATUS_HISTORY');
    setSelectedVisitId(dataSource[rowIndex]?.id);
    getStatusHistory(dataSource[rowIndex]?.id);
  };

  const onCloseStatusHistoryModal = (data: boolean) => {
    setVisibleStatusHistoty(data);
  };

  // console.log('=== icdCodesDataSource ===', icdCodesDataSource);

  const onSaveEdit = () => {
    // console.log('===patientVisitFormData - onSubmit ==', patientVisitFormData);

    const formattedVisitDate = patientVisitFormData?.visitDate
      ? moment(patientVisitFormData?.visitDate).format('YYYY-MM-DD')
      : '';

    let dataObj = {
      patientVisitStatus: patientVisitFormData?.patientVisitStatus ?? patientVisitFormData?.status,
      caseId: patientVisitFormData?.caseId ? parseInt(patientVisitFormData?.caseId) : null,
      financialClass: patientVisitFormData?.visitFinancialClass ?? '',
      attendingProvider: patientVisitFormData?.attendingProviderName ?? patientVisitFormData?.attendingProvider?.name,
      primaryInsuranceId: patientVisitFormData?.primaryInsuranceId
        ? parseInt(patientVisitFormData?.primaryInsuranceId)
        : null,
      secondaryInsuranceId: patientVisitFormData?.secondaryInsuranceId
        ? parseInt(patientVisitFormData?.secondaryInsuranceId)
        : null,
      tertiaryInsuranceId: patientVisitFormData?.tertiaryInsuranceId
        ? parseInt(patientVisitFormData?.tertiaryInsuranceId)
        : null,
      noteToBiller: patientVisitFormData?.noteToBiller ?? '',
      facility: patientVisitFormData?.facilityName ?? patientVisitFormData?.facility?.name,
      assProvider: patientVisitFormData?.assProvider ?? '',
      refProvider: patientVisitFormData?.refProvider ?? '',
      visitDate: formattedVisitDate,
    };
    // console.log('===patientVisitFormData - dataObj ==', dataObj);

    const dispatchObj = {
      patientId: selectedId,
      visitId: selectedVisitId,
      visitData: dataObj,
    };

    dispatch(editPatientVisitRequest(dispatchObj));
  };

  const handleRemoveCPTCodeDataSource = () => {
    setCPTCodesDataSource([]);
  };

  const handleRemoveICDCodeDataSource = () => {
    setICDCodesDataSource([]);
  };

  const handleVisibleCopyAlert = (data: boolean) => {
    setSelectedToCopy({});
    setOpenCopyAlert(data);
  };

  const handleCreateClaim = () => {
    dispatch(createClaimsRequest({ patientId: selectedId }));
  };

  const handleVisibleApplyNotes = () => {
    setVisibleApplyNotes(true);
    getApplyNoteHistory();
  };

  const handleCloseApplyNotes = (close: boolean) => {
    setVisibleApplyNotes(close);
  };

  // console.log('=== settingsAction ===', settingActionClicked);
  // console.log('=== DottedAction ===', dottedActionClicked);

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert
          color={alertObj?.color}
          message={alertObj?.message}
          onClose={handleAlertClose}
          alertClassName="w-3/24"
        />
      )}
      <div className="flex w-96 ml-auto">
        <CommonButton
          label="Apply Notes"
          buttonType="primary"
          // icon={
          //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          //     <path
          //       fill-rule="evenodd"
          //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
          //       clip-rule="evenodd"
          //     />
          //   </svg>
          // }
          onClick={handleVisibleApplyNotes}
          style={{ marginRight: '3px' }}
        />
        <CommonButton
          label="Create Claims"
          buttonType="primary"
          // icon={
          //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          //     <path
          //       fill-rule="evenodd"
          //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
          //       clip-rule="evenodd"
          //     />
          //   </svg>
          // }
          onClick={handleCreateClaim}
          style={{ marginRight: '3px' }}
        />
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            // icon={
            //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            //     <path
            //       fill-rule="evenodd"
            //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
            //       clip-rule="evenodd"
            //     />
            //   </svg>
            // }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      {fetchDataLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns}
              dataSource={dataSource}
              enableHistory={true}
              onDelete={onDelete}
              onEdit={onEdit}
              onView={onView}
              onHandleHistory={onViewHistory}
              enableDots={true}
              enableSettings={true}
              dottedActions={dottedActionsArr}
              settingActions={settingsActionArr}
              onHandleDottedAction={handleDottedActions}
              onHandleSettingsAction={handleSettingsAction}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={mode === 'VIEW' ? false : true} mode={mode} />
          )}
        </>
      )}
      {visibleModal && (
        <RightSheet
          title="New Visit"
          submitButtonTitle="Submit"
          cancelButtonTitle="Cancel"
          children={
            <VisitForm
              onChange={handleVisitFormChange}
              errorObject={errorObj}
              onChangeVisitData={onChangeVisitDate}
              visitFormEditData={patientVisitFormData}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          loading={addPatientVisitLoading || editPatientVisitLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
        />
      )}
      {visibleEditModal && (
        <RightSheetLarge
          children={
            <VisitEditForm
              onChange={handleVisitFormChange}
              errorObject={errorObj}
              onChangeVisitData={onChangeVisitDate}
              visitFormEditData={patientVisitFormData}
              onSaveEdit={onSaveEdit}
              visitId={selectedVisitId}
              cptDataSource={cptDataSource}
              icdDataSource={icdDataSource}
              icdCodesDataSource={icdCodesDataSource}
              cptCodesDataSource={cptCodesDataSource}
              modifiersDataSource={modifiersDataSource}
              getICDCodeList={getICDCodeList}
              getCPTCodeList={getCPTCodeList}
              patientInsurance={patientInsuranceList}
              handleRemoveCPTCodeDataSource={handleRemoveCPTCodeDataSource}
              handleRemoveICDCodeDataSource={handleRemoveICDCodeDataSource}
              mode={childMode}
            />
          }
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          enableStepper={false}
          onClose={onCloseModal}
          enableFooterButtons={false}
        />
      )}
      {visibleEligibilityForm && (
        <RightSheet
          title="Eligibility Inquiry"
          submitButtonTitle="Submit"
          cancelButtonTitle="Cancel"
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          onClose={onCloseEIModal}
          loading={updatePatientVisitEIByIdLoading}
          onSubmit={onSubmitEI}
          alertOnClose={errorAlertOnClose}
          children={
            <EligibilityForm
              onChange={handlePatientEIFormChange}
              errorObject={errorObj}
              onEligibilityFormDates={onEligibilityFormDates}
              patientInsurance={patientInsuranceList}
              eligibilityFormEditData={eligibilityFormEditData}
            />
          }
        />
      )}
      {visibleEInquiryView ? (
        <ViewEligibilityInquiry
          openModal={visibleEInquiryView}
          setOpenModal={onCloseEInquiry}
          details={viewEIDetails}
        />
      ) : (
        <></>
      )}
      {visibleEditStatus && (
        <EditVisitStatus
          editVisitStatusOpen={visibleEditStatus}
          setOpenEditVisitStatus={onCloseEditStatusModal}
          visitId={selectedVisitId}
          getStatusHistory={getStatusHistory}
          statusHistoryDataSource={statusHistoryDataSource}
          fetchPatientById={fetchPatientById}
          patienId={selectedId}
        />
      )}
      {visibleNotesModal && (
        <NotesModal
          visibleEditModal={visibleNotesModal}
          visitId={selectedVisitId}
          setVisibleEditModal={onCloseNotesModal}
          noteHistoryDataSource={noteHistoryDataSource}
        />
      )}

      {visibleStatusHistory && (
        <StatusHistoryModal
          visibleHistoryModal={visibleStatusHistory}
          setVisibleHistoryModal={onCloseStatusHistoryModal}
          dataSource={statusHistoryDataSource}
          visitId={selectedVisitId}
        />
      )}

      {openCopyAlert && (
        <CopyAlertModal
          visitData={eligibilityFormEditData}
          openCopyAlert={openCopyAlert}
          setOpenCopyAlert={handleVisibleCopyAlert}
          patientId={selectedId}
          visitId={selectedVisitId}
        />
      )}

      {visibleApplyNotes && (
        <ApplyNotesModal
          applyBotesModalOpen={visibleApplyNotes}
          setOpenApplyNotesModal={handleCloseApplyNotes}
          applyNoteHistoryDataSource={applyNoteHistoryDataSource}
          selectedId={selectedId}
          setVisibleApplyNotes={handleCloseApplyNotes}
          fetchPatientById={fetchPatientById}
        />
      )}
    </>
  );
};
