import { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { CommonAlert } from '../../components/atoms/Alert';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Button, Tabs, TabsRef } from 'flowbite-react';
import { OpenBatches } from '../../components/billing/batchPayment/OpenBatches';
import { ClosedBatches } from '../../components/billing/batchPayment/ClosedBatches';
import { AllBatches } from '../../components/billing/batchPayment/AllBatches';
import { CreateBatchPayment } from '../../components/billing/batchPayment/CreateBatchPaymentForm';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { AdvancedBatchSearch } from '../../components/tableSearch/AdvanceBatchSearch';
import { BatchView } from '../../components/billing/batchPayment/BatchView';
import { RightSheetLarge } from '../../components/rightSheet/RightSheetLarge';
import { BulkPayments } from './bulkPayments/BulkPayments';
import { ConfirmModal } from '../../components/list/patients/visit/ConfirmModal';

import { IoIosInformationCircleOutline } from 'react-icons/io';

import {
  getBatchPaymentsRequest,
  getBatchPaymentsSelector,
  clearFetchBatchPaymentsResponse,
} from '../../redux/slices/payments/batchPayments/getBatchPaymentSlice';
import {
  addBatchPaymentRequest,
  addBatchPaymentSelector,
  clearAddBatchPaymentResponse,
} from '../../redux/slices/payments/batchPayments/addBatchPaymentSlice';
import {
  updateBatchPaymentSelector,
  clearUpdateBatchPaymentResponse,
  updatebatchPaymentRequest,
} from '../../redux/slices/payments/batchPayments/editBatchPaymentSlice';
import {
  getBatchPaymentByIdRequest,
  getBatchPaymentByIdSelector,
  clearFetchBatchPaymentByIdResponse,
} from '../../redux/slices/payments/batchPayments/getBatchPaymentByIdSlice';
import {
  getBatchPaymentDetailsByIdRequest,
  getBatchPaymentDetailsByIdSelector,
  clearFetchBatchPaymentDetailsByIdResponse,
} from '../../redux/slices/payments/batchPayments/getBatchPaymentDetailsByIdSlice';
import {
  closeBatchPaymentRequest,
  closeBatchPaymentSelector,
  clearCloseBatchPaymentResponse,
} from '../../redux/slices/payments/batchPayments/closeBatchPaymentSlice';
import {
  getBatchPaymentByIdForApplyRequest,
  getBatchPaymentByIdForApplySelector,
  clearFetchBatchPaymentDetailsByIdForApplyResponse,
} from '../../redux/slices/payments/batchPayments/getBatchPaymentByIdForApplySlice';

import { formatDate } from '../../utils/commonFunctions';
import { createBatchPaymentValidation } from '../../utils/paymentValidation';

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'billingBatchPayment', label: 'Batch Payment', status: 'active', link: 'billing-batch-payment' },
];

const PAGE_SIZE = 10;

const dummyDataSource = [
  {
    id: 375,
    batchType: 'LOCKBOX',
    checkDate: '2024-06-05',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
  {
    id: 2,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
  {
    id: 3,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425,817143432,817143432,817143425,817143432,817143432',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
  {
    id: 4,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
];

export const BatchPaymentPage = () => {
  const dispatch = useAppDispatch();

  const { addBatchPaymentStatus } = useAppSelector(addBatchPaymentSelector);
  const { fetchBatchPaymentsData, fetchBatchPaymentsStatus } = useAppSelector(getBatchPaymentsSelector);
  const { editBatchPaymentStatus } = useAppSelector(updateBatchPaymentSelector);
  const { fetchBatchPaymentByIdStatus, fetchBatchPaymentByIdData } = useAppSelector(getBatchPaymentByIdSelector);
  const { fetchBatchPaymentDetailsByIdStatus, fetchBatchPaymentDetailsByIdData } = useAppSelector(
    getBatchPaymentDetailsByIdSelector
  );
  const { closeBatchPaymentStatus } = useAppSelector(closeBatchPaymentSelector);
  const { fetchBatchPaymentByIdForApplyData, fetchBatchPaymentByIdForApplyStatus } = useAppSelector(
    getBatchPaymentByIdForApplySelector
  );

  const tabsRef = useRef<TabsRef>(null);

  const [activeTab, setActiveTab] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleViewModal, setVisibleViewModal] = useState(false);
  const [visibleBulkPaymentModal, setVisibleBulkPaymentModal] = useState(false);
  const [createFormData, setCreateFormData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [filterObject, setFilterObject] = useState<any>({ batchStatus: 'OPEN' });
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);
  const [openBatchesData, setOpenBatchesData] = useState<any>({});
  const [closedBatchesData, setClosedBatchesData] = useState<any>({});
  const [allBatchesData, setAllBatchesData] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [mode, setMode] = useState('CREATE');
  const [batchViewDataSource, setBatchViewDataSource] = useState<any[]>([]);
  const [batchTotalAmount, setBatchTotalAmount] = useState<number>(0);
  const [selectedBatchData, setSelectedBatchData] = useState<any>({});
  const [selectedId, setSelectedId] = useState<any>();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    setFetchDataLoading(true);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setOpenConfirmModal(false);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchBatchPayments();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchBatchPaymentsStatus === 'SUCCESS') {
      setFetchDataLoading(false);
      if (activeTab === 0) {
        // console.log('OPEN TRIGGERED');
        setOpenBatchesData(fetchBatchPaymentsData);
      } else if (activeTab === 1) {
        // console.log('CLOSED TRIGGERED');
        setClosedBatchesData(fetchBatchPaymentsData);
      } else if (activeTab === 2) {
        // console.log('ALL TRIGGERED');
        setAllBatchesData(fetchBatchPaymentsData);
      }
      dispatch(clearFetchBatchPaymentsResponse());
    } else if (fetchBatchPaymentsStatus === 'FAILED') {
      setFetchDataLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchBatchPaymentsResponse());
    }
  }, [fetchBatchPaymentsStatus]);

  useEffect(() => {
    if (addBatchPaymentStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      fetchBatchPayments();
      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setCreateFormData({});
      }, 3000);
      dispatch(clearAddBatchPaymentResponse());
    } else if (addBatchPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddBatchPaymentResponse());
    }
  }, [addBatchPaymentStatus]);

  useEffect(() => {
    if (fetchBatchPaymentByIdStatus === 'SUCCESS') {
      setCreateFormData(fetchBatchPaymentByIdData);
      setVisibleModal(true);
      dispatch(clearFetchBatchPaymentByIdResponse());
    } else if (fetchBatchPaymentByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearFetchBatchPaymentByIdResponse());
    }
  }, [fetchBatchPaymentByIdStatus]);

  useEffect(() => {
    if (editBatchPaymentStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      fetchBatchPayments();
      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setCreateFormData({});
      }, 3000);
      dispatch(clearUpdateBatchPaymentResponse());
    } else if (editBatchPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearUpdateBatchPaymentResponse());
    }
  }, [editBatchPaymentStatus]);

  useEffect(() => {
    if (fetchBatchPaymentDetailsByIdStatus === 'SUCCESS') {
      setBatchViewDataSource(fetchBatchPaymentDetailsByIdData?.items);
      setBatchTotalAmount(fetchBatchPaymentDetailsByIdData?.total);
      setVisibleViewModal(true);
    }
    if (fetchBatchPaymentDetailsByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchBatchPaymentDetailsByIdResponse());
  }, [fetchBatchPaymentDetailsByIdStatus]);

  useEffect(() => {
    if (closeBatchPaymentStatus === 'SUCCESS') {
      setOpenConfirmModal(false);
      setFetchDataLoading(true);
      fetchBatchPayments();
      setAlertObj({
        color: 'success',
        message: `Batch # ${selectedId} Closed Successfully !`,
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearCloseBatchPaymentResponse());
    } else if (closeBatchPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearCloseBatchPaymentResponse());
    }
  }, [closeBatchPaymentStatus]);

  useEffect(() => {
    if (fetchBatchPaymentByIdForApplyStatus === 'SUCCESS') {
      setSelectedBatchData(fetchBatchPaymentByIdForApplyData);
      setVisibleBulkPaymentModal(true);
      dispatch(clearFetchBatchPaymentDetailsByIdForApplyResponse());
    } else if (fetchBatchPaymentByIdForApplyStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching batch data!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearFetchBatchPaymentDetailsByIdForApplyResponse());
    }
  }, [fetchBatchPaymentByIdForApplyStatus]);

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const visibleCreateBatch = () => {
    setMode('CREATE');
    setVisibleModal(true);
  };

  const createErrorAlert = (validationDetails: any) => {
    const errorMessages = Object.values(validationDetails.newErrors);
    return {
      message: (
        <div>
          {errorMessages.map((msg: any, index) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span className="ml-2">{msg}</span>
            </div>
          ))}
        </div>
      ),
      color: 'failure',
      error: true,
    };
  };

  const convertBeforeSubmit = (formData: any) => {
    const obj = {
      ...formData,
      paymentAmount: Number(parseFloat(formData?.paymentAmount).toFixed(2)),
      checkDate: formatDate(formData?.checkDate),
      bankPostingDate: formatDate(formData?.bankPostingDate),
    };

    return obj;
  };

  const onSubmitCreateBatchPayment = () => {
    if (mode === 'CREATE') {
      const validationDetails = createBatchPaymentValidation(createFormData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setAlertObj(createErrorAlert(validationDetails));
        setVisibleAlert(true);
      } else {
        dispatch(addBatchPaymentRequest(convertBeforeSubmit(createFormData)));
      }
    } else if (mode === 'EDIT') {
      const obj = {
        paymentId: selectedId,
        paymentData: convertBeforeSubmit(createFormData),
      };

      const validationDetails = createBatchPaymentValidation(obj?.paymentData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setAlertObj(createErrorAlert(validationDetails));
        setVisibleAlert(true);
      } else {
        dispatch(updatebatchPaymentRequest(obj));
      }
    }
  };

  const onChangeCreateForm = (event: any) => {
    setCreateFormData((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onChangeCreateFormDate = (date: any, name: string) => {
    setCreateFormData((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  const handleTabChange = (tab: number) => {
    setActiveTab(tab);
    setFetchDataLoading(true);
    const newFilter = { batchStatus: tab === 0 ? 'OPEN' : tab === 1 ? 'CLOSED' : undefined };
    setCurrentPage(0);

    setClearFilters((prev) => !prev);
    setFilterObject(newFilter);
  };

  const fetchBatchPayments = async (pageNumber = currentPage) => {
    dispatch(getBatchPaymentsRequest({ page: pageNumber, size: PAGE_SIZE, filters: filterObject }));
  };

  const fetchBatchPaymentDetails = (id: number) => {
    // dispatch(getBatchPaymentDetailsByIdRequest({ paymentId: id }));
  };

  const fetchBatchPaymentById = (paymentId: number) => {
    dispatch(getBatchPaymentByIdRequest({ paymentId }));
  };

  const fetchBatchPaymentByIdForApply = (paymentId: number) => {
    dispatch(getBatchPaymentByIdForApplyRequest({ paymentId }));
  };

  const confirmCloseBatchPayment = () => {
    dispatch(
      closeBatchPaymentRequest({
        paymentId: selectedId,
      })
    );
  };

  const onEdit = (event: any, rowIndex: any, dataSource: any) => {
    event.preventDefault();
    setMode('EDIT');
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    fetchBatchPaymentById(dataSet?.id);
  };

  const onView = (event: any, rowIndex: any, dataSource: any) => {
    event.preventDefault();
    setMode('VIEW');
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    fetchBatchPaymentDetails(dataSet?.id);
    setVisibleViewModal(true);
  };

  const onApply = (event: any, rowIndex: any, dataSource: any) => {
    event.preventDefault();
    setMode('APPLY');
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    fetchBatchPaymentByIdForApply(dataSet?.id);
  };

  const onLock = (event: any, rowIndex: any, dataSource: any) => {
    event.preventDefault();
    setMode('LOCK');
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setOpenConfirmModal(true);
  };

  const onCloseBatchModal = (close: boolean) => {
    setVisibleViewModal(close);
    setBatchViewDataSource([]);
  };

  const onCloseModal = () => {
    setCreateFormData({});
    setVisibleModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setMode('CREATE');
  };

  const handleOnClose = () => {
    setVisibleBulkPaymentModal(false);
    setSelectedBatchData({});
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleSearch = (data: any) => {
    const batchStatus = activeTab === 0 ? 'OPEN' : activeTab === 1 ? 'CLOSED' : undefined;
    const updatedData = {
      ...data,
      batchStatus,
    };

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  // console.log('Filters', filterObject);
  // console.log('createFormData', createFormData);

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && !openConfirmModal && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic
        mainTitle="Batch Payment"
        enablePrimaryButton={true}
        breadCrumbArr={breadCrumbArr}
        onClick={visibleCreateBatch}
      />
      <AdvancedBatchSearch onSubmit={handleSearch} clearFilters={clearFilters} />
      <Tabs aria-label="Default tabs" ref={tabsRef} onActiveTabChange={(tab) => handleTabChange(tab)}>
        <Tabs.Item active title="Open Batches">
          <OpenBatches
            onEdit={(event: any, rowIndex: any) => onEdit(event, rowIndex, openBatchesData?.items)}
            onView={(event: any, rowIndex: any) => onView(event, rowIndex, openBatchesData?.items)}
            onApply={(event: any, rowIndex: any) => onApply(event, rowIndex, openBatchesData?.items)}
            onLock={(event: any, rowIndex: any) => onLock(event, rowIndex, openBatchesData?.items)}
            openBatchesData={openBatchesData}
            fetchBatchPayments={fetchBatchPayments}
            fetchDataLoading={fetchDataLoading}
          />
        </Tabs.Item>
        <Tabs.Item title="Closed Batches">
          <ClosedBatches
            onEdit={(event: any, rowIndex: any) => onEdit(event, rowIndex, closedBatchesData?.items)}
            onView={(event: any, rowIndex: any) => onView(event, rowIndex, closedBatchesData?.items)}
            onApply={(event: any, rowIndex: any) => onApply(event, rowIndex, closedBatchesData?.items)}
            onLock={(event: any, rowIndex: any) => onLock(event, rowIndex, closedBatchesData?.items)}
            closedBatchesData={closedBatchesData}
            fetchBatchPayments={fetchBatchPayments}
            fetchDataLoading={fetchDataLoading}
          />
        </Tabs.Item>
        <Tabs.Item title="All Batches">
          <AllBatches
            onEdit={(event: any, rowIndex: any) => onEdit(event, rowIndex, allBatchesData?.items)}
            onView={(event: any, rowIndex: any) => onView(event, rowIndex, allBatchesData?.items)}
            onApply={(event: any, rowIndex: any) => onApply(event, rowIndex, allBatchesData?.items)}
            onLock={(event: any, rowIndex: any) => onLock(event, rowIndex, allBatchesData?.items)}
            allBatchesData={allBatchesData}
            fetchBatchPayments={fetchBatchPayments}
            fetchDataLoading={fetchDataLoading}
          />
        </Tabs.Item>
      </Tabs>

      {visibleModal && (
        <RightSheet
          onClose={onCloseModal}
          title={mode === 'EDIT' ? 'Edit Batch Payment' : 'Create Batch Payment'}
          submitButtonTitle="Save"
          cancelButtonTitle="Cancel"
          children={
            <CreateBatchPayment
              onChange={onChangeCreateForm}
              onChangeDate={onChangeCreateFormDate}
              createFormData={createFormData}
            />
          }
          onSubmit={onSubmitCreateBatchPayment}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={handleAlertClose}
        />
      )}

      {visibleViewModal && (
        <BatchView
          openBatchView={visibleViewModal}
          setOpenBatchView={onCloseBatchModal}
          dataSource={batchViewDataSource}
          total={batchTotalAmount}
        />
      )}

      {visibleBulkPaymentModal && (
        <RightSheetLarge
          title="Bulk Payments"
          enableStepper={false}
          enableFooterButtons={false}
          onClose={handleOnClose}
          children={<BulkPayments batchData={selectedBatchData} />}
        />
      )}

      {openConfirmModal && (
        <ConfirmModal
          openConfirm={openConfirmModal}
          setOpenConfirm={onCloseConfirm}
          title="Close Batch Payment"
          content={`Are you sure you want to lock Batch # ${selectedId}?`}
          submitButtonTitle="Ok"
          cancelButtonTitle="Cancel"
          handleSubmit={confirmCloseBatchPayment}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}
    </div>
  );
};
