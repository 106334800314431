import { TableContent } from '../../table/TableContent';

const columns = [
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
  },
  {
    title: 'Insurance Name',
    dataIndex: 'insuranceName',
    key: 'insuranceName',
  },
  {
    title: 'Policy Number',
    dataIndex: 'policyNumber',
    key: 'policyNumber',
  },
  {
    title: 'Service Date',
    dataIndex: 'serviceDate',
    key: 'serviceDate',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
];

export const HistoryTable = ({ dataSource }: any) => {
  const handleOnView = () => {};
  return (
    <>
      <TableContent
        dataSource={dataSource}
        columns={columns}
        enableActions={true}
        enableView={true}
        onView={handleOnView}
      />
    </>
  );
};
