import { SelectInput } from '../atoms/SelectInput';
import { BankReconciliationReportInterface } from '../../interfaces/userInterface';
import { CommonButton } from '../atoms/CommonButton';

export const DashboardBankReconciliationReportHeader: React.FC<BankReconciliationReportInterface> = ({
  onChange,
  onSubmit,
  onExport,
  reportFilters,
  years,
  months,
}) => {
  return (
    <>
      <>
        <div className="grid md:grid-cols-5 gap-4 mt-6">
          <SelectInput
            label=""
            options={years}
            enableDefaultPlaceholder={false}
            name="year"
            onChange={onChange}
            defaultValue={reportFilters?.year}
          />
          <SelectInput
            label=""
            options={months}
            enableDefaultPlaceholder={false}
            name="month"
            onChange={onChange}
            defaultValue={reportFilters?.month}
          />

          <div className="grid md:grid-cols-2 gap-4 mt-auto mb-auto">
            <CommonButton
              label="Submit"
              buttonType="primary"
              // icon={
              //   <svg
              //     xmlns="http://www.w3.org/2000/svg"
              //     viewBox="0 0 24 24"
              //     fill="currentColor"
              //     className="w-6 h-6"
              //   >
              //     <path
              //       fill-rule="evenodd"
              //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
              //       clip-rule="evenodd"
              //     />
              //   </svg>
              // }
              onClick={onSubmit}
            />

            <CommonButton
              label="Export"
              buttonType="primary"
              // icon={
              //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              //     <path
              //       fill-rule="evenodd"
              //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
              //       clip-rule="evenodd"
              //     />
              //   </svg>
              // }
              onClick={onExport}
            />
          </div>
        </div>
      </>
    </>
  );
};
