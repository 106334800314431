import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ReimbursementReportAdvanceSearch } from '../../components/tableSearch/ReimbursementReportAdvanceSearch';

import {
  getReimbursementReportRequest,
  getReimbursementReportSelector,
  clearFetchReimbursementReportResponse,
} from '../../redux/slices/reports/getReimbursementReportSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Provider Type',
    dataIndex: 'providerType',
    key: 'providerType',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cpt',
    key: 'cpt',
  },
  {
    title: 'Reimbursement',
    dataIndex: 'reimbursement',
    key: 'reimbursement',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    provider: 'William James',
    providerType: 'MO',
    cpt: '99782',
    reimbursement: 65232.1,
  },
  {
    provider: 'John Kyle',
    providerType: 'CMP',
    cpt: '99781',
    reimbursement: 3232,
  },
  {
    provider: 'Serene Kate',
    providerType: 'KO',
    cpt: '99784',
    reimbursement: 12232333,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'reimbursementReport',
    label: 'Reimbursement Report',
    status: 'active',
    link: 'reimbursement-report',
  },
];

export const ReimbursementReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchReimbursementReportData, fetchReimbursementReportStatus, fetchReimbursementReportLoading } =
    useAppSelector(getReimbursementReportSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemProvidersProviderData?.length === 0) {
      fetchSystemProviders();
    }
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchReimbursementReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchReimbursementReportStatus === 'SUCCESS') {
      setDataSource(fetchReimbursementReportData?.items);
      setCurrentPage(fetchReimbursementReportData?.currentPage);
      setTotalPages(fetchReimbursementReportData?.totalPages);
      dispatch(clearFetchReimbursementReportResponse());
    } else if (fetchReimbursementReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchReimbursementReportResponse());
    }
  }, [fetchReimbursementReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchReimbursementReport(page - 1);
  };

  const fetchReimbursementReport = async (pageNumber = currentPage) => {
    dispatch(getReimbursementReportRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Reimbursement Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      <ReimbursementReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchReimbursementReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="flex justify-end mb-4">
                <div className="flex items-center">
                  <label htmlFor="search" className="mr-2 text-gray-600">
                    Search:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded px-2 py-1"
                    name="search"
                    title="Search"
                    disabled
                  />
                </div>
              </div>

              <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
