import { useState } from 'react';

import { useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { ExtendedDateRangePicker } from '../atoms/ExtendedDateRangePicker';
import { SelectInput } from '../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';

import { usStates } from '../../constants/systemConstants';

export const ReimbursementReportAdvanceSearch = ({ onSubmit }: any) => {
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [filterObject, setFilterObject] = useState<any>({ dateFrom: null, dateTo: null });

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDateRange = (dateRange: { startDate: Date; endDate: Date }) => {
    // console.log('date', dateRange);
    setFilterObject((prev: any) => ({
      ...prev,
      dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD'),
      dateTo: moment(dateRange.endDate).format('YYYY-MM-DD'),
    }));
  };

  //   console.log('filterObject', filterObject);

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-4 mb-4 gap-4 items-end">
            <SelectInput
              defaultPlaceholder="Select Provider"
              name="providerId"
              options={systemProvidersProviderData}
              onChange={onChangeFilter}
              value={filterObject?.providerId ?? ''}
            />
            <ExtendedDateRangePicker
              initialDateRange={[
                {
                  startDate: filterObject?.dateFrom ?? new Date(),
                  endDate: filterObject?.dateTo ?? new Date(),
                  key: 'selection',
                },
              ]}
              onChange={onChangeDateRange}
            />
            <SelectInput
              defaultPlaceholder="All States"
              name="state"
              options={usStates}
              onChange={onChangeFilter}
              value={filterObject?.state ?? ''}
            />
            <div className="flex gap-2 justify-start">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({
                    dateFrom: null,
                    dateTo: null,
                  });
                  onSubmit({});
                }}
              />
              <PrimaryButton
                type="button"
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
