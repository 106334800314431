import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBillingSnapshotReportInterface,
  FetchBillingSnapshotReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getBillingSnapshotReportAPIRequest } from '../../../api/reports/billingSnapshotReport';

const initialState: FetchBillingSnapshotReportInitialInterface = {
  fetchBillingSnapshotReportLoading: false,
  fetchBillingSnapshotReportData: {},
  fetchBillingSnapshotReportError: null,
  fetchBillingSnapshotReportStatus: 'IDLE',
};

export const getBillingSnapshotReportRequest: any = createAsyncThunk(
  'reports/billing-snapshot/get',
  async (reportData: FetchBillingSnapshotReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getBillingSnapshotReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBillingSnapshotReportSlice = createSlice({
  name: 'fetchBillingSnapshotReport',
  initialState,
  reducers: {
    clearBillingSnapshotReportResponse: (state) => {
      state.fetchBillingSnapshotReportLoading = false;
      state.fetchBillingSnapshotReportData = {};
      state.fetchBillingSnapshotReportStatus = 'IDLE';
      state.fetchBillingSnapshotReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillingSnapshotReportRequest.pending, (state, action) => {
        state.fetchBillingSnapshotReportLoading = true;
        state.fetchBillingSnapshotReportStatus = 'PENDING';
      })
      .addCase(getBillingSnapshotReportRequest.fulfilled, (state, action) => {
        state.fetchBillingSnapshotReportLoading = false;
        state.fetchBillingSnapshotReportStatus = 'SUCCESS';
        state.fetchBillingSnapshotReportData = action.payload;
      })
      .addCase(getBillingSnapshotReportRequest.rejected, (state, action) => {
        state.fetchBillingSnapshotReportLoading = false;
        state.fetchBillingSnapshotReportStatus = 'FAILED';
        state.fetchBillingSnapshotReportError = action.payload;
      });
  },
});

export const { clearBillingSnapshotReportResponse } = getBillingSnapshotReportSlice.actions;

export const getBillingSnapshotReportSelector = (state: RootState) => state.GetBillingSnapshotReport;

export default getBillingSnapshotReportSlice.reducer;
