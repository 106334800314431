export const insuranceType = [
  { id: 'PRIMARY', name: 'Primary' },
  { id: 'SECONDARY', name: 'Secondary' },
  { id: 'TERTIARY', name: 'Tertiary' },
  { id: 'QUATERNARY', name: 'Quaternary' },
  { id: 'QUINARY', name: 'Quinary' },
];

export const mediPlans = [
  { id: 'COMMERCIAL', name: 'Commercial' },
  { id: 'MEDICAID', name: 'Medicaid' },
  { id: 'MEDICAID_MCO', name: 'Medicaid MCO' },
  { id: 'MEDICARE', name: 'Medicare' },
  { id: 'MEDICARE_MCO', name: 'Medicare MCO' },
  { id: 'OTHER', name: 'Other' },
];

export const referringProvider = [
  { id: 1, name: 'AHMED ELGAMAL' },
  { id: 2, name: 'ADEGIMI CARRINGTON' },
];

export const insuranceOrder = [
  { id: 1, name: 'MI Medicare Plus Blue' },
  { id: 2, name: 'MI Medicare Part B' },
];

export const illnessIndicator = [
  { id: 'ILLNESS', name: 'ILLNESS' },
  { id: 'INJURY', name: 'INJURY' },
  { id: 'LMP', name: 'LMP' },
  // { id: 'A', name: 'A' },
];

export const accidentType = [
  { id: 'AUTO', name: 'Auto' },
  { id: 'EMPLOYMENT_RELATED', name: 'Employment Related ' },
  { id: 'OTHER', name: 'Other' },
];

export const usStates = [
  { id: 'AL', name: 'AL' },
  { id: 'AK', name: 'AK' },
  { id: 'AZ', name: 'AZ' },
  { id: 'AR', name: 'AR' },
  { id: 'CA', name: 'CA' },
  { id: 'CO', name: 'CO' },
  { id: 'CT', name: 'CT' },
  { id: 'DE', name: 'DE' },
  { id: 'FL', name: 'FL' },
  { id: 'GA', name: 'GA' },
  { id: 'HI', name: 'HI' },
  { id: 'ID', name: 'ID' },
  { id: 'IL', name: 'IL' },
  { id: 'IN', name: 'IN' },
  { id: 'IA', name: 'IA' },
  { id: 'KS', name: 'KS' },
  { id: 'KY', name: 'KY' },
  { id: 'LA', name: 'LA' },
  { id: 'ME', name: 'ME' },
  { id: 'MD', name: 'MD' },
  { id: 'MA', name: 'MA' },
  { id: 'MI', name: 'MI' },
  { id: 'MN', name: 'MN' },
  { id: 'MS', name: 'MS' },
  { id: 'MO', name: 'MO' },
  { id: 'MT', name: 'MT' },
  { id: 'NE', name: 'NE' },
  { id: 'NV', name: 'NV' },
  { id: 'NH', name: 'NH' },
  { id: 'NJ', name: 'NJ' },
  { id: 'NM', name: 'NM' },
  { id: 'NY', name: 'NY' },
  { id: 'NC', name: 'NC' },
  { id: 'ND', name: 'ND' },
  { id: 'OH', name: 'OH' },
  { id: 'OK', name: 'OK' },
  { id: 'OR', name: 'OR' },
  { id: 'PA', name: 'PA' },
  { id: 'RI', name: 'RI' },
  { id: 'SC', name: 'SC' },
  { id: 'SD', name: 'SD' },
  { id: 'TN', name: 'TN' },
  { id: 'TX', name: 'TX' },
  { id: 'UT', name: 'UT' },
  { id: 'VT', name: 'VT' },
  { id: 'VA', name: 'VA' },
  { id: 'WA', name: 'WA' },
  { id: 'WV', name: 'WV' },
  { id: 'WI', name: 'WI' },
  { id: 'WY', name: 'WY' },
];

export const addressType = [
  { id: 1, name: 'Office' },
  { id: 2, name: 'Home ' },
  { id: 3, name: 'Mailing' },
];

export const userTitleType = [
  { id: 'Mr', name: 'Mr' },
  { id: 'Mrs', name: 'Mrs' },
  { id: 'Miss', name: 'Miss' },
];

export const financialClass = [
  { id: 'CM', name: 'Commercial' },
  { id: 'GR', name: 'Group' },
  { id: 'MD', name: 'Medicaid' },
  { id: 'MO', name: 'Medicaid MCO' },
  { id: 'MC', name: 'Medicare' },
  { id: 'MM', name: 'Medicare MCO' },
  { id: 'TC', name: 'Tricare / Champus' },
  { id: 'WC', name: 'Workers Comp' },
  { id: 'VA', name: 'Veterants Administration' },
  { id: 'OT', name: 'Other' },
];

export const credentialingStatus = [
  { id: 1, name: 'Approved' },
  { id: 2, name: 'In-Process' },
  { id: 3, name: 'Pending' },
  { id: 4, name: 'Panel Closed' },
  { id: 5, name: 'Credentialling Not Applicable' },
  { id: 6, name: 'Provider Option Out From Credentialling' },
];

export const applicationStatus = [
  { id: 1, name: 'Yet to be submitted' },
  { id: 2, name: 'Application submitted' },
  { id: 3, name: 'Pending CAQH' },
  { id: 4, name: 'Pending PECOS' },
  { id: 5, name: 'Pending Signature' },
  { id: 6, name: 'Pending Documentation' },
  { id: 7, name: 'In Process' },
  { id: 8, name: 'Panel Closed' },
  { id: 9, name: 'Approved' },
];

export const status = [
  { id: 1, name: 'Active' },
  { id: 0, name: 'Inactive' },
];

export const carriers = [
  { id: 1, name: ' IL AETNA' },
  { id: 2, name: ' BCBS MEDICARE ADVANTAGE (66006)' },
  { id: 3, name: ' IL Country Life (13162)' },
  { id: 4, name: ' Blue Cross Community Options (MCDIL)' },
  { id: 5, name: ' IL Bankers Fidelity (61239)' },
  { id: 6, name: ' IL Medicaid' },
  { id: 7, name: ' IL Pekin Insurance (37086)' },
  { id: 8, name: ' IL QUARTZ (39180)' },
  { id: 9, name: ' IL National Guardian Life Insurance Company (87020)' },
  { id: 10, name: ' MI CIGNA' },
];

export const basicCondition = [
  { id: 'Yes', name: 'Yes' },
  { id: 'No', name: 'No' },
];
