import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchDoctorFinancialReportInterface,
  FetchDoctorFinancialReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getDoctorFinancialReportAPIRequest } from '../../../api/reports/doctorFinancialReport';

const initialState: FetchDoctorFinancialReportInitialInterface = {
  fetchDoctorFinancialReportLoading: false,
  fetchDoctorFinancialReportData: {},
  fetchDoctorFinancialReportError: null,
  fetchDoctorFinancialReportStatus: 'IDLE',
};

export const getDoctorFinancialReportRequest: any = createAsyncThunk(
  'reports/doctor-financial/get',
  async (reportData: FetchDoctorFinancialReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getDoctorFinancialReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getDoctorFinancialReportSlice = createSlice({
  name: 'fetchDoctorFinancialReport',
  initialState,
  reducers: {
    clearDoctorFinancialReportResponse: (state) => {
      state.fetchDoctorFinancialReportLoading = false;
      state.fetchDoctorFinancialReportData = {};
      state.fetchDoctorFinancialReportStatus = 'IDLE';
      state.fetchDoctorFinancialReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDoctorFinancialReportRequest.pending, (state, action) => {
        state.fetchDoctorFinancialReportLoading = true;
        state.fetchDoctorFinancialReportStatus = 'PENDING';
      })
      .addCase(getDoctorFinancialReportRequest.fulfilled, (state, action) => {
        state.fetchDoctorFinancialReportLoading = false;
        state.fetchDoctorFinancialReportStatus = 'SUCCESS';
        state.fetchDoctorFinancialReportData = action.payload;
      })
      .addCase(getDoctorFinancialReportRequest.rejected, (state, action) => {
        state.fetchDoctorFinancialReportLoading = false;
        state.fetchDoctorFinancialReportStatus = 'FAILED';
        state.fetchDoctorFinancialReportError = action.payload;
      });
  },
});

export const { clearDoctorFinancialReportResponse } = getDoctorFinancialReportSlice.actions;

export const getDoctorFinancialReportSelector = (state: RootState) => state.GetDoctorFinancialReport;

export default getDoctorFinancialReportSlice.reducer;
