import {
  PatientAddressAddInterface,
  PatientAddressGetByIdInterface,
  PatientAddressUpdateInterface,
} from '../../interfaces/patientInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addPatientAddressAPIRequest = async (patientData: PatientAddressAddInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientData?.patientId}/address`,
    'post',
    {
      data: { ...patientData?.addressData },
    }
  );
};

export const updatePAtientAddressAPIRequest = async (addressData: PatientAddressUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${addressData?.patientId}/address/${addressData?.addressId}`,
    'put',
    {
      data: { ...addressData?.addressData },
    }
  );
};

export const getPatientAddressByIdAPIRequest = async (addressData: PatientAddressGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${addressData?.patientId}/address/${addressData?.addressId}`,
    'get',
    {}
  );
};

export const deletePatientAddressByIdAPIRequest = async (addressData: PatientAddressGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${addressData?.patientId}/address/${addressData?.addressId}`,
    'delete',
    {}
  );
};
