import { useEffect, useState } from 'react';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';
import { Pagination } from '../../../pagination/Pagination';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { Spinner } from '../../../atoms/Spinner';

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { getInsuranceByIdSelector } from '../../../../redux/slices/insurance/getInsurancebyIdSlice';

import moment from 'moment';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
  },
];

const dummyDataSource = [
  {
    name: 'Fairmont Care',
    address: '5061 N Pulaski Rd',
    city: 'Chicago',
    state: 'IL',
    zip: '60630',
  },
  {
    name: 'Oak Brook Care',
    address: '2013 Midwest Road',
    city: 'Oak Brook',
    state: 'IL',
    zip: '60523',
  },
  {
    name: 'Symphony of Bronzeville Park',
    address: '3400 S Indiana Ave',
    city: 'Chicago',
    state: 'IL',
    zip: '60616',
  },
  {
    name: 'Community Care Center Nursing & Rehab',
    address: '4314 S Wabash Ave',
    city: 'Chicago',
    state: 'IL',
    zip: '60653',
  },
];

export const InsuranceFacilities = ({ mode, selectedInsuranceId, fetchInsuranceById, fetchDataLoading }: any) => {
  const dispatch = useAppDispatch();

  const { fetchInsuranceByIdData, fetchInsuranceByIdStatus } = useAppSelector(getInsuranceByIdSelector);

  const [dataSource, setDataSource] = useState<any>([]);
  const [fetchLoading, setFetchLoading] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    setFetchLoading(fetchDataLoading);
  }, [fetchDataLoading]);

  useEffect(() => {
    if (fetchInsuranceByIdStatus === 'SUCCESS') {
      setDataSource(fetchInsuranceByIdData);
    }
  }, [fetchInsuranceByIdStatus, selectedInsuranceId]);

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  return (
    <>
      {fetchLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent columns={columns} dataSource={dataSource} />
          ) : (
            <EmptyContent enableCreateButton={false} mode="VIEW" />
          )}
        </>
      )}
    </>
  );
};
