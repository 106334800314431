import {
  validSSNLength,
  validTaxonomyLength,
  validZipCodeLength,
  validateEmail,
  validatePhoneNumber,
} from './commonFunctions';

export const createProviderAddressValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.address1) {
    newErrors.address1 = 'Address 1 is required';
    isValid = false;
  }

  if (!formData?.city) {
    newErrors.city = 'City is required';
    isValid = false;
  }

  if (!formData?.state) {
    newErrors.state = 'State is required';
    isValid = false;
  }

  if (!formData?.zipCode || formData?.zipCode.toString().length !== validZipCodeLength) {
    newErrors.zipCode = 'Valid zip code required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const createProviderValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.firstName?.trim()) {
    newErrors.firstName = 'First name is required';
    isValid = false;
  }

  if (!formData?.lastName?.trim()) {
    newErrors.lastName = 'Last name is required';
    isValid = false;
  }

  // if (!formData?.npi || formData?.npi?.length !== validNPILength) {
  //   newErrors.npi = 'Valid NPI is required';
  //   isValid = false;
  // }

  // if (formData?.ssn && formData?.ssn?.length !== validSSNLength) {
  //   newErrors.ssn = 'Enter a valid SSN';
  // }

  if (!formData?.taxonomy || formData?.taxonomy?.length !== validTaxonomyLength) {
    newErrors.taxonomy = 'Valid Taxonomy is required';
  }

  if (formData.workEmail) {
    if (!validateEmail(formData?.workEmail)) {
      newErrors.workEmail = 'Enter a valid Work Email';
      isValid = false;
    }
  }

  if (formData.personalEmail) {
    if (!validateEmail(formData?.personalEmail)) {
      newErrors.personalEmail = 'Enter a valid Personal Email';
      isValid = false;
    }
  }

  if (formData?.mobilePhone) {
    if (!validatePhoneNumber(formData?.mobilePhone)) {
      newErrors.mobilePhone = 'Enter a valid mobile phone number';
      isValid = false;
    }
  }

  if (formData?.homePhone) {
    if (!validatePhoneNumber(formData?.homePhone)) {
      newErrors.homePhone = 'Enter a valid home phone number';
      isValid = false;
    }
  }

  return { newErrors, isValid };
};

export const createProviderProfessionValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.npi) {
    newErrors.npi = 'NPI Number is required';
    isValid = false;
  }
  if (!formData?.deaNo) {
    newErrors.deaNo = 'DEA Number is required';
    isValid = false;
  }
  if (!formData?.effectiveDate) {
    newErrors.effectiveDate = 'DEA Number Effective Date is required';
    isValid = false;
  }

  if (!formData?.pecosUserId) {
    newErrors.pecosUserId = 'PECOS UserId is required';
    isValid = false;
  }

  if (!formData?.caqh) {
    newErrors.caqh = 'CAQH Number is required';
    isValid = false;
  }

  if (!formData?.caqhUserId) {
    newErrors.caqhUserId = 'CAQH UserId is required';
    isValid = false;
  }

  if (!formData?.caqhPassword) {
    newErrors.caqhPassword = 'CAQH Password is required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const createProviderLicenseValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.licenseNum) {
    newErrors.licenseNum = 'License Number is required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const createProviderCarriersValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.carrierName) {
    newErrors.carrierName = 'Carrier Name is required';
    isValid = false;
  }

  // if (!formData?.initiatedDate) {
  //   newErrors.initiatedDate = 'Initiated Date is required';
  //   isValid = false;
  // } else if (isNaN(Date.parse(formData.initiatedDate))) {
  //   newErrors.initiatedDate = 'Initiated Date must be a valid date';
  //   isValid = false;
  // }

  if (!formData?.initiatedDate) {
    newErrors.initiatedDate = 'Initiated Date is required';
    isValid = false;
  }

  if (!formData?.effectiveDate) {
    newErrors.effectiveDate = 'Effective Date is required';
    isValid = false;
  }

  if (!formData?.expirationDate) {
    newErrors.expirationDate = 'Expiration Date is required';
    isValid = false;
  }

  // if (!formData?.effectiveDate) {
  //   newErrors.effectiveDate = 'Effective Date is required';
  //   isValid = false;
  // } else if (isNaN(Date.parse(formData.effectiveDate))) {
  //   newErrors.effectiveDate = 'Effective Date must be a valid date';
  //   isValid = false;
  // }

  // if (!formData?.expirationDate) {
  //   newErrors.expirationDate = 'Expiration Date is required';
  //   isValid = false;
  // } else if (isNaN(Date.parse(formData.expirationDate))) {
  //   newErrors.expirationDate = 'Expiration Date must be a valid date';
  //   isValid = false;
  // }

  if (!formData?.status) {
    newErrors.status = 'Status is required';
    isValid = false;
  }

  return { newErrors, isValid };
};
