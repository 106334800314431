import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  ProcedureProductivityReportFetchInterface,
  ProcedureProductivityReportFetchPropsInterface,
} from '../../../../interfaces/managementInterfaces';
import { getProcedureProductivityReportAPIRequest } from '../../../../api/management/procedureProductivityReport';

const initialState: ProcedureProductivityReportFetchPropsInterface = {
  fetchProcedureProductivityReportLoading: false,
  fetchProcedureProductivityReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchProcedureProductivityReportError: null,
  fetchProcedureProductivityReportStatus: 'IDLE',
};

export const getProcedureProductivityReportRequest: any = createAsyncThunk(
  'management/procedureProductivityReport/get',
  async (reportData: ProcedureProductivityReportFetchInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getProcedureProductivityReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getProcedureProductivityReportSlice = createSlice({
  name: 'fetchProcedureProductivityReport',
  initialState,
  reducers: {
    clearFetchProcedureProductivityReportResponse: (state) => {
      state.fetchProcedureProductivityReportLoading = false;
      state.fetchProcedureProductivityReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchProcedureProductivityReportStatus = 'IDLE';
      state.fetchProcedureProductivityReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcedureProductivityReportRequest.pending, (state, action) => {
        state.fetchProcedureProductivityReportLoading = true;
        state.fetchProcedureProductivityReportStatus = 'PENDING';
      })
      .addCase(getProcedureProductivityReportRequest.fulfilled, (state, action) => {
        state.fetchProcedureProductivityReportLoading = false;
        state.fetchProcedureProductivityReportStatus = 'SUCCESS';
        state.fetchProcedureProductivityReportData = action.payload;
      })
      .addCase(getProcedureProductivityReportRequest.rejected, (state, action) => {
        state.fetchProcedureProductivityReportLoading = false;
        state.fetchProcedureProductivityReportStatus = 'FAILED';
        state.fetchProcedureProductivityReportError = action.payload;
      });
  },
});

export const { clearFetchProcedureProductivityReportResponse } = getProcedureProductivityReportSlice.actions;

export const getProcedureProductivityReportSelector = (state: RootState) => state.GetProcedureProductivityReport;

export default getProcedureProductivityReportSlice.reducer;
