import { Button, Modal } from 'flowbite-react';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';

export const ConfirmModal = ({
  openConfirm,
  setOpenConfirm,
  title,
  content,
  handleSubmit,
  submitButtonTitle,
  cancelButtonTitle,
  handleAlertClose,
  visibleAlert,
  alertObj,
  alertClassName = 'w/12',
}: any) => {
  return (
    <Modal show={openConfirm} onClose={() => setOpenConfirm(false)}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <div>
          {visibleAlert && !alertObj?.error && (
            <CommonAlert
              color={alertObj?.color}
              message={alertObj?.message}
              onClose={handleAlertClose}
              alertClassName={alertClassName}
            />
          )}
        </div>
        <div>{content}</div>
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton
            type="button"
            label={cancelButtonTitle}
            buttonType="secondary"
            onClick={() => setOpenConfirm(false)}
          />
          <CommonButton type="button" label={submitButtonTitle} buttonType="primary" onClick={handleSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
