import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchCarrierListInitialInterface,
  FetchReasonListInitialInterface,
} from '../../../interfaces/systemInterfaces';
import { getCarrierListAPIRequest, getReasonListAPIRequest } from '../../../api/system/patient';

const initialState: FetchCarrierListInitialInterface = {
  carrierListLoading: false,
  carrierListData: [],
  carrierListError: null,
  carrierListStatus: 'IDLE',
};

export const getCarrierListRequest: any = createAsyncThunk('carrier/get', async (thunkAPI: any) => {
  try {
    const response: any = await getCarrierListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getCarrierListSlice = createSlice({
  name: 'fetchCarriers',
  initialState,
  reducers: {
    clearFetchCarrierListResponse: (state) => {
      state.carrierListLoading = false;
      state.carrierListStatus = 'IDLE';
      state.carrierListData = [];
      state.carrierListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCarrierListRequest.pending, (state, action) => {
        state.carrierListLoading = true;
        state.carrierListStatus = 'PENDING';
      })
      .addCase(getCarrierListRequest.fulfilled, (state, action) => {
        state.carrierListLoading = false;
        state.carrierListData = action.payload;
        state.carrierListStatus = 'SUCCESS';
      })
      .addCase(getCarrierListRequest.rejected, (state, action) => {
        state.carrierListLoading = false;
        state.carrierListError = action.payload;
        state.carrierListStatus = 'FAILED';
      });
  },
});

export const { clearFetchCarrierListResponse } = getCarrierListSlice.actions;

export const getCarrierListSelector = (state: RootState) => state.GetCarrierList;

export default getCarrierListSlice.reducer;
