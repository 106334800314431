import { useEffect, useState } from 'react';

import { BatchTable } from './BatchTable';
import { Spinner } from '../../atoms/Spinner';

const dummyDataSource = [
  {
    id: 375,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
  {
    id: 2,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
  {
    id: 3,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
  {
    id: 4,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: 6579.06,
    unappliedAmount: 6430.75,
  },
];

const PAGE_SIZE = 10;

export const ClosedBatches = ({
  closedBatchesData,
  onEdit,
  onView,
  onApply,
  onLock,
  fetchBatchPayments,
  fetchDataLoading,
}: any) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    setDataSource(closedBatchesData?.items);
    setCurrentPage(closedBatchesData?.currentPage);
    setTotalPages(closedBatchesData?.totalPages);
  }, [closedBatchesData]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
    fetchBatchPayments(page - 1);
  };

  return (
    <>
      {fetchDataLoading ? (
        <Spinner />
      ) : (
        <BatchTable
          dataSource={dataSource}
          onView={onView}
          onEdit={onEdit}
          onApply={onApply}
          onLock={onLock}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};
