import { authenticatedRequest } from '../../utils/commonAxios';

export const getReasonListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/reason-list`, 'get', {});
};

export const getARCategoryListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/ar-category-list`, 'get', {});
};

export const getCategoryListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/category-list`, 'get', {});
};

export const getClaimStatusListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/status-list`, 'get', {});
};

export const getCarrierListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/insurance/list`, 'get', {});
};

export const getCarrierTypeListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/insurance/type-list`, 'get', {});
};

export const getVisitReasonListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/visit/reason-list`, 'get', {});
};
