import { useState } from 'react';

import { ClaimERAFilesInterface } from '../../../interfaces/userInterface';
import { Badge } from '../../atoms/Badge';
import { CommonButton } from '../../atoms/CommonButton';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { PrimaryButton } from '../../atoms/PrimaryButton';

export const ClaimERAFilesHeader: React.FC<ClaimERAFilesInterface> = ({
  onSubmit,
  months,
  totalERA,
  showInputField,
}) => {
  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <>
        <div className="grid md:grid-cols-6 gap-4 mt-6 mb-4">
          <SelectInput
            label=""
            options={months}
            enableDefaultPlaceholder={true}
            defaultPlaceholder="Select Month"
            name="month"
            onChange={onChangeFilter}
            value={filterObject?.month ?? ''}
          />
          {showInputField && (
            <InputField
              label=""
              placeholder="Check Number"
              name="checkNumber"
              onChange={onChangeFilter}
              value={filterObject?.checkNumber ?? ''}
            />
          )}
          <div className="grid md:grid-cols-2 gap-4 mt-auto">
            <PrimaryButton
              type="button"
              label="Submit"
              style={{ height: '40px' }}
              onClick={() => onSubmit(filterObject)}
            />
            <CommonButton
              label="Reset"
              buttonType="secondary"
              style={{ height: '40px' }}
              onClick={() => {
                onSubmit({});
                setFilterObject({});
              }}
            />
          </div>

          <div className={`${showInputField ? 'col-span-2' : 'col-span-3'}`} />

          <div className="table-action-area__left-actions">
            <div className="flex gap-2 mt-auto mb-auto ml-auto">
              <div>Total ERA</div>
              <Badge value={totalERA} />
            </div>
          </div>
        </div>
      </>
    </>
  );
};
