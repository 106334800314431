import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchClaimStatusListInitialInterface,
  FetchReasonListInitialInterface,
} from '../../../interfaces/systemInterfaces';
import { getClaimStatusListAPIRequest, getReasonListAPIRequest } from '../../../api/system/patient';

const initialState: FetchClaimStatusListInitialInterface = {
  claimStatusListLoading: false,
  claimStatusListData: [],
  claimStatusListError: null,
  claimStatusListStatus: 'IDLE',
};

export const getClaimStatusListRequest: any = createAsyncThunk('patient/claim-status/get', async (thunkAPI: any) => {
  try {
    const response: any = await getClaimStatusListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getClaimStatusListSlice = createSlice({
  name: 'fetchClaimStatus',
  initialState,
  reducers: {
    clearFetchClaimStatusListResponse: (state) => {
      state.claimStatusListLoading = false;
      state.claimStatusListStatus = 'IDLE';
      state.claimStatusListData = [];
      state.claimStatusListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimStatusListRequest.pending, (state, action) => {
        state.claimStatusListLoading = true;
        state.claimStatusListStatus = 'PENDING';
      })
      .addCase(getClaimStatusListRequest.fulfilled, (state, action) => {
        state.claimStatusListLoading = false;
        state.claimStatusListData = action.payload;
        state.claimStatusListStatus = 'SUCCESS';
      })
      .addCase(getClaimStatusListRequest.rejected, (state, action) => {
        state.claimStatusListLoading = false;
        state.claimStatusListError = action.payload;
        state.claimStatusListStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimStatusListResponse } = getClaimStatusListSlice.actions;

export const getClaimStatusListSelector = (state: RootState) => state.GetClaimStatus;

export default getClaimStatusListSlice.reducer;
