import {
  FetchInsuranceInterface,
  FetchInsuranceByIdInterface,
  InsuranceUpdateInterface,
  InsuranceDeleteByIdInterface,
} from '../../interfaces/insuranceInterface';
import { AddInsuranceInterface } from '../../interfaces/systemInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addInsuranceAPIRequest = async (insuranceData: AddInsuranceInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/insurance/`, 'post', {
    data: { ...insuranceData },
  });
};

export const getInsuranceAPIRequest = async (insuranceData: FetchInsuranceInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/insurance/search?page=${insuranceData?.page}&size=${insuranceData?.size}`,
    'get',
    {
      data: {},
    }
  );
};

export const getInsuranceByIdAPIRequest = async (insurance: FetchInsuranceByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/insurance/${insurance?.insuranceId}/${insurance?.section}`,
    'get',
    {
      data: {},
    }
  );
};

export const editInsuranceAPIRequest = async (insurance: InsuranceUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/insurance/${insurance?.insuranceId}/basic`,
    'put',
    {
      data: { ...insurance?.insuranceData },
    }
  );
};

export const deleteInsuranceAPIRequest = async (insurance: InsuranceDeleteByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/insurance/${insurance?.insuranceId}`,
    'delete',
    {}
  );
};
