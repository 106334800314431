import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { ClaimedMDERAPayementsHeader } from '../../../components/billing/claimedMDERAPayments/ClaimedMDERAPayementsHeader';
import { ConfirmModal } from '../../../components/list/patients/visit/ConfirmModal';

import {
  getClaimEraPaymentsRequest,
  getClaimEraPaymentsSelector,
  clearFetchClaimEraPaymentsResponse,
} from '../../../redux/slices/payments/claimEraFiles/getClaimEraPaymentsSlice';
import {
  verifyClaimEraPaymentRequest,
  verifyClaimEraPaymentSelector,
  clearVerifyClaimEraPaymentResponse,
} from '../../../redux/slices/payments/claimEraFiles/verifyClaimEraPaymentSlice';

import { formatCurrency } from '../../../utils/commonFunctions';
import { Spinner } from '../../../components/atoms/Spinner';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ClaimEra ID',
    dataIndex: 'claimEraId',
    key: 'claimEraId',
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraId',
    key: 'eraId',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Paid Amount',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Posted Amount',
    dataIndex: 'postedAmount',
    key: 'postedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payer ID',
    dataIndex: 'payerId',
    key: 'payerId',
    render: (text: any, record: any) => record?.payer?.payerId,
  },
  {
    title: 'Payer Name',
    dataIndex: 'payerName',
    key: 'payerName',
    render: (text: any, record: any) => record?.payer?.payerName,
  },
  {
    title: 'Provider NPI',
    dataIndex: 'providerNpi',
    key: 'providerNpi',
  },
  {
    title: 'Received Time',
    dataIndex: 'receivedTime',
    key: 'receivedTime',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM-DD-YYYY HH:mm') : ''),
  },
  {
    title: 'Posted Date',
    dataIndex: 'postedDate',
    key: 'postedDate',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM-DD-YYYY HH:mm:ss') : ''),
  },
];

const dummyDataSource = [
  {
    claimEraId: 8921,
    eraId: 'ERA123456',
    checkNumber: 'CHK8732300',
    paidDate: '2024-05-15',
    paidAmount: 1500.0,
    postedAmount: 1450.0,
    balance: 50.0,
    payer: {
      payerId: 38224,
      payerName: 'HEALTH ALLIANCE PLAN',
    },
    providerNpi: 'NPI1234567',
    receivedTime: '2024-05-16T10:00:00Z',
    postedDate: '2024-05-17T10:00:00Z',
    verified: false,
    verifiedBy: null,
  },
  {
    claimEraId: 323,
    eraId: '992120',
    checkNumber: '4349200',
    paidDate: '2024-10-21',
    paidAmount: 2434.0,
    postedAmount: 3899.0,
    balance: 1367.0,
    payer: {
      payerId: 123223,
      payerName: 'MEDICARE',
    },
    providerNpi: 'NPI1234434',
    receivedTime: '2024-10-21T10:00:00Z',
    postedDate: '2024-10-21T10:00:00Z',
    verified: true,
    verifiedBy: 'Jake Williams',
  },
];

export const months = [
  { id: '05-2023', name: 'May - 2023' },
  { id: '06-2023', name: 'June - 2023' },
  { id: '07-2023', name: 'July - 2023' },
  { id: '08-2023', name: 'August - 2023' },
  { id: '09-2023', name: 'September - 2023' },
  { id: '10-2023', name: 'October - 2023' },
  { id: '11-2023', name: 'November - 2023' },
  { id: '12-2023', name: 'December - 2023' },
  { id: '01-2024', name: 'January - 2024' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'claimMdEra', label: 'ClaimMD ERA Payments', status: 'active', link: 'claimmd-era' },
];

export const ClaimMDERAPaymentsPage = () => {
  const dispatch = useAppDispatch();

  const { fetchClaimEraPaymentsData, fetchClaimEraPaymentsStatus, fetchClaimEraPaymentsLoading } =
    useAppSelector(getClaimEraPaymentsSelector);
  const { verifyClaimEraPaymentStatus } = useAppSelector(verifyClaimEraPaymentSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});
  const [selectedToVerify, setSelectedToVerify] = useState<any>({});
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setOpenConfirmModal(false);
  }, []);

  // useEffect(() => {
  //   if (filterObject) {
  //     fetchClaimEraPayments();
  //   }
  // }, [filterObject]);

  useEffect(() => {
    if (fetchClaimEraPaymentsStatus === 'SUCCESS') {
      setDataSource(fetchClaimEraPaymentsData?.items);
      setCurrentPage(fetchClaimEraPaymentsData?.currentPage);
      setTotalPages(fetchClaimEraPaymentsData?.totalPages);
      dispatch(clearFetchClaimEraPaymentsResponse());
    } else if (fetchClaimEraPaymentsStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchClaimEraPaymentsResponse());
    }
  }, [fetchClaimEraPaymentsStatus]);

  useEffect(() => {
    if (verifyClaimEraPaymentStatus === 'SUCCESS') {
      fetchClaimEraPayments();
      setAlertObj({
        color: 'success',
        message: 'Verified Successfully !',
        error: true,
      });
      setVisibleAlert(true);
      setOpenConfirmModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearVerifyClaimEraPaymentResponse());
    } else if (verifyClaimEraPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearVerifyClaimEraPaymentResponse());
    }
  }, [verifyClaimEraPaymentStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchClaimEraPayments(page - 1);
  };

  const fetchClaimEraPayments = async (pageNumber = currentPage) => {
    dispatch(getClaimEraPaymentsRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.month || updatedData?.month === '') {
      delete updatedData.month;
    }

    if (updatedData?.eraId === '') {
      delete updatedData.eraId;
    }

    if (updatedData?.checkNumber === '') {
      delete updatedData.checkNumber;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleVerify = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dummyDataSource[rowIndex];

    if (dataSet?.verified) {
      return;
    }

    setSelectedToVerify(dataSet);
    setOpenConfirmModal(true);
  };

  const handleDownload = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const confirmVerify = () => {
    dispatch(
      verifyClaimEraPaymentRequest({
        claimEraId: selectedToVerify?.claimEraId,
        checkNumber: selectedToVerify?.checkNumber,
        verified: true,
      })
    );
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
    setSelectedToVerify({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="ClaimMD ERA Payments" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ClaimedMDERAPayementsHeader totalERA={'3550'} months={months} onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {fetchClaimEraPaymentsLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent
                  enableActions={true}
                  columns={columns}
                  dataSource={dummyDataSource}
                  enableVerifyButton
                  enableDownload
                  onHandleDownload={handleDownload}
                  onClickVerifyButton={handleVerify}
                />
              </div>

              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}

      {openConfirmModal && (
        <ConfirmModal
          openConfirm={openConfirmModal}
          setOpenConfirm={onCloseConfirm}
          title="Verify ERA"
          content={`Are you sure you want to verify Check # ${selectedToVerify?.checkNumber}?`}
          submitButtonTitle="Verify"
          cancelButtonTitle="Cancel"
          handleSubmit={confirmVerify}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}
    </div>
  );
};
