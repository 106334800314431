import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  VisitBilledCountListFetchInterface,
  VisitBilledCountListFetchPropsInterface,
} from '../../../../interfaces/managementInterfaces';
import { getVisitBilledCountsAPIRequest } from '../../../../api/management/visitBilledCount';

const initialState: VisitBilledCountListFetchPropsInterface = {
  fetchVisitBilledCountListLoading: false,
  fetchVisitBilledCountListData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchVisitBilledCountListError: null,
  fetchVisitBilledCountListStatus: 'IDLE',
};

export const getVisitBilledCountsRequest: any = createAsyncThunk(
  'management/visitBilledCount/get',
  async (visitData: VisitBilledCountListFetchInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getVisitBilledCountsAPIRequest(visitData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitBilledCountsSlice = createSlice({
  name: 'fetchVisitBilledCounts',
  initialState,
  reducers: {
    clearFetchVisitBilledCountsResponse: (state) => {
      state.fetchVisitBilledCountListLoading = false;
      state.fetchVisitBilledCountListData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchVisitBilledCountListStatus = 'IDLE';
      state.fetchVisitBilledCountListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitBilledCountsRequest.pending, (state, action) => {
        state.fetchVisitBilledCountListLoading = true;
        state.fetchVisitBilledCountListStatus = 'PENDING';
      })
      .addCase(getVisitBilledCountsRequest.fulfilled, (state, action) => {
        state.fetchVisitBilledCountListLoading = false;
        state.fetchVisitBilledCountListStatus = 'SUCCESS';
        state.fetchVisitBilledCountListData = action.payload;
      })
      .addCase(getVisitBilledCountsRequest.rejected, (state, action) => {
        state.fetchVisitBilledCountListLoading = false;
        state.fetchVisitBilledCountListStatus = 'FAILED';
        state.fetchVisitBilledCountListError = action.payload;
      });
  },
});

export const { clearFetchVisitBilledCountsResponse } = getVisitBilledCountsSlice.actions;

export const getVisitBilledCountsSelector = (state: RootState) => state.GetVisitBilledCounts;

export default getVisitBilledCountsSlice.reducer;
