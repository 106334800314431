import { DatePickerField } from '../../atoms/DatePickerField';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';

export const Form = () => {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Payer Name"
          name="payerName"
          // onChange={onChange}
          // defaultValue={eligibilityFormEditData?.firstName ?? undefined}
          // error={errorObject && errorObject?.firstName}
          // errorMessage={errorObject && errorObject?.firstName}
          required={true}
        />
        <SelectInput
          label="Service Type"
          options={[]}
          enableDefaultPlaceholder={true}
          name="serviceType"
          // onChange={onChange}
          // defaultValue={eligibilityFormEditData?.insuranceId ?? undefined}
          // error={errorObject && errorObject?.insuranceId}
          // errorMessage={errorObject && errorObject?.insuranceId}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="First Name"
          name="firstName"
          // onChange={onChange}
          // defaultValue={eligibilityFormEditData?.firstName ?? undefined}
          // error={errorObject && errorObject?.firstName}
          // errorMessage={errorObject && errorObject?.firstName}
          required={true}
        />
        <InputField
          label="Last Name"
          name="lastName"
          // onChange={onChange}
          // defaultValue={eligibilityFormEditData?.lastName ?? undefined}
          // error={errorObject && errorObject?.lastName}
          // errorMessage={errorObject && errorObject?.lastName}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <DatePickerField
          label="Date of Birth"
          name="patientDoB"
          // selectedDate={formatDatePickerDate(eligibilityFormEditData?.patientDoB)}
          // onChange={(date: Date | null) => {
          //   if (date) {
          //     onEligibilityFormDates(date, 'patientDoB');
          //   }
          // }}
          required={true}
        />
        <InputField
          label="Policy ID"
          name="policyId"
          // onChange={onChange}
          // defaultValue={eligibilityFormEditData?.policy ?? undefined}
          // error={errorObject && errorObject?.policy}
          // errorMessage={errorObject && errorObject?.policy}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-5 mt-6">
        <DatePickerField
          label="Service Date"
          name="dateOfService"
          // selectedDate={formatDatePickerDate(eligibilityFormEditData?.dateOfService)}
          // onChange={(date: Date | null) => {
          //   if (date) {
          //     onEligibilityFormDates(date, 'dateOfService');
          //   }
          // }}
          required={true}
        />
      </div>
    </>
  );
};
