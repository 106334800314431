import { NestedTableProps } from '../../interfaces/commonInterfaces';
import './tableContent.css';

export const NestedTableContent: React.FC<NestedTableProps> = ({ columns = [], dataSource = [] }) => {
  return (
    <>
      <div className="table-content">
        <div className="table-content__table-view">
          <table className="table-content__table">
            <thead className="table-content__table-header">
              <tr>
                {columns.map((column) => (
                  <th key={column.key} colSpan={column.children ? column.children.length : 1} className="text-center">
                    {column.title}
                  </th>
                ))}
              </tr>
              <tr>
                {columns.map((column) =>
                  column.children ? (
                    column.children.map((subColumn: any) => (
                      <th key={subColumn.key} className="text-center">
                        {subColumn.title}
                      </th>
                    ))
                  ) : (
                    <th key={column.key} className="text-center"></th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="table-content__table-body">
              {dataSource.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((column) =>
                    column.children ? (
                      column.children.map((subColumn: any) => (
                        <td key={subColumn.key} className="text-center">
                          {subColumn.render
                            ? subColumn.render(row[subColumn.dataIndex], row)
                            : row[subColumn.dataIndex]}
                        </td>
                      ))
                    ) : (
                      <td key={column.key}>
                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
