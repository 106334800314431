import { usStates } from '../../../constants/systemConstants';
import { FacilityFormInterface } from '../../../interfaces/facilitiesInterface';
import { InputField } from '../../atoms/InputField';
import { Checkbox } from '../../atoms/Checkbox';
import { SelectInput } from '../../atoms/SelectInput';

export const FacilityForm: React.FC<FacilityFormInterface> = ({ onChange, formData, errorObject }) => {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Name"
          name="name"
          onChange={onChange}
          defaultValue={formData?.name ?? undefined}
          error={errorObject && errorObject?.name}
          errorMessage={errorObject && errorObject?.name}
          required={true}
        />
        <InputField
          label="NPI"
          name="npi"
          onChange={onChange}
          defaultValue={formData?.npi ?? undefined}
          error={errorObject && errorObject?.npi}
          errorMessage={errorObject && errorObject?.npi}
          required={true}
          tooltip="Character length must be 10"
        />
        {/* <InputField
          label="Other ID"
          name="otherId"
          onChange={onChange}
          defaultValue={formData?.otherId ?? undefined}
          error={errorObject && errorObject?.otherId}
          errorMessage={errorObject && errorObject?.otherId}
        /> */}
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Address"
          name="address"
          onChange={onChange}
          defaultValue={formData?.address ?? undefined}
          error={errorObject && errorObject?.address}
          errorMessage={errorObject && errorObject?.address}
          required={true}
        />
        <InputField
          label="City"
          name="city"
          onChange={onChange}
          defaultValue={formData?.city ?? undefined}
          error={errorObject && errorObject?.city}
          errorMessage={errorObject && errorObject?.city}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          defaultValue={formData?.state ?? undefined}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          required={true}
        />
        <InputField
          label="Zip Code"
          name="zipcode"
          onChange={onChange}
          defaultValue={formData?.zipcode ?? undefined}
          error={errorObject && errorObject?.zipcode}
          errorMessage={errorObject && errorObject?.zipcode}
          required={true}
          tooltip="Character length must be 5"
        />
        <InputField
          label="Extension"
          name="extension"
          onChange={onChange}
          type="number"
          defaultValue={formData?.extension ?? undefined}
          error={errorObject && errorObject?.extension}
          errorMessage={errorObject && errorObject?.extension}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        {/* <SelectInput
          label="Country"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="countryCode"
          onChange={onChange}
          defaultValue={formData?.countryCode ?? undefined}
          error={errorObject && errorObject?.countryCode}
          errorMessage={errorObject && errorObject?.countryCode}
        /> */}
        <InputField
          label="Country"
          name="countryCode"
          onChange={onChange}
          defaultValue="US"
          disabled
          error={errorObject && errorObject?.countryCode}
          errorMessage={errorObject && errorObject?.countryCode}
        />
        <InputField
          label="Locality"
          name="locality"
          onChange={onChange}
          defaultValue={formData?.locality ?? undefined}
          error={errorObject && errorObject?.locality}
          errorMessage={errorObject && errorObject?.locality}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Phone"
          name="phone"
          onChange={onChange}
          defaultValue={formData?.phone ?? undefined}
          error={errorObject && errorObject?.phone}
          errorMessage={errorObject && errorObject?.phone}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Fax"
          name="fax"
          onChange={onChange}
          defaultValue={formData?.fax ?? undefined}
          error={errorObject && errorObject?.fax}
          errorMessage={errorObject && errorObject?.fax}
          tooltip="10-digit fax number required"
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Email"
          name="email"
          onChange={onChange}
          defaultValue={formData?.email ?? undefined}
          error={errorObject && errorObject?.email}
          errorMessage={errorObject && errorObject?.email}
        />
        <InputField
          label="Website"
          name="website"
          onChange={onChange}
          defaultValue={formData?.website ?? undefined}
          error={errorObject && errorObject?.website}
          errorMessage={errorObject && errorObject?.website}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Primary business entity"
          name="primaryBusinessEntity"
          onChange={onChange}
          defaultValue={formData?.primaryBusinessEntity ?? undefined}
          type="number"
          error={errorObject && errorObject?.primaryBusinessEntity}
          errorMessage={errorObject && errorObject?.primaryBusinessEntity}
        />
        <InputField
          label="Service Location"
          name="serviceLocation"
          onChange={onChange}
          defaultValue={formData?.serviceLocation ?? undefined}
          type="number"
          error={errorObject && errorObject?.serviceLocation}
          errorMessage={errorObject && errorObject?.serviceLocation}
        />
        <SelectInput
          label="Insurance Billing State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="insuranceBillingState"
          onChange={onChange}
          defaultValue={formData?.insuranceBillingState ?? undefined}
          error={errorObject && errorObject?.insuranceBillingState}
          errorMessage={errorObject && errorObject?.insuranceBillingState}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Place of service"
          name="placeOfService"
          onChange={onChange}
          defaultValue={formData?.placeOfService ?? undefined}
          error={errorObject && errorObject?.placeOfService}
          errorMessage={errorObject && errorObject?.placeOfService}
        />
        <InputField
          label="TIN"
          name="tin"
          onChange={onChange}
          defaultValue={formData?.tin ?? undefined}
          error={errorObject && errorObject?.tin}
          errorMessage={errorObject && errorObject?.tin}
          tooltip="Character length must be 9"
        />
        <InputField
          label="PTAN"
          name="ptan"
          onChange={onChange}
          defaultValue={formData?.ptan ?? undefined}
          error={errorObject && errorObject?.ptan}
          errorMessage={errorObject && errorObject?.ptan}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <Checkbox
          label="Donot Call Facility"
          onChange={onChange}
          name="doNotCallFacility"
          defaultChecked={formData?.doNotCallFacility}
        />
      </div>
    </>
  );
};
