import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ProviderVisitLagReportAdvanceSearch } from '../../components/tableSearch/ProviderVisitLagReportAdvanceSearch';

import {
  getProviderVisitLagReportRequest,
  getProviderVisitLagReportSelector,
  clearProviderVisitLagReportResponse,
} from '../../redux/slices/reports/getProviderVisitLagReportSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Provider ID',
    dataIndex: 'providerId',
    key: 'providerId',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Last Visit Date',
    dataIndex: 'lastVisitDate',
    key: 'lastVisitDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Last Visit Creaated At',
    dataIndex: 'lastVisitCreatedAt',
    key: 'lastVisitCreatedAt',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Days',
    dataIndex: 'days',
    key: 'days',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
];

const dummyDataSource = [
  {
    providerId: 241,
    firstName: 'BASHIR',
    lastName: 'AHMED',
    type: 'Provider',
    lastVisitDate: '2024-09-26',
    lastVisitCreatedAt: '2024-10-14',
    days: 28,
    state: 'NY',
  },
  {
    providerId: 240,
    firstName: 'ABDULLA ABDEL',
    lastName: 'HAFEEZ',
    type: 'Provider',
    lastVisitDate: '2024-09-25',
    lastVisitCreatedAt: '2024-10-14',
    days: 29,
    state: 'MI',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'providerVisitLagReport',
    label: 'Provider Visit Lag Report',
    status: 'active',
    link: 'provider-lag-report',
  },
];

export const ProviderVisitLagReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchProviderVisitLagReportData, fetchProviderVisitLagReportStatus, fetchProviderVisitLagReportLoading } =
    useAppSelector(getProviderVisitLagReportSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemProvidersProviderData?.length === 0) {
      fetchSystemProviders();
    }
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchProviderVisitLagReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchProviderVisitLagReportStatus === 'SUCCESS') {
      setDataSource(fetchProviderVisitLagReportData?.items);
      setCurrentPage(fetchProviderVisitLagReportData?.currentPage);
      setTotalPages(fetchProviderVisitLagReportData?.totalPages);
      dispatch(clearProviderVisitLagReportResponse());
    } else if (fetchProviderVisitLagReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearProviderVisitLagReportResponse());
    }
  }, [fetchProviderVisitLagReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchProviderVisitLagReport(page - 1);
  };

  const fetchProviderVisitLagReport = async (pageNumber = currentPage) => {
    dispatch(getProviderVisitLagReportRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.lagDays || updatedData?.lagDays === '') {
      delete updatedData.lagDays;
    } else {
      updatedData.lagDays = parseInt(updatedData?.lagDays, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Provider Visit Lag Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ProviderVisitLagReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchProviderVisitLagReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="flex justify-end mb-4">
                <div className="flex items-center">
                  <label htmlFor="search" className="mr-2 text-gray-600">
                    Search:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded px-2 py-1"
                    name="search"
                    title="Search"
                    disabled
                  />
                </div>
              </div>

              <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
