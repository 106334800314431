import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { CommonAlert } from '../../components/atoms/Alert';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { getInsuranceRequest, getInsuranceSelector } from '../../redux/slices/insurance/getInsuranceSlice';
import {
  getInsuranceByIdRequest,
  getInsuranceByIdSelector,
  clearFetchInsuranceByIdResponse,
} from '../../redux/slices/insurance/getInsurancebyIdSlice';
import {
  addInsuranceRequest,
  addInsuranceSelector,
  clearAddInsuranceResponse,
} from '../../redux/slices/insurance/addInsuranceSlice';
import {
  editInsuranceRequest,
  editInsuranceSelector,
  clearEditInsuranceResponse,
} from '../../redux/slices/insurance/editInsuranceSlice';
import {
  deleteInsuranceRequest,
  deleteInsuranceSelector,
  clearDeleteInsuranceResponse,
} from '../../redux/slices/insurance/deleteInsuranceSlice';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { InsuranceForm } from '../../components/list/insurance/InsuranceForm';
import { InsuranceProviders } from '../../components/list/insurance/providers/InsuranceProviders';
import { InsuranceFacilities } from '../../components/list/insurance/facilities/InsuranceFacilities';
import { createInsuranceValidation } from '../../utils/insuranceValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TableSearch } from '../../components/tableSearch/TableSearch';
import { RightSheetLarge } from '../../components/rightSheet/RightSheetLarge';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemInsuranceRequest } from '../../redux/slices/system/getSystemInsuranceSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Payer ID',
    dataIndex: 'payerId',
    key: 'payerId',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'DBIN State',
    dataIndex: 'dbInState',
    key: 'dbInState',
  },
  {
    title: 'Zip Code',
    dataIndex: 'zipCode',
    key: 'zipCode',
  },
];

const dataSource = [
  {
    id: 1,
    payerId: '08202',
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    dbInState: 'Doe',
    state: 'MI',
  },
  {
    id: 1,
    payerId: '08202',
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    dbInState: 'Doe',
    state: 'MI',
  },
  {
    id: 1,
    payerId: '08202',
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    dbInState: 'Doe',
    state: 'MI',
  },
  {
    id: 1,
    payerId: '08202',
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    dbInState: 'Doe',
    state: 'MI',
  },
];

const stepperData = [
  { id: 1, label: 'Basic', requestParam: 'basic' },
  { id: 2, label: 'Providers', requestParam: 'provider' },
  { id: 3, label: 'Facility', requestParam: 'facility' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'insurance', label: 'Insurance', status: 'active', link: 'insurance' },
];

export const InsurancePage = () => {
  const dispatch = useAppDispatch();
  const { insuranceData, insuranceLoading, insuranceStatus } = useAppSelector(getInsuranceSelector);
  const { addInsuranceData, addInsuranceLoading, addInsuranceStatus } = useAppSelector(addInsuranceSelector);
  const { fetchInsuranceByIdStatus, fetchInsuranceByIdData, fetchInsuranceByIdLoading } =
    useAppSelector(getInsuranceByIdSelector);
  const { editInsuranceStatus } = useAppSelector(editInsuranceSelector);
  const { deleteInsuranceByIdStatus } = useAppSelector(deleteInsuranceSelector);

  const [visibleModal, setVisibleModal] = useState(false);
  const [createFormData, setCreateFormData] = useState<any>({});

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [selectedInsuranceId, setSelectedInsuranceId] = useState<number | null>();
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(1);
  const [selectedSection, setSelectedSection] = useState('');
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});
  const [enableFormAlert, setEnableFormAlert] = useState(false);
  const [formAlertDetails, setformAlertDetails] = useState<any>({});
  const [fetchingLoading, setFetchingLoading] = useState(false);

  useEffect(() => {
    fetchInsurance();
  }, []);

  useEffect(() => {
    if (insuranceStatus === 'SUCCESS' && !insuranceLoading) {
      setDataSource(insuranceData?.items);
      setCurrentPage(insuranceData?.currentPage);
      setTotalPages(insuranceData?.totalPages);
    }
  }, [insuranceStatus]);

  useEffect(() => {
    if (addInsuranceStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      fetchInsurance();
      getDropdwonData();
      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setCreateFormData({});
        setErrorObj({});
      }, 3000);
    } else if (addInsuranceStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }

    dispatch(clearAddInsuranceResponse());
  }, [addInsuranceStatus]);

  useEffect(() => {
    if (fetchInsuranceByIdStatus === 'SUCCESS') {
      setCreateFormData(fetchInsuranceByIdData);
      setVisibleEditForm(true);
      setFetchingLoading(false);
      dispatch(clearFetchInsuranceByIdResponse());
    } else if (fetchInsuranceByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      setFetchingLoading(false);
      dispatch(clearFetchInsuranceByIdResponse());
    }
  }, [fetchInsuranceByIdStatus]);

  useEffect(() => {
    if (editInsuranceStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setFetchingLoading(true);
      fetchInsuranceById(null, 'basic');
      getDropdwonData();
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditInsuranceResponse());
    } else if (editInsuranceStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditInsuranceResponse());
    }
  }, [editInsuranceStatus]);

  useEffect(() => {
    if (deleteInsuranceByIdStatus === 'SUCCESS') {
      fetchInsurance();
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDeleteInsuranceResponse());
    } else if (deleteInsuranceByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDeleteInsuranceResponse());
    }
  }, [deleteInsuranceByIdStatus]);

  const getDropdwonData = () => {
    dispatch(getSystemInsuranceRequest());
  };

  const fetchInsurance = async () => {
    dispatch(getInsuranceRequest({ size: PAGE_SIZE, page: currentPage }));
  };

  const fetchInsuranceById = async (id = null, section = '') => {
    dispatch(
      getInsuranceByIdRequest({
        insuranceId: id ?? selectedInsuranceId,
        section: section ?? selectedSection,
      })
    );
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    dispatch(getInsuranceRequest({ page: page - 1, size: PAGE_SIZE }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleModalVisible = () => {
    setErrorObj({});
    setCreateFormData({});
    setMode('CREATE');
    setVisibleModal(true);
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedChild(1);
    setSelectedSection('basic');
    setMode('EDIT');
    const dataSet = dataSource[rowIndex];
    setSelectedInsuranceId(dataSet?.id);
    fetchInsuranceById(dataSet?.id, 'basic');
  };

  const onView = (event: any, rowIndex: any) => {
    event?.preventDefault();
    setSelectedChild(1);
    setSelectedSection('basic');
    setMode('VIEW');
    const dataSet = dataSource[rowIndex];
    setSelectedInsuranceId(dataSet?.id);
    fetchInsuranceById(dataSet?.id, 'basic');
  };

  const onDelete = (event: any, rowIndex: any) => {
    const dataset = dataSource[rowIndex];
    setSelectedInsuranceId(dataset?.id);
    dispatch(deleteInsuranceRequest({ insuranceId: dataset?.id }));
  };

  const renderTab = () => {
    switch (selectedChild) {
      case 1:
        return fetchingLoading ? (
          <Spinner />
        ) : (
          <InsuranceForm formData={createFormData} onChange={onChangeFormData} mode={mode} />
        );
      case 2:
        return (
          <InsuranceProviders
            mode={mode}
            selectedInsuranceId={selectedInsuranceId}
            fetchInsuranceById={fetchInsuranceById}
            fetchDataLoading={fetchInsuranceByIdLoading}
          />
        );
      case 3:
        return (
          <InsuranceFacilities
            mode={mode}
            selectedInsuranceId={selectedInsuranceId}
            fetchInsuranceById={fetchInsuranceById}
            fetchDataLoading={fetchInsuranceByIdLoading}
          />
        );
    }
  };

  const handleClickStepper = (id: any) => {
    setSelectedChild(id);
    setSelectedSection(stepperData[id - 1]?.requestParam.toLowerCase());
    setFetchingLoading(true);
    fetchInsuranceById(null, stepperData[id - 1]?.requestParam?.toLowerCase());
  };

  const convertBeforeSubmit = (formData: any) => {
    let obj = {
      ...formData,
      daysToProcess: formData?.daysToProcess ? parseInt(formData.daysToProcess, 10) : undefined,
      // eligibilityPayerId: parseInt(formData?.eligibilityPayerId, 10),  // Issue SV-432
      // claimPayerId: parseInt(formData?.claimPayerId, 10),
      eligibilityPayerId: formData?.eligibilityPayerId,
      claimPayerId: formData?.claimPayerId,
    };
    return obj;
  };

  const onSubmit = () => {
    if (mode === 'CREATE') {
      let obj = convertBeforeSubmit(createFormData);
      const validationDetails = createInsuranceValidation(obj);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        // setformAlertDetails({
        //   message: (
        //     <div>
        //       {errorMessages.map((msg: any, index) => (
        //         <div key={index} className="flex items-center">
        //           <IoIosInformationCircleOutline />
        //           <span style={{ marginLeft: '8px' }}>{msg}</span>
        //         </div>
        //       ))}
        //     </div>
        //   ),
        //   color: 'failure',
        // });
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        // let handledForm = createFormData;
        // if (createFormData?.daysToProcess) {
        //   handledForm.daysToProcess = parseInt(createFormData?.daysToProcess);
        // }

        // if (createFormData?.eligibilityPayerId) {
        //   handledForm.eligibilityPayerId = parseInt(createFormData?.eligibilityPayerId);
        // }

        // if (createFormData?.claimPayerId) {
        //   handledForm.claimPayerId = parseInt(createFormData?.claimPayerId);
        // }
        dispatch(addInsuranceRequest(obj));
      }
    } else if (mode === 'EDIT') {
      let obj = {
        insuranceId: selectedInsuranceId,
        insuranceData: convertBeforeSubmit(createFormData),
      };

      const validationDetails = createInsuranceValidation(obj?.insuranceData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);

        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(editInsuranceRequest(obj));
      }
    }
  };

  const onChangeFormData = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setCreateFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleOnClose = () => {
    setCreateFormData({});
    setErrorObj({});
    setMode('CREATE');
    setVisibleModal(false);
    setVisibleEditForm(false);
    setFetchingLoading(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    fetchInsurance();
  };

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Insurance" breadCrumbArr={breadCrumbArr} />

      {insuranceLoading ? (
        <Spinner />
      ) : (
        <>
          {!insuranceLoading && dataSource?.length > 0 ? (
            <>
              <div className="flex justify-end mb-4">
                <div className="flex items-center">
                  <label htmlFor="search" className="mr-2 text-gray-600">
                    Search:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded px-2 py-1"
                    name="search"
                    title="Search"
                    disabled
                  />
                </div>
              </div>

              <TableContent
                enableActions={true}
                columns={columns}
                dataSource={dataSource}
                enableView={true}
                enableEdit={true}
                enableDelete={true}
                enableCopy={true}
                onEdit={onEdit}
                onView={onView}
                onDelete={onDelete}
              />

              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      )}

      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title="Create Insurance"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          children={<InsuranceForm onChange={onChangeFormData} errorObject={errorObj} />}
          onSubmit={onSubmit}
          loading={addInsuranceLoading}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}

      {visibleEditForm && (
        <RightSheetLarge
          title={mode === 'VIEW' ? 'View Insurance' : 'Update Insurance'}
          onSubmit={onSubmit}
          onClose={handleOnClose}
          submitButtonTitle="Update"
          cancelButtonTitle="Cancel"
          children={<>{renderTab()}</>}
          stepperData={stepperData}
          clickStepper={handleClickStepper}
          enableFooterButtons={mode === 'VIEW' ? false : selectedSection === 'basic'}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      )}
    </div>
  );
};
