import { PatientEligibility } from '../../components/billing/patientEligibility/PatientEligibility';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';

export const PatientEligibilityPage = () => {
  const breadCrumbArr = [
    { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
    { id: 'primaryEligibility', label: 'Patient Eligibility', status: 'active', link: 'patient-eligibility' },
  ];
  return (
    <>
      <div className="main-content">
        <MainHeader />
        <PageTopic mainTitle="Patient Eligibility" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
        <PatientEligibility />
      </div>
    </>
  );
};
