import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ClientsListFetchPropsInterface } from '../../../interfaces/systemInterfaces';
import { getClientsListAPIRequest } from '../../../api/management/chargeDetailVisitReport';

const initialState: ClientsListFetchPropsInterface = {
  clientsListLoading: false,
  clientsListData: [],
  clientsListError: null,
  clientsListStatus: 'IDLE',
};

export const getClientsListRequest: any = createAsyncThunk('system/clientList/get', async (thunkAPI: any) => {
  try {
    // const response: any = await getClientsListAPIRequest();
    // return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getClientsListSlice = createSlice({
  name: 'fetchClientsList',
  initialState,
  reducers: {
    clearFetchClientsListResponse: (state) => {
      state.clientsListLoading = false;
      state.clientsListStatus = 'IDLE';
      state.clientsListData = [];
      state.clientsListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsListRequest.pending, (state, action) => {
        state.clientsListLoading = true;
        state.clientsListStatus = 'PENDING';
      })
      .addCase(getClientsListRequest.fulfilled, (state, action) => {
        state.clientsListLoading = false;
        state.clientsListData = action.payload;
        state.clientsListStatus = 'SUCCESS';
      })
      .addCase(getClientsListRequest.rejected, (state, action) => {
        state.clientsListLoading = false;
        state.clientsListError = action.payload;
        state.clientsListStatus = 'FAILED';
      });
  },
});

export const { clearFetchClientsListResponse } = getClientsListSlice.actions;

export const getClientsListSelector = (state: RootState) => state.GetClientsList;

export default getClientsListSlice.reducer;
