import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddInsuranceSubscriberInterface,
  AddInsuranceSubscriberInitialInterface,
} from '../../../interfaces/patientInterface';
import { addInsuranceSubscriberAPIRequest } from '../../../api/patient/patientInsurance';

const initialState: AddInsuranceSubscriberInitialInterface = {
  addInsuranceSubscriberLoading: false,
  addInsuranceSubscriberData: {},
  addInsuranceSubscriberError: null,
  addInsuranceSubscriberStatus: 'IDLE',
};

export const addInsuranceSubscriberRequest: any = createAsyncThunk(
  'patient/insurance/subscriber/add',
  async (subscriberData: AddInsuranceSubscriberInterface, thunkAPI: any) => {
    try {
      const response: any = await addInsuranceSubscriberAPIRequest(subscriberData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addInsuranceSubscriberSlice = createSlice({
  name: 'addPatientInsuranceSubscriber',
  initialState,
  reducers: {
    clearAddPatientInsuranceSubscriberResponse: (state) => {
      state.addInsuranceSubscriberLoading = false;
      state.addInsuranceSubscriberStatus = 'IDLE';
      state.addInsuranceSubscriberData = {};
      state.addInsuranceSubscriberError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addInsuranceSubscriberRequest.pending, (state, action) => {
        state.addInsuranceSubscriberLoading = true;
        state.addInsuranceSubscriberStatus = 'PENDING';
      })
      .addCase(addInsuranceSubscriberRequest.fulfilled, (state, action) => {
        state.addInsuranceSubscriberData = action.payload;
        state.addInsuranceSubscriberStatus = 'SUCCESS';
        state.addInsuranceSubscriberLoading = false;
      })
      .addCase(addInsuranceSubscriberRequest.rejected, (state, action) => {
        state.addInsuranceSubscriberLoading = false;
        state.addInsuranceSubscriberError = action.payload;
        state.addInsuranceSubscriberStatus = 'FAILED';
      });
  },
});

export const { clearAddPatientInsuranceSubscriberResponse } = addInsuranceSubscriberSlice.actions;

export const addInsuranceSubscriberSelector = (state: RootState) => state.AddPatientInsuranceSubscriber;

export default addInsuranceSubscriberSlice.reducer;
