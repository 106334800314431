import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchExpectedVsCollectionsReportInterface,
  FetchExpectedVsCollectionsReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getExpectedVsCollectionsReportAPIRequest } from '../../../api/reports/expectedVsCollectionsReport';

const initialState: FetchExpectedVsCollectionsReportInitialInterface = {
  fetchExpectedVsCollectionsReportLoading: false,
  fetchExpectedVsCollectionsReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchExpectedVsCollectionsReportError: null,
  fetchExpectedVsCollectionsReportStatus: 'IDLE',
};

export const getExpectedVsCollectionsReportRequest: any = createAsyncThunk(
  'reports/expected-vs-collections/get',
  async (reportData: FetchExpectedVsCollectionsReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getExpectedVsCollectionsReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getExpectedVsCollectionsReportSlice = createSlice({
  name: 'fetchExpectedVsCollectionsReport',
  initialState,
  reducers: {
    clearFetchExpectedVsCollectionsReportResponse: (state) => {
      state.fetchExpectedVsCollectionsReportLoading = false;
      state.fetchExpectedVsCollectionsReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchExpectedVsCollectionsReportStatus = 'IDLE';
      state.fetchExpectedVsCollectionsReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpectedVsCollectionsReportRequest.pending, (state, action) => {
        state.fetchExpectedVsCollectionsReportLoading = true;
        state.fetchExpectedVsCollectionsReportStatus = 'PENDING';
      })
      .addCase(getExpectedVsCollectionsReportRequest.fulfilled, (state, action) => {
        state.fetchExpectedVsCollectionsReportLoading = false;
        state.fetchExpectedVsCollectionsReportStatus = 'SUCCESS';
        state.fetchExpectedVsCollectionsReportData = action.payload;
      })
      .addCase(getExpectedVsCollectionsReportRequest.rejected, (state, action) => {
        state.fetchExpectedVsCollectionsReportLoading = false;
        state.fetchExpectedVsCollectionsReportStatus = 'FAILED';
        state.fetchExpectedVsCollectionsReportError = action.payload;
      });
  },
});

export const { clearFetchExpectedVsCollectionsReportResponse } = getExpectedVsCollectionsReportSlice.actions;

export const getExpectedVsCollectionsReportSelector = (state: RootState) => state.GetExpectedVsCollectionsReport;

export default getExpectedVsCollectionsReportSlice.reducer;
