import { authenticatedRequest } from '../../utils/commonAxios';
import {
  FetchPatientTransactionSummaryInterface,
  PatientTransactionResponsibilityUpdateInterface,
  PatientPaymentListFetchInterface,
  PatientPaymentAddInterface,
  PatientPaymentFetchByIdInterface,
  PatientPaymentUpdateInterface,
} from '../../interfaces/patientInterface';

export const getTransactionsSumamryAPIRequest = async (patient: FetchPatientTransactionSummaryInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patient?.patientId}/transaction-summary`,
    'get',
    {}
  );
};

export const getPaymentListAPIRequest = async (transaction: PatientPaymentListFetchInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/transaction/${transaction?.transactionId}/payments?page=${transaction?.page}&size=${transaction?.size}`,
    'get',
    {}
  );
};

export const addPatientPaymentAPIRequest = async (payment: PatientPaymentAddInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/transaction/${payment?.transactionId}/payment`,
    'post',
    {
      data: { ...payment?.paymentData },
    }
  );
};

export const getPaymentByIdAPIRequest = async (payment: PatientPaymentFetchByIdInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/payment/${payment?.paymentId}`, 'get', {});
};

export const editPatientPaymentAPIRequest = async (payment: PatientPaymentUpdateInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/payment/${payment?.paymentId}`, 'put', {
    data: { ...payment?.paymentData },
  });
};

export const deletePatientPaymentAPIRequest = async (payment: PatientPaymentFetchByIdInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/payment/${payment?.paymentId}`, 'delete', {});
};

export const editTransactionResponsibilityAPIRequest = async (
  transaction: PatientTransactionResponsibilityUpdateInterface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/transaction/${transaction.transactionId}`,
    'put',
    {
      data: { responsibility: transaction.responsibility },
    }
  );
};
