import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchDenialCodeListInitialInterface } from '../../../interfaces/paymentInterfaces';
import { getDenialCodeListAPIRequest } from '../../../api/system/payment';

const initialState: FetchDenialCodeListInitialInterface = {
  denialCodeListLoading: false,
  denialCodeListData: [],
  denialCodeListError: null,
  denialCodeListStatus: 'IDLE',
};

export const getDenialCodeListRequest: any = createAsyncThunk(
  'denial-reports/denial-codes/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getDenialCodeListAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getDenialCodeListSlice = createSlice({
  name: 'fetchDenialCodes',
  initialState,
  reducers: {
    clearFetchDenialCodeListResponse: (state) => {
      state.denialCodeListLoading = false;
      state.denialCodeListStatus = 'IDLE';
      state.denialCodeListData = [];
      state.denialCodeListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDenialCodeListRequest.pending, (state, action) => {
        state.denialCodeListLoading = true;
        state.denialCodeListStatus = 'PENDING';
      })
      .addCase(getDenialCodeListRequest.fulfilled, (state, action) => {
        state.denialCodeListLoading = false;
        state.denialCodeListData = action.payload;
        state.denialCodeListStatus = 'SUCCESS';
      })
      .addCase(getDenialCodeListRequest.rejected, (state, action) => {
        state.denialCodeListLoading = false;
        state.denialCodeListError = action.payload;
        state.denialCodeListStatus = 'FAILED';
      });
  },
});

export const { clearFetchDenialCodeListResponse } = getDenialCodeListSlice.actions;

export const getDenialCodeListSelector = (state: RootState) => state.GetDenialCodeList;

export default getDenialCodeListSlice.reducer;
