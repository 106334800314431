import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchClaimEraPaymentsInterface,
  FetchClaimEraPaymentsInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getClaimEraPaymentsAPIRequest } from '../../../../api/payments/claimEraFiles';

const initialState: FetchClaimEraPaymentsInitialInterface = {
  fetchClaimEraPaymentsLoading: false,
  fetchClaimEraPaymentsData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchClaimEraPaymentsError: null,
  fetchClaimEraPaymentsStatus: 'IDLE',
};

export const getClaimEraPaymentsRequest: any = createAsyncThunk(
  'claim-era-payments/get',
  async (searchParams: FetchClaimEraPaymentsInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimEraPaymentsAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimEraPaymentsSlice = createSlice({
  name: 'fetchClaimEraPayments',
  initialState,
  reducers: {
    clearFetchClaimEraPaymentsResponse: (state) => {
      state.fetchClaimEraPaymentsLoading = false;
      state.fetchClaimEraPaymentsStatus = 'IDLE';
      state.fetchClaimEraPaymentsData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchClaimEraPaymentsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimEraPaymentsRequest.pending, (state, action) => {
        state.fetchClaimEraPaymentsLoading = true;
        state.fetchClaimEraPaymentsStatus = 'PENDING';
      })
      .addCase(getClaimEraPaymentsRequest.fulfilled, (state, action) => {
        state.fetchClaimEraPaymentsLoading = false;
        state.fetchClaimEraPaymentsStatus = 'SUCCESS';
        state.fetchClaimEraPaymentsData = action.payload;
      })
      .addCase(getClaimEraPaymentsRequest.rejected, (state, action) => {
        state.fetchClaimEraPaymentsLoading = false;
        state.fetchClaimEraPaymentsError = action.payload;
        state.fetchClaimEraPaymentsStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimEraPaymentsResponse } = getClaimEraPaymentsSlice.actions;

export const getClaimEraPaymentsSelector = (state: RootState) => state.GetClaimEraPayments;

export default getClaimEraPaymentsSlice.reducer;
