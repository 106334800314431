export const providerVisitReportLagDays = [
  { id: 7, name: '7 Days' },
  { id: 14, name: '14 Days' },
  { id: 21, name: '21 Days' },
  { id: 30, name: '30 Days' },
  { id: 45, name: '45 Days' },
  { id: 60, name: '60 Days' },
  { id: 90, name: '90 Days' },
];

export const billingSnapshotReportFilterBy = [
  { id: 'CREATED', name: 'Charge Date Created' },
  { id: 'DOS', name: 'Date of Service' },
];

export const billingSnapshotReportTypes = [
  { id: 'CI', name: 'CI' },
  { id: 'MB', name: 'MB' },
  { id: 'MC', name: 'MC' },
  { id: 'VA', name: 'VA' },
];
