import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  ChargeDetailEncounterReportFetchInterface,
  ChargeDetailEncounterReportFetchPropsInterface,
} from '../../../../interfaces/managementInterfaces';
import { getChargeDetailEncounterReportAPIRequest } from '../../../../api/management/chargeDetailVisitReport';

const initialState: ChargeDetailEncounterReportFetchPropsInterface = {
  fetchChargeDetailEncounterReportLoading: false,
  fetchChargeDetailEncounterReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchChargeDetailEncounterReportError: null,
  fetchChargeDetailEncounterReportStatus: 'IDLE',
};

export const getChargeDetailEncounterReportRequest: any = createAsyncThunk(
  'management/chargeDetailEncounterReport/get',
  async (reportData: ChargeDetailEncounterReportFetchInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getChargeDetailEncounterReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getChargeDetailEncounterReportSlice = createSlice({
  name: 'fetchChargeDetailEncounterReport',
  initialState,
  reducers: {
    clearFetchChargeDetailEncounterReportResponse: (state) => {
      state.fetchChargeDetailEncounterReportLoading = false;
      state.fetchChargeDetailEncounterReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchChargeDetailEncounterReportStatus = 'IDLE';
      state.fetchChargeDetailEncounterReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargeDetailEncounterReportRequest.pending, (state, action) => {
        state.fetchChargeDetailEncounterReportLoading = true;
        state.fetchChargeDetailEncounterReportStatus = 'PENDING';
      })
      .addCase(getChargeDetailEncounterReportRequest.fulfilled, (state, action) => {
        state.fetchChargeDetailEncounterReportLoading = false;
        state.fetchChargeDetailEncounterReportStatus = 'SUCCESS';
        state.fetchChargeDetailEncounterReportData = action.payload;
      })
      .addCase(getChargeDetailEncounterReportRequest.rejected, (state, action) => {
        state.fetchChargeDetailEncounterReportLoading = false;
        state.fetchChargeDetailEncounterReportStatus = 'FAILED';
        state.fetchChargeDetailEncounterReportError = action.payload;
      });
  },
});

export const { clearFetchChargeDetailEncounterReportResponse } = getChargeDetailEncounterReportSlice.actions;

export const getChargeDetailEncounterReportSelector = (state: RootState) => state.GetChargeDetailEncounterReport;

export default getChargeDetailEncounterReportSlice.reducer;
