import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';
import { DoctorFinancialReportAdvanceSearch } from '../../components/tableSearch/DoctorFinancialReportAdvanceSearch';

import {
  getDoctorFinancialReportRequest,
  getDoctorFinancialReportSelector,
  clearDoctorFinancialReportResponse,
} from '../../redux/slices/reports/getDoctorFinancialReportSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Doctor',
    dataIndex: 'doctor',
    key: 'doctor',
  },
  {
    title: '# of Visits',
    dataIndex: 'noOfVisits',
    key: 'noOfVisits',
  },
  {
    title: 'Charges',
    dataIndex: 'charges',
    key: 'charges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Allowed Amount',
    dataIndex: 'allowedAmount',
    key: 'allowedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Charge Adjust',
    dataIndex: 'chargeAdjust',
    key: 'chargeAdjust',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Write-Off',
    dataIndex: 'insuranceWriteOff',
    key: 'insuranceWriteOff',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Net Charges',
    dataIndex: 'netCharges',
    key: 'netCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Personal Receipts',
    dataIndex: 'personalReceipts',
    key: 'personalReceipts',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Receipts',
    dataIndex: 'insuranceReceipts',
    key: 'insuranceReceipts',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Receipt Adjust',
    dataIndex: 'receiptAdjust',
    key: 'receiptAdjust',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Net Receipts',
    dataIndex: 'netReceipts',
    key: 'netReceipts',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Change In A/R',
    dataIndex: 'changeInAR',
    key: 'changeInAR',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

// const dataSource = [
//   {
//     doctor: 'John',
//     noOfVisits: '1',
//     charges: '$109.00',
//     allowedAmount: '$0.00	',
//     chargeAdjust: '$0.00',
//     insuranceWriteOff: '$0.00	',
//     netCharges: '$109.00',
//     personalReceipts: '$0.00',
//     insuranceReceipts: '$0.00',
//     receiptAdjust: '$0.00',
//     netReceipts: '$0.00',
//     chargeInAR: '$109.00',
//   },
// ];

const dummyDataSource = {
  items: [
    {
      doctor: 'Abdulla Abdel Hafeez',
      noOfVisits: 151,
      charges: 27710.0,
      allowedAmount: 0.0,
      chargeAdjust: 882.08,
      insuranceWriteOff: 7259.89,
      netCharges: 19568.03,
      personalReceipts: 0.0,
      insuranceReceipts: 12485.97,
      receiptAdjust: 0.0,
      netReceipts: 12485.97,
      changeInAR: 7082.06,
    },
    {
      doctor: 'Bashir Ahmed',
      noOfVisits: 2,
      charges: 340.0,
      allowedAmount: 0.0,
      chargeAdjust: 0.0,
      insuranceWriteOff: 173.08,
      netCharges: 166.92,
      personalReceipts: 0.0,
      insuranceReceipts: 374.73,
      receiptAdjust: 0.0,
      netReceipts: 374.73,
      changeInAR: -207.81,
    },
    {
      doctor: 'Boris Rosenfeld',
      noOfVisits: 21,
      charges: 3638.0,
      allowedAmount: 0.0,
      chargeAdjust: 476.0,
      insuranceWriteOff: 949.3,
      netCharges: 2212.7,
      personalReceipts: 0.0,
      insuranceReceipts: 1539.51,
      receiptAdjust: 0.0,
      netReceipts: 1539.51,
      changeInAR: 673.19,
    },
    {
      doctor: 'Mohammed Yazbek',
      noOfVisits: 2487,
      charges: 402118.0,
      allowedAmount: 0.0,
      chargeAdjust: 7198.73,
      insuranceWriteOff: 104830.26,
      netCharges: 290089.01,
      personalReceipts: 0.0,
      insuranceReceipts: 173268.16,
      receiptAdjust: 0.0,
      netReceipts: 173268.16,
      changeInAR: 116820.85,
    },
  ],
  totals: {
    noOfVisits: 2661,
    charges: 433806.0,
    allowedAmount: 0.0,
    chargeAdjust: 8556.81,
    insuranceWriteOff: 113212.53,
    netCharges: 312036.66,
    personalReceipts: 0.0,
    insuranceReceipts: 187668.37,
    receiptAdjust: 0.0,
    netReceipts: 187668.37,
    changeInAR: 124368.29,
  },
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'doctor-financial', label: 'Doctor Financial Report', status: 'active', link: 'doctor-financial' },
];

export const DoctorFinancialReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchDoctorFinancialReportData, fetchDoctorFinancialReportStatus, fetchDoctorFinancialReportLoading } =
    useAppSelector(getDoctorFinancialReportSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    const updatedDataSource = [...dummyDataSource?.items, { ...dummyDataSource?.totals, doctor: 'Total' }];
    setDataSource(updatedDataSource);

    fetchSystemProviders();
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchDoctorFinancialReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchDoctorFinancialReportStatus === 'SUCCESS') {
      dispatch(clearDoctorFinancialReportResponse());
    } else if (fetchDoctorFinancialReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDoctorFinancialReportResponse());
    }
  }, [fetchDoctorFinancialReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchDoctorFinancialReport(page - 1);
  };

  const fetchDoctorFinancialReport = async (pageNumber = currentPage) => {
    dispatch(getDoctorFinancialReportRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.providerType || updatedData?.providerType === '') {
      delete updatedData.providerType;
    } else {
      updatedData.providerType = parseInt(updatedData?.providerType, 10);
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Doctor Financial Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <DoctorFinancialReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchDoctorFinancialReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent
                  enableActions={false}
                  columns={columns}
                  dataSource={dataSource}
                  enableLastRowStyles={true}
                  lastRowCellStyles="font-bold"
                />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
