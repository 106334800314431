import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { FetchBatchPaymentInterface, FetchBatchPaymentPropsInterface } from '../../../../interfaces/paymentInterfaces';
import { getBatchPaymentsAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: FetchBatchPaymentPropsInterface = {
  fetchBatchPaymentsLoading: false,
  fetchBatchPaymentsData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchBatchPaymentsError: null,
  fetchBatchPaymentsStatus: 'IDLE',
};

export const getBatchPaymentsRequest: any = createAsyncThunk(
  'batch-payment/get',
  async (searchParams: FetchBatchPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await getBatchPaymentsAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchBatchPaymentsSlice = createSlice({
  name: 'fetchBatchPayments',
  initialState,
  reducers: {
    clearFetchBatchPaymentsResponse: (state) => {
      state.fetchBatchPaymentsLoading = false;
      state.fetchBatchPaymentsData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchBatchPaymentsStatus = 'IDLE';
      state.fetchBatchPaymentsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBatchPaymentsRequest.pending, (state, action) => {
        state.fetchBatchPaymentsLoading = true;
        state.fetchBatchPaymentsStatus = 'PENDING';
      })
      .addCase(getBatchPaymentsRequest.fulfilled, (state, action) => {
        state.fetchBatchPaymentsLoading = false;
        state.fetchBatchPaymentsStatus = 'SUCCESS';
        state.fetchBatchPaymentsData = action.payload;
      })
      .addCase(getBatchPaymentsRequest.rejected, (state, action) => {
        state.fetchBatchPaymentsLoading = false;
        state.fetchBatchPaymentsStatus = 'FAILED';
        state.fetchBatchPaymentsError = action.payload;
      });
  },
});

export const { clearFetchBatchPaymentsResponse } = fetchBatchPaymentsSlice.actions;

export const getBatchPaymentsSelector = (state: RootState) => state.GetBatchPayments;

export default fetchBatchPaymentsSlice.reducer;
