import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  VerifyClaimEraPaymentInterface,
  VerifyClaimEraPaymentInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { verifyClaimEraPaymentAPIRequest } from '../../../../api/payments/claimEraFiles';

const initialState: VerifyClaimEraPaymentInitialInterface = {
  verifyClaimEraPaymentLoading: false,
  verifyClaimEraPaymentData: {},
  verifyClaimEraPaymentError: null,
  verifyClaimEraPaymentStatus: 'IDLE',
};

export const verifyClaimEraPaymentRequest: any = createAsyncThunk(
  'claim-era-payments/verify',
  async (paymentData: VerifyClaimEraPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await verifyClaimEraPaymentAPIRequest(paymentData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const verifyClaimEraPaymentSlice = createSlice({
  name: 'verifyClaimEraPayment',
  initialState,
  reducers: {
    clearVerifyClaimEraPaymentResponse: (state) => {
      state.verifyClaimEraPaymentLoading = false;
      state.verifyClaimEraPaymentData = {};
      state.verifyClaimEraPaymentError = null;
      state.verifyClaimEraPaymentStatus = 'IDLE';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(verifyClaimEraPaymentRequest.pending, (state, action) => {
        state.verifyClaimEraPaymentLoading = true;
        state.verifyClaimEraPaymentStatus = 'PENDING';
      })
      .addCase(verifyClaimEraPaymentRequest.fulfilled, (state, action) => {
        state.verifyClaimEraPaymentData = action.payload;
        state.verifyClaimEraPaymentLoading = false;
        state.verifyClaimEraPaymentStatus = 'SUCCESS';
      })
      .addCase(verifyClaimEraPaymentRequest.rejected, (state, action) => {
        state.verifyClaimEraPaymentLoading = false;
        state.verifyClaimEraPaymentStatus = 'FAILED';
        state.verifyClaimEraPaymentError = action.payload;
      });
  },
});

export const { clearVerifyClaimEraPaymentResponse } = verifyClaimEraPaymentSlice.actions;

export const verifyClaimEraPaymentSelector = (state: RootState) => state.VerifyClaimEraPayment;

export default verifyClaimEraPaymentSlice.reducer;
