import { DatePickerField } from '../../atoms/DatePickerField';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { Form } from './Form';
import { HistoryTable } from './HistoryTable';
import { Title } from './Title';

export const PatientEligibility = () => {
  return (
    <>
      <Title title="Eligibility Inquiry" />
      <Form />
      <Title title="Eligibility History" />
      <HistoryTable />
    </>
  );
};
