import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { DenialReportHeader } from '../../../components/billing/denialReport/DenialReportHeader';

import {
  getSystemInsuranceRequest,
  getSystemInsuranceSelector,
} from '../../../redux/slices/system/getSystemInsuranceSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../../redux/slices/system/getSystemProvidersSlice';

import {
  getDenialReportsRequest,
  getDenialReportsSelector,
  clearDenialReportsResponse,
} from '../../../redux/slices/payments/denialReports/getDenialReportsSlice';
import { getDenialCodeListRequest } from '../../../redux/slices/system/getDenialCodeListSlice';
import { getRemarkCodeListRequest } from '../../../redux/slices/system/getRemarkCodeListSlice';
import { Spinner } from '../../../components/atoms/Spinner';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    render: (text: any, row: any) => (
      <a className="no-underline text-linkBlue" href={`patients?id=${row?.patient?.id}`}>
        {row?.patient?.name}
      </a>
    ),
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Attending Provider',
    dataIndex: 'attendingProvider',
    key: 'attendingProvider',
  },
  {
    title: 'Insurance',
    dataIndex: 'insurance',
    key: 'insurance',
  },
  {
    title: 'Remark Code',
    dataIndex: 'remarkCode',
    key: 'remarkCode',
  },
  {
    title: 'Denial Code',
    dataIndex: 'denialCode',
    key: 'denialCode',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Payment Date',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

const dummyDataSource = [
  {
    patient: {
      id: 218,
      name: 'Marine Williams',
    },
    visitId: 'V123456',
    visitDate: '2024-05-01',
    claimId: 'C123456',
    attendingProvider: 'Dr. Smith',
    insurance: 'Insurance A',
    remarkCode: 'R001',
    denialCode: 'D001',
    description: 'Routine check-up',
    paymentDate: '2024-05-05',
  },
];

const reportFilters = {
  denialCode: undefined,
  remarkCode: undefined,
  provider: undefined,
  insurance: undefined,
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'denailReport', label: 'Denial Reports', status: 'active', link: 'denial-reports' },
];

export const DenialPage = () => {
  const dispatch = useAppDispatch();
  const { systemInsuranceStatus, systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);
  const { systemProvidersStatus, systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { fetchDenialReportsStatus, fetchDenialReportsData, fetchDenialReportsLoading } =
    useAppSelector(getDenialReportsSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    // fetchDenialReports();
    if (systemInsuranceInsuranceData?.length === 0 || systemProvidersProviderData?.length === 0) {
      fetchInsurance();
      fetchSystemProviders();
    }
    fetchDenialCodeList();
    fetchRemarkCodeList();
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchDenialReports();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchDenialReportsStatus === 'SUCCESS') {
      setDataSource(fetchDenialReportsData?.items);
      setCurrentPage(fetchDenialReportsData?.currentPage);
      setTotalPages(fetchDenialReportsData?.totalPages);
      dispatch(clearDenialReportsResponse());
    } else if (fetchDenialReportsStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDenialReportsResponse());
    }
  }, [fetchDenialReportsStatus]);

  const fetchDenialReports = async (pageNumber = currentPage) => {
    // dispatch(getDenialReportsRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchInsurance = async () => {
    dispatch(getSystemInsuranceRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchDenialCodeList = () => {
    dispatch(getDenialCodeListRequest());
  };

  const fetchRemarkCodeList = () => {
    dispatch(getRemarkCodeListRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.insuranceId || updatedData?.insuranceId === '') {
      delete updatedData.insuranceId;
    } else {
      updatedData.insuranceId = parseInt(updatedData?.insuranceId, 10);
    }

    if (!updatedData?.remarkCode || updatedData?.remarkCode === '') {
      delete updatedData.remarkCode;
    }

    if (!updatedData?.denialCode || updatedData?.denialCode === '') {
      delete updatedData.denialCode;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchDenialReports(page - 1);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Denial Reports" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <DenialReportHeader onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {fetchDenialReportsLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent enableActions={false} columns={columns} dataSource={dummyDataSource} />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent enableCreateButton={false} mode="VIEW" />
          )}
        </>
      )}
    </div>
  );
};
