import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  CloseBatchPaymentInterface,
  CloseBatchPaymentInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { closeBatchPaymentAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: CloseBatchPaymentInitialInterface = {
  closeBatchPaymentLoading: false,
  closeBatchPaymentData: {},
  closeBatchPaymentStatus: 'IDLE',
  closeBatchPaymentError: null,
};

export const closeBatchPaymentRequest: any = createAsyncThunk(
  'batch-payment/close',
  async (payment: CloseBatchPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await closeBatchPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const closeBatchPaymentSlice = createSlice({
  name: 'closeBatchPayment',
  initialState,
  reducers: {
    clearCloseBatchPaymentResponse: (state) => {
      state.closeBatchPaymentLoading = false;
      state.closeBatchPaymentStatus = 'IDLE';
      state.closeBatchPaymentData = {};
      state.closeBatchPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(closeBatchPaymentRequest.pending, (state, action) => {
        state.closeBatchPaymentLoading = true;
        state.closeBatchPaymentStatus = 'PENDING';
      })
      .addCase(closeBatchPaymentRequest.fulfilled, (state, action) => {
        state.closeBatchPaymentData = action.payload;
        state.closeBatchPaymentLoading = false;
        state.closeBatchPaymentStatus = 'SUCCESS';
      })
      .addCase(closeBatchPaymentRequest.rejected, (state, action) => {
        state.closeBatchPaymentLoading = false;
        state.closeBatchPaymentStatus = 'FAILED';
        state.closeBatchPaymentError = action.payload;
      });
  },
});

export const { clearCloseBatchPaymentResponse } = closeBatchPaymentSlice.actions;

export const closeBatchPaymentSelector = (state: RootState) => state.CloseBatchPayment;

export default closeBatchPaymentSlice.reducer;
