import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchVisitReasonListInitialInterface } from '../../../interfaces/systemInterfaces';
import { getVisitReasonListAPIRequest } from '../../../api/system/patient';

const initialState: FetchVisitReasonListInitialInterface = {
  visitReasonListLoading: false,
  visitReasonListData: [],
  visitReasonListError: null,
  visitReasonListStatus: 'IDLE',
};

export const getVisitReasonListRequest: any = createAsyncThunk('patient/visit/reasons/get', async (thunkAPI: any) => {
  try {
    const response: any = await getVisitReasonListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getVisitReasonListSlice = createSlice({
  name: 'fetchVisitReasons',
  initialState,
  reducers: {
    clearFetchVisitReasonListResponse: (state) => {
      state.visitReasonListLoading = false;
      state.visitReasonListStatus = 'IDLE';
      state.visitReasonListData = [];
      state.visitReasonListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitReasonListRequest.pending, (state, action) => {
        state.visitReasonListLoading = true;
        state.visitReasonListStatus = 'PENDING';
      })
      .addCase(getVisitReasonListRequest.fulfilled, (state, action) => {
        state.visitReasonListLoading = false;
        state.visitReasonListData = action.payload;
        state.visitReasonListStatus = 'SUCCESS';
      })
      .addCase(getVisitReasonListRequest.rejected, (state, action) => {
        state.visitReasonListLoading = false;
        state.visitReasonListError = action.payload;
        state.visitReasonListStatus = 'FAILED';
      });
  },
});

export const { clearFetchVisitReasonListResponse } = getVisitReasonListSlice.actions;

export const getVisitReasonListSelector = (state: RootState) => state.GetVisitReasonList;

export default getVisitReasonListSlice.reducer;
