import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { ClaimERAAutoPostHeader } from '../../../components/billing/claimERAAutoPost/ClaimERAAutoPostHeader';
import { DownloadERAModal } from '../../../components/billing/claimERAAutoPost/DownloadERAModal';
import { SelectInput } from '../../../components/atoms/SelectInput';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { Checkbox } from '../../../components/atoms/Checkbox';
import { DatePickerField } from '../../../components/atoms/DatePickerField';

import {
  getClaimEraAutoPostRequest,
  getClaimEraAutoPostSelector,
  clearFetchClaimEraAutoPostResponse,
} from '../../../redux/slices/payments/claimEraAutoPost/getClaimEraAutoPostSlice';

import { batchType } from '../../../constants/billingConstants';
import { bankTransactionType } from '../../../constants/billingConstants';

import { formatDatePickerDate } from '../../../utils/commonFunctions';
import { validDateFormat } from '../../../utils/commonFunctions';
import { formatCurrency } from '../../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = (handleBankTransactionTypeChange: any, handleBankPostingDate: any, handleisFwd: any) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Received Date',
    dataIndex: 'receivedDate',
    key: 'receivedDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraId',
    key: 'eraId',
  },
  {
    title: 'Group Name',
    dataIndex: 'groupName',
    key: 'groupName',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Paid Amount',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Bank Posting Date',
    dataIndex: 'bankPostingDate',
    key: 'bankPostingDate',
    render: (text: any, record: any) => (
      // <Calendar
      //   onSelectedDateChanged={(date) => handleBankPostingDate(date, 'bankPostingDate', record)}
      //   name="bankPostingDate"
      //   required={false}
      // />
      <DatePickerField
        name="bankPostingDate"
        selectedDate={formatDatePickerDate(record?.bankPostingDate)}
        onChange={(date: Date | null) => {
          if (date) {
            handleBankPostingDate(date, 'bankPostingDate', record);
          }
        }}
      />
    ),
  },
  {
    title: 'Bank Transaction Type',
    dataIndex: 'bankTransactionType',
    key: 'bankTransactionType',
    render: (text: any, record: any) => (
      <SelectInput
        options={bankTransactionType}
        enableDefaultPlaceholder={true}
        name="bankTransactionType"
        defaultValue={record?.bankTransactionType ?? undefined}
        // defaultValue={record?.applicationStatus ?? undefined}
        onChange={(e: any) => handleBankTransactionTypeChange(record?.id, e.target.value)}
      />
    ),
  },
  {
    title: 'Is Fwd',
    dataIndex: 'isFwd',
    key: 'isFwd',
    render: (text: any, record: any) => (
      <Checkbox
        onChange={(e: any) => handleisFwd('isFwd', e.target.checked, record)}
        defaultChecked={record?.isFwd ?? undefined}
      />
    ),
  },
];

const dummyDataSource = [
  {
    id: '1',
    receivedDate: '2024-05-01',
    paidDate: '2024-05-02',
    eraId: 'ERA123456',
    groupName: 'Physicians Care Solutions',
    checkNumber: '12345',
    paidAmount: 3216.4,
    bankPostingDate: '2024-05-03',
    bankTransactionType: 'EFT',
    isFwd: false,
  },
];

const reportFilters = {
  searchText: '',
  startDate: new Date(),
  endDate: new Date(),
};

export const months = [{ id: 'May', name: 'May' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'claimAutoPost', label: 'Claim ERA Auto Post', status: 'active', link: 'claim-auto-post' },
];

export const ClaimERAAutoPostPage = () => {
  const dispatch = useAppDispatch();

  const { fetchClaimEraAutoPostStatus, fetchClaimEraAutoPostData, fetchClaimEraAutoPostLoading } =
    useAppSelector(getClaimEraAutoPostSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [visibleDownloadERA, setVisibleDownloadERA] = useState<boolean>(false);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchClaimEraAutoPost();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchClaimEraAutoPostStatus === 'SUCCESS') {
      setDataSource(fetchClaimEraAutoPostData?.items);
      setCurrentPage(fetchClaimEraAutoPostData?.currentPage);
      setTotalPages(fetchClaimEraAutoPostData?.totalPages);
      dispatch(clearFetchClaimEraAutoPostResponse());
    } else if (fetchClaimEraAutoPostStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchClaimEraAutoPostResponse());
    }
  }, [fetchClaimEraAutoPostStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchClaimEraAutoPost(page - 1);
  };

  const fetchClaimEraAutoPost = async (pageNumber = currentPage) => {
    // dispatch(getClaimEraAutoPostRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const onDownloadERA = () => {
    setVisibleDownloadERA(true);
  };

  const onCloseDownloadERA = (close: boolean) => {
    setVisibleDownloadERA(false);
  };

  const handleBankTransactionTypeChange = (record: any, value: any) => {};

  const handleBankPostingDate = (date: any, name: string, record: any) => {};

  const handleisFwd = (name: string, value: any, record: any) => {};

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Claim ERA Auto Post" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ClaimERAAutoPostHeader reportFilters={reportFilters} onDownload={onDownloadERA} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {fetchClaimEraAutoPostLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent
                  enableActions={true}
                  columns={columns(handleBankTransactionTypeChange, handleBankPostingDate, handleisFwd)}
                  dataSource={dummyDataSource}
                  enableDownload={true}
                  enablePrimaryButton={true}
                  primaryButtonLabel="Post"
                />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}

      {visibleDownloadERA && (
        <DownloadERAModal openDownloadERA={visibleDownloadERA} setOpenDownloadERA={onCloseDownloadERA} />
      )}
    </div>
  );
};
