import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'January',
    dataIndex: 'january',
    key: 'january',
  },
  {
    title: 'February',
    dataIndex: 'february',
    key: 'february',
  },
  {
    title: 'March',
    dataIndex: 'march',
    key: 'march',
  },
  {
    title: 'April',
    dataIndex: 'april',
    key: 'april',
  },
  {
    title: 'May',
    dataIndex: 'may',
    key: 'may',
  },
  {
    title: 'June',
    dataIndex: 'june',
    key: 'june',
  },
  {
    title: 'July',
    dataIndex: 'july',
    key: 'july',
  },
  {
    title: 'August',
    dataIndex: 'august',
    key: 'august',
  },
  {
    title: 'September',
    dataIndex: 'september',
    key: 'september',
  },
  {
    title: 'October',
    dataIndex: 'october',
    key: 'october',
  },
  {
    title: 'November',
    dataIndex: 'november',
    key: 'november',
  },
  {
    title: 'December',
    dataIndex: 'december',
    key: 'december',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
  },
];

const dummyDataSource = [
  {
    provider: 'Gihan',
    january: '191',
    february: '173',
    march: '132',
    april: '132	',
    may: '273',
    june: '132',
    july: '132',
    august: '132',
    september: '132',
    october: '132',
    november: '132',
    december: '132',
    count: '1825',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'visit-count', label: 'Visit Count Report', status: 'active', link: 'visit-count' },
];

export const VisitCountReportPage = () => {
  const dispatch = useAppDispatch();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.lagDays = parseInt(updatedData?.lagDays, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    if (!updatedData?.filterBy || updatedData?.filterBy === '') {
      delete updatedData.filterBy;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Visit Count Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      {/* <TableSearch /> */}

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {dummyDataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent enableActions={false} columns={columns} dataSource={dummyDataSource} />
        </div>
      ) : (
        <EmptyContent mode="VIEW" enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
